import React, { createContext, useContext } from "react";

export const loader = false;

export type LoaderContextType = {
  loader: boolean;
  setLoader: React.Dispatch<React.SetStateAction<boolean>>;
};

export const LoaderContext = createContext<LoaderContextType>({
  loader: loader,
  setLoader: () => {},
});

export const useLoader = () => useContext(LoaderContext);
