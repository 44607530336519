import {
  Action,
  combineReducers,
  configureStore,
  ThunkAction,
} from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";

import cartSlice from "./slices/cartSlice";
import restaurantSlice from "./slices/restaurantSlice";

const reducers = combineReducers({
  cart: cartSlice,
  restaurant: restaurantSlice,
});

// Update key value at each new version
const key = process.env.NEXT_PUBLIC_PERSIST_KEY as string;

const persistConfig = { key, storage };

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NEXT_PUBLIC_NODE_ENV !== "production",
  middleware: [thunk],
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export const clearResults = () => {
  storage.removeItem(`persist:${key}`);
};
