type Props = {
  src: string;
};

const imageLoader = ({ src }: Props) => {
  return `/assets/ui/${src}`;
};

export const absoluteLoader = ({ src }: Props) => {
  return src;
};

export default imageLoader;
