import { createContext, useContext } from "react";

import { RestaurantItemType } from "../api/types/restaurant.type";

export type RestaurantsContextType = {
  restaurants: RestaurantItemType[];
  setRestaurants: React.Dispatch<React.SetStateAction<RestaurantItemType[]>>;
};

export const RestaurantsContext = createContext<RestaurantsContextType>({
  restaurants: [],
  setRestaurants: () => {},
});

export const useRestaurants = () => useContext(RestaurantsContext);
