import { translationsContent } from "../../../../localization/translations";
import { useLanguage } from "../../../../store/lang.context";
import { translate } from "../../../../utils/translate";
import Legals from "../Legals";
import Socials from "../Socials";
import classes from "./columns.module.scss";
import ColumnItem from "./Item/index";

export type ColumnItemsType1 =
  | "Nos restaurants"
  | "Service client"
  | "Nous contacter"
  | "Recrutement";

const columnItems1: string[] = ["Nos restaurants", "Service client"];

const columnItems2: string[] = ["Recrutement", "Nous contacter"];

const Columns = () => {
  const { language } = useLanguage();
  return (
    <div className={classes.container}>
      <ColumnItem
        title={`${translate(translationsContent, "footerHeader1", language)}`}
        items={columnItems1}
        id={true}
      />
      <ColumnItem
        title={`${translate(translationsContent, "footerHeader2", language)}`}
        items={columnItems2}
      />
      <Legals />
      <Socials />
    </div>
  );
};

export default Columns;
