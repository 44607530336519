import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { getCookie, hasCookie } from "cookies-next";

import { AuthResponse, RefreshToken } from "../types/auth.type";
import { AxiosHttpError } from "../types/error.type";
import { setToken, storeAuthResult } from "../utils/token/token.util";

axios.defaults.baseURL = process.env.NEXT_PUBLIC_API_URL;

setToken();

export async function refreshToken() {
  if (hasCookie("resfreshToken")) {
    const refreshToken = getCookie("refreshToken") as string;
    try {
      const res = await axios.post<RefreshToken, AxiosResponse<AuthResponse>>(
        "/auth/token/refresh",
        { refreshToken: refreshToken }
      );
      if (res.data) storeAuthResult(res.data);
      return true;
    } catch (e) {
      console.log(e);
    }
  }
  return false;
}

export async function sendRawRequest<T, R>(
  method: "get" | "post" | "put" | "patch" | "delete",
  url: string,
  data?: T,
  config?: AxiosRequestConfig<T>
) {
  if (method === "get") return axios.get<T, R>(url, config);
  return axios[method]<T, R>(url, data, config);
}

export async function sendRequest<T, R>(
  method: "get" | "post" | "put" | "patch" | "delete",
  url: string,
  data?: T,
  config?: AxiosRequestConfig<T>
) {
  try {
    return await sendRawRequest<T, R>(method, url, data, config);
  } catch (error) {
    const axiosError = error as Error | AxiosHttpError;
    if (axios.isAxiosError(axiosError) && axiosError.response?.status === 401) {
      const success = await refreshToken();
      if (success) return sendRawRequest<T, R>(method, url, data, config);
    }
    throw error;
  }
}
