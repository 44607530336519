import classes from "./translations.module.scss";
export const translationsContent = {
  // [objectname]: {
  //   fr: "",
  //   en: "",
  //   es: "",
  //   nl: "",
  // },
  to: {
    fr: "à",
    en: "to",
    es: "a",
    nl: "naar",
  },
  ingredients: {
    fr: "Ingrédients",
    en: "Ingredients",
    es: "Ingredientes",
    nl: "Ingrediënten",
  },
  sauce: {
    fr: "Sauce",
    en: "Sauce",
    es: "Salsa",
    nl: "Sauzen",
  },
  extrasPopup1: {
    fr: "Souhaitez-vous ajouter une sauce à votre salade ?",
    en: "Would you like to add a dressing to your salad?",
    es: "¿Te gustaría agregar un aderezo a tu ensalada?",
    nl: "Wil je een dressing toevoegen aan je salade?",
  },
  extrasPopup2: {
    fr: "Souhaitez-vous ajouter un ingrédient supplémentaire ?",
    en: "Would you like to add an additional ingredient?",
    es: "¿Te gustaría añadir un ingrediente adicional?",
    nl: "Wil je een extra ingrediënt toevoegen?",
  },
  pokePopup1: {
    fr: " Désirez vous un petit pain ?",
    en: "Would you like a small bread?",
    es: "¿Te gustaría un pequeño pan?",
    nl: "Wil je een klein broodje?",
  },
  pokePopup2: {
    fr: " Souhaitez-vous petit plaisir ?",
    en: "Would you like a little pleasure?",
    es: "¿Te gustaría un pequeño placer?",
    nl: "Wil je een klein plezier?",
  },

  catalog: {
    fr: "Nos ingrédients",
    en: "Our ingredients",
    es: "Nuestros ingredientes",
    nl: "Onze ingrediënten",
  },
  above18Content1: {
    fr: "En confirmant ma commande, je certifie avoir plus de 18 ans pour consommer de l'alcool.",
    en: "",
    es: "",
    nl: "",
  },
  above18Content2: {
    fr: "* Vente d'alcool interdite aux mineurs de moins de 18 ans. L'abus d'alcool est dangereux pour la santé. Toute commande d'une boisson alcoolisée doit être accompagnée de la consommation d'un plat.",
    en: "",
    es: "",
    nl: "",
  },
  above16Content1: {
    fr: "En confirmant ma commande, je certifie avoir plus de 16 ans pour consommer de l'alcool.",
    en: "",
    es: "",
    nl: "",
  },
  above16Content2: {
    fr: "* Vente d'alcool interdite aux mineurs de moins de 16 ans. L'abus d'alcool est dangereux pour la santé. Toute commande d'une boisson alcoolisée doit être accompagnée de la consommation d'un plat.",
    en: "",
    es: "",
    nl: "",
  },
  ingredientCardUnavailableMessage: {
    fr: "Produit indisponible - bientôt de retour",
    en: "Product unavailable - back soon",
    es: "Producto no disponible - volveremos pronto",
    nl: "Product niet beschikbaar - binnenkort terug",
  },
  compositionContent1: {
    fr: "Choisissez",
    en: "Choose",
    es: "Elegir",
    nl: "Kiezen",
  },
  compositionContent2: {
    fr: "vos",
    en: "your",
    es: "su",
    nl: "uw",
  },
  compositionContent3: {
    fr: "votre",
    en: "your",
    es: "su",
    nl: "uw",
  },
  compositionContent4: {
    fr: "Désirez-vous un petit pain ?",
    en: "Would you like a bun?",
    es: "¿Quieres un bollo?",
    nl: "Wil je een broodje?",
  },
  compositionContent5: {
    fr: "Désirez-vous une boisson chaude ?",
    en: "Would you like a hot drink?",
    es: "¿Quieres una bebida caliente?",
    nl: "Wil je een warm drankje?",
  },
  compositionContent6: {
    fr: "Composition de la formule",
    en: "Composition of the formula",
    es: "Composición de la fórmula",
    nl: "Samenstelling van de formule",
  },
  compositionContent7: {
    fr: "Suppléments",
    en: "Supplements",
    es: "Suplementos",
    nl: "Supplementen",
  },
  compositionContent8: {
    fr: "ÉTAPE",
    en: "STEP",
    es: "PASO",
    nl: "STAP",
  },
  compositionContent9: {
    fr: "Souhaitez-vous un petit plaisir ?",
    en: "Would you like a little pleasure?",
    es: "¿Te gustaría un poco de diversión?",
    nl: "Wil je een beetje plezier?",
  },
  compositionContent10: {
    fr: "Souhaitez-vous ajouter un ingrédient supplémentaire?",
    en: "Would you like to add an additional ingredient?",
    es: "¿Le gustaría agregar un ingrediente adicional?",
    nl: "Wil je een extra ingrediënt toevoegen?",
  },
  dessertCompositionContent1: {
    fr: "Choisissez",
    en: "Choose",
    es: "Elegir",
    nl: "Kiezen",
  },
  dessertCompositionContent3: {
    fr: "votre",
    en: "your",
    es: "su",
    nl: "uw",
  },
  dessertCompositionContent6: {
    fr: "Composition du dessert",
    en: "Composition of the dessert",
    es: "Composición de la dessert",
    nl: "Samenstelling van de dessert",
  },
  freeSauceContent: {
    fr: "Une sauce offerte",
    en: "First Sauce is for free",
    es: "Una salsa gratis",
    nl: "Een gratis saus",
  },
  freeSauceCardContent: {
    fr: "Sauce Offerte",
    en: "Free Sauce",
    es: "Salsa Gratis",
    nl: "Gratis saus",
  },
  freeSauceCardContent2: {
    fr: "Choisissez votre sauce gratuite",
    en: "Choose your free sauce",
    es: "Elige tu salsa gratis",
    nl: "Kies je gratis saus",
  },
  ingredientCardContent1: {
    fr: "Vous souhaitez que la base soit chaude ou froide ?",
    en: "Do you want the base to be hot or cold?",
    es: "¿Quieres que la base esté caliente o fría?",
    nl: "Wil je dat de basis warm of koud is?",
  },
  ingredientCardContent2: {
    fr: "FROID  ",
    en: "COLD",
    es: "FRÍO",
    nl: "KOUD",
  },
  ingredientCardContent3: {
    fr: "CHAUD",
    en: "HOT",
    es: "CALIENTE",
    nl: "WARM",
  },
  snacks: {
    fr: "Nos petits plaisirs",
    en: "Our little pleasures",
    es: "Nuestros pequeños placeres",
    nl: "Onze kleine genoegens",
  },
  breads: {
    fr: "Nos petits pains",
    en: "Our little Breads",
    es: "Nuestros pequeños panes",
    nl: "Onze kleine broden",
  },
  ourFormulas: {
    fr: "Formules",
    en: "Formulas",
    es: "Fórmulas",
    nl: "Formules",
  },
  customizedDessert: {
    fr: "Dessert Sur Mesure",
    en: "Customized Dessert",
    es: "Postre a Medida",
    nl: "Aangepast Dessert",
  },
  personalizedFormula: {
    fr: "Formule sur mesure",
    en: "Your formula to compose",
    es: "Tu fórmula para componer",
    nl: "Uw formule om samen te stellen",
  },
  personalizedFormula2: {
    fr: " A Composer",
    en: " To compose",
    es: " Para componer",
    nl: " Om samen te stellen",
  },
  cartIngredients: {
    fr: "À la carte",
    en: "Cart ingredients",
    es: "Ingredientes del carrito",
    nl: "Winkelwagen ingrediënten",
  },
  recipeIdeas: {
    fr: "Idées recettes",
    en: "Recipe ideas",
    es: "Ideas de receta",
    nl: "Recept idees",
  },
  compose: {
    fr: "COMPOSER",
    en: "COMPOSE",
    es: "COMPONER",
    nl: "COMPONEREN",
  },
  add: {
    fr: "Ajouter",
    en: "Add",
    es: "Añadir",
    nl: "Toevoegen",
  },
  remove: {
    fr: "RETIRER",
    en: "REMOVE",
    es: "ELIMINAR",
    nl: "VERWIJDEREN",
  },
  youHave: {
    fr: "Vous avez",
    en: "You have",
    es: "Teneis",
    nl: "Jullie hebben, u heeft",
  },
  back: {
    fr: "Retour",
    en: "Back",
    es: "Volver",
    nl: "Terug",
  },
  next: {
    fr: "SUIVANT",
    en: "NEXT",
    es: "PRÓXIMO",
    nl: "DE VOLGENDE",
  },
  thankYou: {
    fr: "NON MERCI",
    en: "NO THANKS",
    es: "NO GRACIAS",
    nl: "NEE, DANK U WEL",
  },
  discount: {
    fr: "Réduction",
    en: "Discount",
    es: "Descuento",
    nl: "Korting",
  },
  newTotal: {
    fr: "Nouveau total",
    en: "New Total",
    es: "Total nuevo",
    nl: "Nieuw totaal",
  },
  promoCode: {
    fr: "Le code promo n'est pas valide ou a expiré.",
    en: "The promo code is invalid or has expired.",
    es: "El código de promoción no es válido o ha caducado.",
    nl: "De promotiecode is ongeldig of is verlopen.",
  },
  cart: {
    fr: "Panier",
    en: "Cart",
    es: "Cesta",
    nl: "Winkelmandje",
  },
  cartContent1: {
    fr: "Votre panier est vide, composez votre salade dès à présent et venez la récupérer dans votre restaurant Eat Salad.",
    en: "Your cart is empty, compose your salad now and pick it up at your Eat Salad restaurant.",
    es: "Tu cesta está vacía, prepara ya tu ensalada y recógela en tu restaurante Eat Salad.",
    nl: "Uw mandje is leeg, stel nu uw salade samen en haal deze op bij uw Eat Salad restaurant.",
  },
  cartButton1: {
    fr: "VOIR LE MENU EAT SALAD",
    en: "SEE EAT SALAD MENU",
    es: "VER EL MENÚ EAT SALAD",
    nl: "ZIE HET EAT SALAD MENU",
  },
  cartContent2: {
    fr: "Récapitulatif",
    en: "Sum up",
    es: "Resumen",
    nl: "Samenvatting",
  },
  cutleryBannerContent1: {
    fr: "Pensez à la planète, aidez-nous à réduire les déchets : Ne commandez que lorsque vous en avez vraiment besoin !",
    en: "Think about the planet, help us reduce waste: Order only when you really need it!",
    es: "Piensa en el planeta, ayúdanos a reducir los desechos: ¡Sólo pide cuando lo necesites de verdad!",
    nl: "Denk aan de planeet, help ons afval te verminderen: Bestel alleen als je het echt nodig hebt!",
  },
  cutleryBannerContent2: {
    fr: "Couverts",
    en: "Tableware",
    es: "Cubiertos",
    nl: "Bestek",
  },
  cutleryBannerContent3: {
    fr: "J'en ai vraiment besoin !",
    en: "I really need it!",
    es: "¡Lo necesito de verdad!",
    nl: "Ik heb het echt nodig!",
  },
  cartMessageLabel: {
    fr: "Des précisions particulières ?",
    en: "Specific information ?",
    es: "¿Algún detalle específico?",
    nl: "Nog specifieke details?",
  },
  cartMessagePlaceholder: {
    fr: "Allergies, intolérances ou autres remarques",
    en: "Allergies, intolerances or other related comments",
    es: "Alergias, intolerancias u otras observaciones",
    nl: "Allergieën, intoleranties of andere opmerkingen",
  },
  cartPromoPlaceholder: {
    fr: "Entrez votre code promo",
    en: "Enter your promotional code",
    es: "Introduce tu código de promoción",
    nl: "Voer uw promotiecode in",
  },
  total: {
    fr: "TOTAL",
    en: "TOTAL",
    es: "TOTAL",
    nl: "TOTAAL",
  },
  cartButton2: {
    fr: "CONTINUER MES ACHATS",
    en: "CONTINUE SHOPPING",
    es: "SEGUIR CON LAS COMPRAS",
    nl: "VERDER WINKELEN",
  },
  cartButton3: {
    fr: "UTLISER MA FIDÉLITÉ",
    en: "USE MY LOYALTY",
    es: "USA MI LEALTAD",
    nl: "GEBRUIK MIJN LOYALITEIT",
  },
  cartPopupMessage1: {
    fr: "Veuillez compléter toutes les formules avant de procéder au paiement.",
    en: "Please complete all forms before proceeding to payment.",
    es: "Por favor complete todos los formularios antes de proceder al pago.",
    nl: "Gelieve alle formulieren in te vullen alvorens over te gaan tot betaling.",
  },
  cartPopupMessage2: {
    fr: "Votre heure de retrait n'est plus valide. Veuillez sélectionner un autre créneau horaire s'il vous plait.",
    en: "Your takeaway time is no longer valid. Please select another time slot.",
    es: "Su tiempo de retiro ya no es válido. Seleccione otra franja horaria.",
    nl: "Uw opnametijd is niet meer geldig. Selecteer een ander tijdslot.",
  },
  customFormulaHeader: {
    fr: "Formule Sur Mesure",
    en: "Bespoke order",
    es: "Fórmula a medida",
    nl: "Formule op maat",
  },
  calories: {
    fr: "Calories",
    en: "Calories",
    es: "Calorías",
    nl: "Calorieën",
  },
  ttc: {
    fr: "Montant TTC",
    en: "Amount incl. VAT",
    es: "Importe con IVA incluido",
    nl: "Bedrag incl. BTW",
  },
  restHoursList1: {
    fr: "Restaurant selectionné",
    en: "Selected restaurant",
    es: "Restaurante seleccionado",
    nl: "Geselecteerd restaurant",
  },
  restHoursList2: {
    fr: "Créneau horaire",
    en: "Time slot",
    es: "Franja horaria",
    nl: "Tijdslot",
  },
  restHoursList3: {
    fr: "En modifiant l'adresse certains produits ne seront peut être plus disponibles. Merci de votre compréhension.",
    en: "By modifying the address, some products may no longer be available. Thank you for your understanding.",
    es: "Al modificar la dirección, es posible que algunos productos ya no estén disponibles. Gracias por su comprensión.",
    nl: "Door het adres te wijzigen, zijn sommige producten mogelijk niet meer beschikbaar. Bedankt voor uw begrip.",
  },
  close: {
    fr: "FERMER",
    en: "CLOSE",
    es: "CERRAR",
    nl: "SLUITEN",
  },
  closed: {
    fr: "Fermé",
    en: "Closed",
    es: "Cerrado",
    nl: "Gesloten",
  },
  closedFrom: {
    fr: "Restaurant fermé le ",
    en: "Exceptional closure",
    es: "Cierre excepcional",
    nl: "Uitzonderlijke sluiting",
  },
  backPayment: {
    fr: "Revenir au panier",
    en: "Go back to cart",
    es: "Volver a la cesta",
    nl: "Terug naar de winkelmand",
  },
  paymentContent1: {
    fr: "Payer ma commande",
    en: "Pay my order",
    es: "Paga mi orden",
    nl: "Betaal mijn bestelling",
  },
  paymentContent2: {
    fr: "Maintenant",
    en: "Now",
    es: "Ahora",
    nl: "Nutsvoorzieningen",
  },
  paymentRadioBtnLabel1: {
    fr: "Carte bancaire",
    en: "Credit card",
    es: "Tarjeta bancaria",
    nl: "Bankkaart",
  },
  paymentContent3: {
    fr: "Plus tard",
    en: "Later",
    es: "Más tarde",
    nl: "Later",
  },
  paymentRadioBtnLabel2: {
    fr: "En restaurant",
    en: "In restaurant",
    es: "En restaurante",
    nl: "In restaurant",
  },
  paymentContent4: {
    fr: "En validant ma commande je déclare avoir pris connaissance et accepté les",
    en: "By adding a Payment method, I declare that I understand and accept the",
    es: "Al validar mi pedido, declaro que he leído y acepto las",
    nl: "Door het betalingsmiddel toe te voegen, verklaar ik dat ik de ",
  },
  paymentContent5: {
    fr: "Permet d'éviter les files d'attente !",
    en: "Avoid the queues!",
    es: "¡Evita las colas!",
    nl: "Vermijd de wachtrijen!",
  },
  paymentContent6: {
    fr: "Cartes bancaires, titres restaurant papier, carte ou espèces",
    en: "Bank cards, paper meal vouchers, card or cash",
    es: "Tarjetas bancarias, vales de restaurante en papel, tarjeta o efectivo",
    nl: "Bankkaarten, papieren restaurantvouchers, kaart of contant geld",
  },
  paymentBtnLabel1: {
    fr: "PAYER",
    en: "PAY",
    es: "PAGA",
    nl: "BETALEN",
  },
  paymentErrorPOPUP: {
    fr: "Le service de commande en ligne n'est pas disponible pour le moment. Merci de commander directement sur place à votre restaurant.",
    en: "The online ordering service is not available at this time. Please order directly on site at your restaurant.",
    es: "El servicio de pedidos en línea no está disponible en este momento. Por favor ordene directamente en el sitio en su restaurante.",
    nl: "De online bestelservice is op dit moment niet beschikbaar. Bestel a.u.b. direct ter plaatse in uw restaurant.",
  },
  paymentErrorPOPUPPriceMismatch: {
    fr: "Une erreur est survenue sur votre commande et votre panier a été vidé. Merci de bien vouloir recommencer.",
    en: "An error has occurred on your order and your cart has been emptied. Please try again.",
    es: "Se ha producido un error en su pedido y su cesta se ha vaciado. Inténtalo de nuevo.",
    nl: "Er is een fout opgetreden bij uw bestelling en uw winkelmandje is geleegd. Probeer het opnieuw.",
  },
  paymentErrorPOPUPEatfidMismatch: {
    fr: "Vous ne disposez pas d'assez de points pour commander. Le produit eatfid a été retiré de votre panier.",
    en: "You do not have enough points to order. The eatfid product has been removed from your basket.",
    es: "No tienes suficientes puntos para ordenar. El producto eatfid ha sido eliminado de su cesta.",
    nl: "U heeft niet genoeg punten om te bestellen. Het product eatfid is uit uw winkelmandje verwijderd.",
  },
  paymentErrorPOPUPClosedRestaurant: {
    fr: "Le restaurant actuel semble être fermé maintenant. Veuillez sélectionner un nouveau restaurant ou réessayer plus tard.",
    en: "The current restaurant appears to be closed now. Please select a new restaurant, or try again later.",
    es: "El restaurante actual parece estar cerrado ahora. Seleccione un nuevo restaurante o vuelva a intentarlo más tarde.",
    nl: "Het huidige restaurant lijkt nu gesloten te zijn. Selecteer een nieuw restaurant of probeer het later opnieuw.",
  },
  creditCardLabel1: {
    fr: "Numéro de la carte*",
    en: "Card number*",
    es: "Numero de la tarjeta*",
    nl: "Kaartnummer*",
  },
  creditCardErrorMessage1: {
    fr: "Votre numéro de carte doit comporter 16 chiffres",
    en: "Your card number must be 16 digits",
    es: "Tu número de tarjeta debe tener 16 dígitos",
    nl: "Uw kaartnummer moet uit 16 cijfers bestaan",
  },
  creditCardLabel2: {
    fr: "Date d'expiration*",
    en: "Expiring date*",
    es: "Fecha de caducidad*",
    nl: "Vervaldatum*",
  },
  creditCardPlaceholder1: {
    fr: "MM/AA",
    en: "Month/Year",
    es: "MM/AA",
    nl: "MM/JJ",
  },
  creditCardErrorMessage2: {
    fr: "Veuillez entrer un format de date valide",
    en: "Please enter a valid date format",
    es: "Ingrese un formato de fecha válido",
    nl: "Voer een geldig datumformaat in",
  },
  creditCardErrorMessage3: {
    fr: "Votre CVC doit être composé de 3 chiffres",
    en: "Your CVC must consist of 3 digits",
    es: "Tu CVC debe constar de 3 dígitos",
    nl: "Uw CVC moet uit 3 cijfers bestaan",
  },
  creditCardLabel3: {
    fr: "Nom du titulaire de la carte*",
    en: "Name of the card holder*",
    es: "Nombre del titular de la tarjeta*",
    nl: "Naam van de kaarthouder*",
  },
  creditCardPlaceholder2: {
    fr: "Entrez le nom du titulaire de la carte",
    en: "Enter the name of the card holder",
    es: "Introduce el nombre del titular de la tarjeta",
    nl: "Voer de naam van de kaarthouder in",
  },
  creditCardContent: {
    fr: "Le nom ne doit pas être vide.",
    en: "The name must not be empty.",
    es: "El nombre no debe estar vacío.",
    nl: "De naam mag niet leeg zijn.",
  },
  creditCardError1: {
    fr: "Une erreur s'est produite lors du traitement de votre paiement. Veuillez saisir les informations de la carte",
    en: "An error occurred while processing your payment. Please enter card information",
    es: "Ocurrió un error al procesar su pago. Por favor ingrese la información de la tarjeta",
    nl: "Er is een fout opgetreden tijdens het verwerken van uw betaling. Voer kaartgegevens in",
  },
  creditCardError2: {
    fr: "Une erreur s'est produite lors du traitement de votre paiement. Veuillez réessayer plus tard",
    en: "An error occurred while processing your payment. please try again later",
    es: "Ocurrió un error al procesar su pago. Por favor, inténtelo de nuevo más tarde",
    nl: "Er is een fout opgetreden tijdens het verwerken van uw betaling. probeer het later nog eens",
  },
  creditCardError3: {
    fr: "Une erreur inconnue s'est produite. Veuillez payer au restaurant ou réessayer plus tard",
    en: "An unknown error has occurred. Please pay at the restaurant or try again later",
    es: "Un error desconocido a ocurrido. Paga en el restaurante o vuelve a intentarlo más tarde",
    nl: "Er is een onbekende fout opgetreden. Gelieve te betalen in het restaurant of probeer het later opnieuw",
  },
  confirmationContent1: {
    fr: "Merci pour votre commande !",
    en: "Thank yoiu for your order !",
    es: "Gracias por su orden !",
    nl: "Bedankt voor uw bestelling!",
  },
  confirmationContent2: {
    fr: "Pour récupérer votre commande",
    en: "To pick up your order",
    es: "Para recoger tu pedido",
    nl: "Om uw bestelling af te halen",
  },
  confirmationContent3: {
    fr: "Voici votre numéro de commande à présenter en caisse",
    en: "Here is your order number to present at the checkout",
    es: "Este es el número de pedido que debes presentar en la caja",
    nl: "Hier is uw bestelnummer dat u bij de kassa moet opgeven",
  },
  confirmationContent4: {
    fr: "Aujourd'hui",
    en: "Today",
    es: "Hoy",
    nl: "Vandaag",
  },
  confirmationContent5: {
    fr: "Demain",
    en: "Tomorrow",
    es: "Mañana",
    nl: "Morgen",
  },
  confirmationContent6: {
    fr: "entre",
    en: "between",
    es: "entre",
    nl: "tussen",
  },
  confirmationContent7: {
    fr: "au",
    en: "at",
    es: "en",
    nl: "op",
  },
  confirmationContent8: {
    fr: (
      <>
        <h3>Pour payer et retirer votre commande, téléchargez votre reçu</h3>
        <div>
          <span>Nous vous l'avons aussi envoyé par mail</span>
        </div>
        <div>
          <span>
            <small>Attention, il peut se trouver dans vos spams</small>
          </span>
        </div>
      </>
    ),
    en: (
      <>
        <h3>To pay and collect your order, download your receipt</h3>
        <div>
          <span>We have also sent it to you by e-mail</span>
        </div>
        <div>
          <span>
            <small>Beware, it may be in your spam</small>
          </span>
        </div>
      </>
    ),
    es: (
      <>
        <h3>Para pagar y recoger tu pedido, descarga tu recibo</h3>
        <div>
          <span>También te lo enviamos por email</span>
        </div>
        <div>
          <span>
            <small>Cuidado, puede estar en los spams</small>
          </span>
        </div>
      </>
    ),
    nl: (
      <>
        <h3>Download je kassabon om te betalen en je bestelling op te halen</h3>
        <div>
          <span>Wij hebben hem ook per e-mail toegezonden</span>
        </div>
        <div>
          <span>
            <small>Opgepast, deze kan in uw spam zitten</small>
          </span>
        </div>
      </>
    ),
  },

  confirmationContent9: {
    fr: "Vous avez actuellement",
    en: "You currently have",
    es: "Actualmente tienes",
    nl: "U heeft momenteel",
  },
  confirmationContent10: {
    fr: "Vous venez de cumuler",
    en: "You just accumulated",
    es: "Acabas de acumular",
    nl: "Je hebt net verzameld",
  },
  confirmationBtn1: {
    fr: "Télécharger le reçu",
    en: "Download the receipt",
    es: "Descargar el recibo",
    nl: "Download het ontvangstbewijs",
  },
  confirmationBtn2: {
    fr: "Découvrir les gains",
    en: "Discover the benefits",
    es: "Descubre las ventajas",
    nl: "Winst ontdekken",
  },
  headerNav1: {
    fr: <>SE CONNECTER</>,
    en: <>LOGIN</>,
    es: <>CONECTARSE</>,
    nl: <>INLOGGEN</>,
  },
  loginHeader: {
    fr: "SE CONNECTER",
    en: "LOGIN",
    es: "CONECTARSE",
    nl: "INLOGGEN",
  },
  headerNav2: {
    fr: <>PANIER</>,
    en: <>CART</>,
    es: <>CESTA</>,
    nl: <>WINKELMANDJE</>,
  },
  cartHeader: {
    fr: "PANIER",
    en: "CART",
    es: "CESTA",
    nl: "WINKELMAND",
  },
  confirmationHeader: {
    fr: "Confirmation",
    en: "Confirmation",
    es: "Confirmación",
    nl: "Bevestiging",
  },
  loginHeader1: {
    fr: "Identifiez-vous",
    en: "Identify yourself",
    es: "Identifícate",
    nl: "Identificeer jezelf",
  },
  loginHeader2: {
    fr: "Vous n’avez pas de compte ? C’est rapide !",
    en: "You do not have an account ? It's quick !",
    es: "¿No tienes una cuenta? Es rápido !",
    nl: "Heeft u geen account? Het is snel!",
  },
  loginSignupBtn: {
    fr: "CRÉER VOTRE COMPTE",
    en: "CREATE MY ACCOUNT",
    es: "CREAR TU CUENTA",
    nl: "UW ACCOUNT AANMAKEN",
  },
  loginHeader3: {
    fr: "Connectez-vous via",
    en: "Log in via",
    es: "Iniciar sesión a través de",
    nl: "Inloggen via",
  },
  googleBtn: {
    fr: "Google",
    en: "Google",
    es: "Google",
    nl: "Google",
  },
  fbBtn: {
    fr: "Facebook",
    en: "Facebook",
    es: "Facebook",
    nl: "Facebook",
  },
  loginHeader4: {
    fr: "ou",
    en: "or",
    es: "O",
    nl: "of",
  },
  setEmail: {
    fr: "Créez votre adresse e-mail",
    en: "Create your email address",
    es: "Crea tu dirección de correo electrónico",
    nl: "Maak uw e-mailadres aan",
  },
  setEmailError: {
    fr: "entrez une adresse e-mail valide",
    en: "Enter a valid email address",
    es: "Introduzca una dirección de correo electrónico válida",
    nl: "Voer een geldig e-mailadres in",
  },
  password: {
    fr: "Mot de passe*",
    en: "Password*",
    es: "Contraseña *",
    nl: "Wachtwoord*",
  },
  setPassword: {
    fr: "Créez votre mot de passe",
    en: "Create your password",
    es: "Crea tu contraseña",
    nl: "Maak uw wachtwoord aan",
  },
  newPassword: {
    fr: "Saisissez un nouveau mot de passe",
    en: "Please enter a new password",
    es: "Introduce una nueva contraseña",
    nl: "Geef een nieuw wachtwoord",
  },
  confirmNewPassword: {
    fr: "Re-saisissez le nouveau mot de passe",
    en: "Re-enter a new password",
    es: "Introduce otra vez la nueva contraseña",
    nl: "Voer het wachtwoord opnieuw in",
  },
  passwordError: {
    fr: "Essayez un autre mot de passe.",
    en: "Try another password.",
    es: "Pruebe con otra contraseña.",
    nl: "Probeer een ander wachtwoord.",
  },
  passwordTokenError: {
    fr: "Erreur de serveur, veuillez réessayer plus tard !",
    en: "Server error, please try again later!",
    es: "Error del servidor, inténtalo de nuevo más tarde.",
    nl: "Serverfout, probeer het later opnieuw!",
  },
  passwordMismatch: {
    fr: "Les deux mots de passe ne correspondent pas.",
    en: "The two password fields didn't match.",
    es: "Los dos campos de contraseña no coinciden.",
    nl: "De twee wachtwoordvelden komen niet overeen.",
  },
  forgotPassword: {
    fr: "Mot de passe oublié ?",
    en: "Forgot password ?",
    es: "¿Has olvidado tu contraseña?",
    nl: "Wachtwoord vergeten ?",
  },
  loginError: {
    fr: "Le nom d'utilisateur ou le mot de passe sont incorrects",
    en: "Username or password is incorrect",
    es: "Nombre de usuario o contraseña incorrecta",
    nl: "Gebruikersnaam of wachtwoord is onjuist",
  },
  socialLoginError: {
    fr: "Vous n'avez pas de mail associé à votre compte Facebook / Google. Merci de créer un compte ou de vous connecter directement sur le site.",
    en: "You do not have an email associated with your Facebook / Google account. Please create an account or log in directly to the site.",
    es: "No tienes un correo electrónico asociado a tu cuenta de Facebook/Google. Cree una cuenta o inicie sesión directamente en el sitio.",
    nl: "U heeft geen e-mailadres dat is gekoppeld aan uw Facebook-/Google-account. Maak een account aan of log direct in op de site.",
  },
  signupHeader1: {
    fr: "Créer mon compte",
    en: "Sign up",
    es: "Crear mi cuenta",
    nl: "Mijn account aanmaken",
  },
  signupTaCcontent1: {
    fr: "En vous inscrivant, vous acceptez",
    en: "By registering, you agree",
    es: "Al registrarte, aceptas",
    nl: "Door te registreren, ga je akkoord",
  },
  signupTaCcontent2: {
    fr: "les conditions d'utilisations",
    en: "terms of use",
    es: "términos de Uso",
    nl: "gebruiksvoorwaarden",
  },
  signupTaCcontent3: {
    fr: "la politique de confidentialité.",
    en: "the privacy policy.",
    es: "la política de privacidad",
    nl: "het privacybeleid.",
  },
  signupHeader2: {
    fr: (
      <>
        Déjà&nbsp;inscrit&nbsp;?
        <span style={{ color: "#aaa", marginLeft: "10px" }}>
          &nbsp;Connexion
        </span>
      </>
    ),
    en: (
      <>
        Already have an account?
        <span style={{ color: "#aaa", marginLeft: "10px" }}>&nbsp;Sign in</span>
      </>
    ),
    es: (
      <>
        ¿Ya estás registrado?
        <span style={{ color: "#aaa", marginLeft: "10px" }}>
          &nbsp;Conexión
        </span>
      </>
    ),
    nl: (
      <>
        Al geregistreerd?
        <span style={{ color: "#aaa", marginLeft: "10px" }}>
          &nbsp;Inloggen
        </span>
      </>
    ),
  },
  firstNameLabel: {
    fr: "Prénom*",
    en: "First name *",
    es: "Nombre *",
    nl: "Voornaam*",
  },
  firstNamePlaceholder: {
    fr: "Votre prénom",
    en: "Your first name",
    es: "Tu nombre",
    nl: "Uw voornaam",
  },
  lastNameLabel: {
    fr: "Nom*",
    en: "Surname *",
    es: "Apellidos *",
    nl: "Naam *",
  },
  lastNamePlaceholder: {
    fr: "Votre nom",
    en: "Your surname",
    es: "Tus apellidos",
    nl: "Uw naam",
  },
  telephoneLabel: {
    fr: "Téléphone*",
    en: "Fixed or mobile phone *",
    es: "Teléfono móvil o fijo*",
    nl: "Mobiele of vaste telefoon *",
  },
  telephonePlaceholder: {
    fr: "Entrez votre numéro de téléphone",
    en: "Enter your phone number",
    es: "Introduce tu número de teléfono",
    nl: "Uw telefoonnummer",
  },
  byEmail: {
    fr: "Par Email:",
    en: "By Email:",
    es: "Por Email:",
    nl: "Per email:",
  },
  bySMS: {
    fr: "Par SMS:",
    en: "By SMS:",
    es: "Por SMS:",
    nl: "Per SMS:",
  },
  telephoneFooter: {
    fr: "Nous ne vous appelons qu'en cas de besoin lié à votre commande. Nous pourrons également l'utiliser pour l'envoi de newsletters ou communications marketing seulement si vous avez donné votre consentement express pour recevoir nos communications par SMS.",
    en: "We only call you if necessary related to your order. We may also use it to send newsletters or marketing communications only if you have given your express consent to receive our communications by SMS.",
    es: "Solo le llamamos si es necesario relacionado con su pedido. También podemos usarlo para enviar boletines o comunicaciones de marketing solo si ha dado su consentimiento expreso para recibir nuestras comunicaciones por SMS.",
    nl: "Wij bellen u alleen indien nodig in verband met uw bestelling. We kunnen het ook gebruiken om nieuwsbrieven of marketingcommunicatie te verzenden, alleen als u uw uitdrukkelijke toestemming hebt gegeven om onze communicatie per sms te ontvangen.",
  },
  emailFooter: {
    fr: "Nous utiliserons votre adresse mail que pour la gestion de ce compte et les besoins de votre commande. Nous pourrons également l'utiliser pour l'envoi de newsletters ou communications marketing seulement si vous avez donné votre consentement express pour recevoir nos communications par mail.",
    en: "We will only use your email address for the management of this account and the needs of your order. We may also use it to send newsletters or marketing communications only if you have given your express consent to receive our communications by email.",
    es: "Solo utilizaremos su dirección de correo electrónico para la gestión de esta cuenta y las necesidades de su pedido. También podemos usarlo para enviar boletines o comunicaciones de marketing solo si ha dado su consentimiento expreso para recibir nuestras comunicaciones por correo electrónico.",
    nl: "We zullen uw e-mailadres alleen gebruiken voor het beheer van dit account en de behoeften van uw bestelling. We kunnen het ook gebruiken om nieuwsbrieven of marketingcommunicatie te verzenden, alleen als u uw uitdrukkelijke toestemming hebt gegeven om onze communicatie per e-mail te ontvangen.",
  },
  addressLabel: {
    fr: "Adresse",
    en: "Address",
    es: "Dirección",
    nl: "Adres",
  },
  addressPlaceholder: {
    fr: "Entrez votre adresse",
    en: "Enter your address",
    es: "Introduce tu dirección",
    nl: "Vul uw adres in",
  },
  postcodeLabel: {
    fr: "Code postal",
    en: "Surname *",
    es: "Postcode",
    nl: "Postcode",
  },
  postcodePlaceholder: {
    fr: "Votre CP",
    en: "Your postcode",
    es: "Tu CP",
    nl: "Uw postcode",
  },
  cityLabel: {
    fr: "Ville",
    en: "City",
    es: "Ciudad",
    nl: "Stad",
  },
  cityPlaceholder: {
    fr: "Entrez votre ville",
    en: "Enter your city",
    es: "Introduce tu ciudad",
    nl: "Voer uw stad in",
  },
  passwordFooter: {
    fr: "Votre mot de passe doit contenir au minimum 8 caractères, dont au moins 1 majuscule, 1 minuscule, 1 casse spéciale (@$!%*?&) et 1 chiffre.",
    en: "Your password must contain at least 8 characters, including at least 1 uppercase, 1 lowercase, 1 special case (@$!%*?&) and 1 number.",
    es: "Su contraseña debe contener al menos 8 caracteres, incluidos al menos 1 mayúscula, 1 minúscula, 1 caso especial (@$!%*?&) y 1 número.",
    nl: "Uw wachtwoord moet minimaal 8 tekens bevatten, waarvan minimaal 1 hoofdletter, 1 kleine letter, 1 speciale hoofdletter (@$!%*?&) en 1 cijfer.",
  },
  newsletter: {
    fr: "j’accepte de recevoir les actualités, les offres, remises, cadeaux et avantages.",
    en: "I agree to receive news, offers, discounts, gifts and benefits.",
    es: "Acepto recibir noticias, ofertas, descuentos, regalos y beneficios.",
    nl: "Ik ga akkoord met het ontvangen van nieuws, aanbiedingen, kortingen, geschenken en voordelen.",
  },
  cgv: {
    fr: "J’ai lu et j’accepte la politique de confidentialité Eat Salad",
    en: "I have read and accept the Eat Salad privacy policy",
    es: "He leído y acepto la política de privacidad Eat Salad",
    nl: "Ik heb het privacybeleid van Eat Salad gelezen en geaccepteerd",
  },
  newsletterYes: { fr: "OUI", en: "YES", es: "SÍ", nl: "JA" },
  newsletterNo: { fr: "NON", en: "NO", es: "NO", nl: "NEE" },
  signupTaC: {
    fr: (
      <>
        * Information obligatoire pour adhérer au programme La Fidélité et créer
        son compte.
        <br />
        <br />
        Vos données à caractères personnelles font l'objet d'un traitement par
        la société BARAT CORPORATE, en qualité de responsable du traitement,
        uniquement dans le cadre de la gestion de la relation client pour vous
        fournir un service adapté et répondre à vos demandes et si vous avez
        donné votre consentement express, pour des finalités marketing.
        <br />
        <br />
        Ces données sont destinées à BARAT CORPORATE, aux filiales et franchises
        du groupe EAT SALAD, ainsi qu'aux prestataires qui nous fournissent des
        services liés à la gestion des outils digitaux et des commandes en
        ligne.
        <br />
        <br />
        Conformément à la loi informatique et à la réglementation Européenne
        concernant la protection des données à caractère personnel, vous
        disposez d'un droit d'accès, de rectification, d'opposition et de
        suppression sur les données à caractère personnel vous concernant. Vous
        pouvez exercer ces droits en contactant Eat Salad par l'email suivant :
        <span
          style={{
            color: "#75bb21",
            cursor: "pointer",
            textDecoration: "underline",
          }}
        >
          servicemarketing@eatsalad.com
        </span>
        <br />
        <br />
        En qualité de responsable du traitement des données personnelles, Barat
        Corporate SAS Héliopolis B5 - 18 Avenue Pythagore – Bâtiment C (33700)
        Mérignac capital social 733 324,28 euros., RCS de Bordeaux sous le n°810
        103 275 00014 Traitement de vos données personnelles : Politique de
        confidentialité des données
        <br />
        <br />
        Pour en savoir plus, consultez le Règlement du programme de fidélité La
        Fidélité.
      </>
    ),
    en: (
      <>
        * Mandatory information to join the La Fidélité program and create his
        account.
        <br />
        <br />
        Your personal data is subject to a processing by the company BARAT
        CORPORATE, as responsible for the processing, only in the context of
        customer relationship management to provide you with a suitable service
        and respond to your requests and if you have given your express consent,
        for purposes marketing.
        <br />
        <br />
        This data is intended for BARAT CORPORATE, subsidiaries and franchises
        of the EAT SALAD group, as well as to service providers who provide us
        with services related to the management of digital tools and online
        orders line.
        <br />
        <br />
        In accordance with the IT law and European regulations regarding the
        protection of personal data, you have a right of access, rectification,
        opposition and deletion of personal data concerning you. YOU can
        exercise these rights by contacting Eat Salad by the following email:
        <span
          style={{
            color: "#75bb21",
            cursor: "pointer",
            textDecoration: "underline",
          }}
        >
          servicemarketing@eatsalad.com
        </span>
        <br />
        <br />
        As responsible for the processing of personal data, Barat Corporate SAS
        Heliopolis B5 - 5 rue Laplace 33700 Mérignac capital social 666 662, 14
        euros, RCS of Bordeaux under n°810 103 275 00014 Processing of your
        personal data: Privacy policy Datas
        <br />
        <br />
        For more information, see the Rules of the loyalty program La Loyalty.{" "}
      </>
    ),
    es: (
      <>
        * Información obligatoria para adherirse al programa La Fidélité y crear
        su cuenta.
        <br />
        <br />
        Sus datos personales están sujetos a un tratamiento por parte de la
        empresa BARAT CORPORATE, como responsable del procesamiento, solo en el
        contexto de la gestión de relaciones con el cliente para brindarle un
        servicio adecuado y responder a sus solicitudes y si usted ha dado su
        consentimiento expreso, para fines marketing.
        <br />
        <br />
        Estos datos están destinados a BARAT CORPORATE, filiales y franquicias
        del grupo EAT SALAD, así como a los proveedores de servicios que nos
        brindan servicios relacionados con la gestión de herramientas digitales
        y pedidos online línea.
        <br />
        <br />
        De acuerdo con la ley de TI y la normativa europea en materia de
        protección de datos personales, Ud. tiene derecho de acceso,
        rectificación, oposición y eliminación de los datos personales que le
        conciernen. Vosotras puede ejercer estos derechos dirigiéndose a Eat
        Salad a través del siguiente correo electrónico:
        <span
          style={{
            color: "#75bb21",
            cursor: "pointer",
            textDecoration: "underline",
          }}
        >
          servicemarketing@eatsalad.com
        </span>
        <br />
        <br />
        Como responsable del tratamiento de datos personales, Barat Corporativo
        SAS Heliopolis B5 - 5 rue Laplace 33700 Mérignac capital social 666 662,
        14 euros, RCS de Burdeos bajo el n°810 103 275 00014 Tratamiento de sus
        datos personales: Política de privacidad datos
        <br />
        <br />
        Para más información, consulta las Normas del programa de fidelización
        La Lealtad.
      </>
    ),
    nl: (
      <>
        * Verplichte informatie om deel te nemen aan het La Fidélité-programma
        en te creëren zijn account.
        <br />
        <br />
        Uw persoonlijke gegevens zijn onderworpen aan een verwerking door het
        bedrijf BARAT CORPORATE, als verantwoordelijke voor de verwerking, enkel
        in het kader van klantrelatiebeheer om u een passende service te bieden
        en te reageren op uw verzoeken en indien u hebt uw uitdrukkelijke
        toestemming gegeven, voor doeleinden marketing.
        <br />
        <br />
        Deze gegevens zijn bestemd voor BARAT CORPORATE, dochterondernemingen en
        franchises van de EAT SALAD-groep, evenals aan dienstverleners die ons
        voorzien diensten met betrekking tot het beheer van digitale
        hulpmiddelen en online bestellingen lijn.
        <br />
        <br />
        Conform de IT-wet en Europese regelgeving met betrekking tot de
        bescherming van persoonsgegevens, u hebben een recht van toegang,
        rectificatie, verzet en verwijdering van persoonsgegevens die op u
        betrekking hebben. JIJ kan deze rechten uitoefenen door contact op te
        nemen met Eat Salad via het volgende e-mailadres:
        <span
          style={{
            color: "#75bb21",
            cursor: "pointer",
            textDecoration: "underline",
          }}
        >
          servicemarketing@eatsalad.com
        </span>
        <br />
        <br />
        Als verantwoordelijke voor de verwerking van persoonsgegevens is Barat
        Corporate SAS Heliopolis B5 - 5 rue Laplace 33700 Mérignac hoofdstad
        sociaal 666 662, 14 euro, RCS van Bordeaux onder n°810 103 275 00014
        Verwerking van uw persoonsgegevens: Privacybeleid Gegevens
        <br />
        <br />
        Voor meer informatie, zie de regels van het loyaliteitsprogramma La
        Loyaliteit.
      </>
    ),
  },
  accountHeader1: {
    fr: "/mon-compte/informations-personnelles",
    en: "/en/my-account/personal-information",
    es: "/es/mi-cuenta/datos-personales",
    nl: "/nl/mijn-account/persoonlijke-gegevens",
  },
  accountHeader1Label: {
    fr: "Informations personnelles",
    en: "Personal Information",
    es: "Datos Personales",
    nl: "Persoonlijke gegevens",
  },
  accountHeader2: {
    fr: "/mon-compte/historique-commandes",
    en: "/en/my-account/order-history",
    es: "/es/mi-cuenta/historial-pedidos",
    nl: "/nl/mijn-account/bestelgeschiedenis",
  },
  accountHeader2Label: {
    fr: "Historique de commandes",
    en: "Order History",
    es: "Historial de los pedidos",
    nl: "Bestelgeschiedenis",
  },
  accountHeader3: {
    fr: "/mon-compte/cartes",
    en: "/en/my-account/credit-cards",
    es: "/es/mi-cuenta/tarjetas",
    nl: "/nl/mijn-account/kaarten",
  },
  accountHeader3Label: {
    fr: "Moyens de paiement",
    en: "Payment Methods",
    es: "Formas de pago",
    nl: "Betalingsmiddelen",
  },
  accountHeader4: {
    fr: "/mon-compte/restaurant-favori",
    en: "/en/my-account/favorite-location",
    es: "/es/mi-cuenta/restaurante-preferido",
    nl: "/nl/mijn-account/favoriete-restaurant",
  },
  accountHeader4Label: {
    fr: "Restaurant favori",
    en: "Favorite Location",
    es: "Restaurante preferido",
    nl: "Favoriete restaurant",
  },
  accountHeader5: {
    fr: "/mon-compte/la-fidelite",
    en: "/en/my-account/the-fidelity",
    es: "/es/mi-cuenta/el-fidelite",
    nl: "/nl/mijn-account/de-fidelite",
  },
  accountHeader5Label: {
    fr: "La Fidélité Eat Salad",
    en: "The Fidelity Eat Salad",
    es: "El Fidelity Eat Salad",
    nl: "De Eat Salad Fidelity",
  },
  formula: {
    fr: "Formule",
    en: "Formula",
    es: "Formule",
    nl: "Formule",
  },
  clubHeader1: {
    fr: "Je m’inscris au programme Fidélité.",
    en: "I register for the Loyalty program.",
    es: "Me suscribo al programa de fidelidad.",
    nl: "Ik schrijf me in voor het loyaliteitsprogramma.",
  },
  clubHeader3: {
    fr: "1€ dépensé = 1 point",
    en: "1€ spent = 1 point",
    es: "1€ gastado = 1 punto",
    nl: "1 € uitgegeven = 1 punt",
  },
  clubRegisterButtonLabel: {
    fr: "JE M’INSCRIS",
    en: "I REGISTER",
    es: "ME SUSCRIBO",
    nl: "IK SCHRIJF ME IN",
  },
  fidelityLink: {
    fr: "Règlement pour La Fidélité",
    en: "Rules for Loyalty Program",
    es: "Reglas para la Lealtad",
    nl: "Regels voor loyaliteit",
  },
  paymentMethod: {
    fr: "Ajouter un moyen de paiement",
    en: "Add a payment method",
    es: "Anadir una forma de pago",
    nl: "Een betalingswijze toevoegen",
  },
  paymentHeader1: {
    fr: "Informations de paiement",
    en: "Payment details",
    es: "Información sobre el pago",
    nl: "Betalingsinformatie",
  },
  paymentHeader2: {
    fr: "Moyens de paiement acceptés",
    en: "Accepted payment methods ",
    es: "Formas de pago aceptadas",
    nl: "Aanvaarde betaalmiddelen",
  },
  paymentInput1: {
    fr: "Entrez le nom du titulaire de la carte",
    en: "Enter the name of the card holder",
    es: "Introduce el nombre del titular de la tarjeta",
    nl: "Voer de naam van de kaarthouder in",
  },
  savePaymentHeader: {
    fr: "Sauvegarder votre moyen de paiement",
    en: "Save your payment method for future orders",
    es: "Guardar esta forma de pago",
    nl: "Sla uw betalingswijze op",
  },
  savePaymentText: {
    fr: "Je souhaite enregistrer ma carte sur mon compte pour faciliter mes prochains achats.",
    en: "I would like to save my card information on my account to facilitate my future orders.",
    es: "Quiero guardar esta tarjeta en mi cuenta para facilitar mis futuras compras.",
    nl: "Ik wil graag mijn kaart op mijn rekening registreren om mijn toekomstige aankopen te vergemakkelijken.",
  },
  addToCart: {
    fr: "Ajouter à la carte",
    en: "Add to cart",
    es: "Añadir al carrito",
    nl: "Voeg toe aan winkelwagen",
  },
  includeBaseFormage: {
    fr: "Fromage blanc inclus",
    en: "Cottage cheese included",
    es: "Requesón incluido",
    nl: "Cottage cheese inbegrepen",
  },
  includeCoconutMilk: {
    fr: "Lait de coco + chia Inclus",
    en: "Coconut milk + chia included",
    es: "Leche de coco + chía incluida",
    nl: "Kokosmelk + chia Inbegrepen",
  },
  ourSuggestions: {
    fr: "Suggestions",
    en: "Suggestions",
    es: "Sugerencias",
    nl: "Suggesties",
  },
  selectRestaurantPhoneNumber: {
    fr: "Sélectionner le numéro de téléphone du restaurant",
    en: "Select restaurant phone number",
    es: "Seleccione el número de teléfono del restaurante",
    nl: "Selecteer het telefoonnummer van het restaurant",
  },
  customDessertInfo: {
    fr: "* Sur une base de fromage blanc, ajoutez topping et coulis de votre choix",
    en: "* On a cottage cheese base, add topping and coulis of your choice",
    es: "*Sobre una base de requesón, agrega topping y coulis de tu elección",
    nl: "* Voeg op een kwarkbasis topping en coulis naar keuze toe",
  },
  coldSuggestionsItems: {
    fr: "Nos suggestions froides",
    en: "Our cold suggestions",
    es: "Nuestras sugerencias frías",
    nl: "Onze koude suggesties",
  },
  warmSuggestionsItems: {
    fr: "Nos suggestions chaudes",
    en: "Our hot suggestions",
    es: "Nuestras sugerencias calientes",
    nl: "Onze hete suggesties",
  },
  worldSuggestions: {
    fr: "Saveurs du monde",
    en: "Flavors of the world",
    es: "Sabores del mundo",
    nl: "Smaken van de wereld",
  },
  partnerCompanyDelivery: {
    fr: "Livraison Entreprise Partenaire",
    en: "Partner Company Delivery",
    es: "Entrega Empresa Colaboradora",
    nl: "Levering Partnerbedrijf",
  },
  partnerCompanyDeliveryMessage: {
    fr: (
      <>
        Pour bénéficier de ce service,
        <br />
        vous devez passer commande le jour même avant{" "}
        <span>11h du lundi au vendredi, uniquement le midi.</span> Merci de nous
        indiquer{" "}
        <span>
          le nom de l’entreprise, le lieu ainsi qu’un numéro de téléphone pour
          la livraison à partir de 11h30
        </span>
        , dans la rubrique "Précisions particulières" au niveau du récapitulatif
        de la commande.
        <br />
        <br />
        Merci
      </>
    ),
    en: (
      <>
        To benefit from this service,
        <br />
        you must place an order on the same day before{" "}
        <span>11 am from Monday to Friday, only at lunchtime.</span> Please
        indicate{" "}
        <span>
          the name of the company, the location and a telephone number for
          delivery from 11:30 am
        </span>
        , in the "Special details" section in the summary of the order.
        <br />
        <br />
        Thank you
      </>
    ),
    es: (
      <>
        Para beneficiarse de este servicio,
        <br />
        debe realizar un pedido el mismo día antes de{" "}
        <span>11 am de lunes a viernes, solo a mediodía.</span> Por favor
        indique{" "}
        <span>
          el nombre de la empresa, la ubicación y un número de teléfono para
          entrega a partir de las 11:30 am
        </span>
        , en la sección "Detalles especiales" en el resumen del pedido.
        <br />
        <br />
        Gracias
      </>
    ),
    nl: (
      <>
        Om van deze service te profiteren,
        <br />
        moet u een bestelling plaatsen op dezelfde dag voor{" "}
        <span>11 uur van maandag tot vrijdag, alleen 's middags.</span> Gelieve
        ons te informeren{" "}
        <span>
          de naam van het bedrijf, de locatie en een telefoonnummer voor
          bezorging vanaf 11.30 uur
        </span>
        , in het gedeelte "Bijzondere opmerkingen" in het overzicht van de
        bestelling.
        <br />
        <br />
        Dank u
      </>
    ),
  },
  dessert: {
    fr: "Desserts",
    en: "Desserts",
    es: "Postres",
    nl: "Nagerechten",
  },
  drinks: {
    fr: "Boissons",
    en: "Drinks",
    es: "Bebidas",
    nl: "Drankjes",
  },
  addInFormula: {
    fr: "Ajouter en formule",
    en: "Add in formula",
    es: "Añadir en la fórmula",
    nl: "Voeg toe in formule",
  },
  addInDish: {
    fr: "Plat Seul",
    en: "Dish Alone",
    es: "Plato Solo",
    nl: "Gerecht alleen",
  },
  recipeIdeaChoicePopupHeaader: {
    fr: "Choississez la formule que vous souhaitez prendre pour la recette",
    en: "Choose the formula you want to take for the recipe",
    es: "Elige la fórmula que quieres tomar para la receta",
    nl: "Kies de formule die u voor het recept wilt gebruiken",
  },
  headerNav3: {
    fr: "LA CARTE",
    en: "MENU",
    es: "TARJETA",
    nl: "DE KAART",
  },
  footerLangFrench: {
    fr: "Français",
    en: "French",
    es: "Francés",
    nl: "Frans",
  },
  footerLangEnglish: {
    fr: "Anglais",
    en: "English",
    es: "Inglés",
    nl: "Engels",
  },
  footerLangSpanish: {
    fr: "Espagnol",
    en: "Spanish",
    es: "Español",
    nl: "Spaans",
  },
  footerLangDutch: {
    fr: "Néerlandais",
    en: "Dutch",
    es: "Holandés",
    nl: "Nederlands",
  },
  footerCountryFrance: {
    fr: "France",
    en: "France",
    es: "Francia",
    nl: "Frankrijk",
  },
  footerCountryBelgium: {
    fr: "Belgique",
    en: "Belgium",
    es: "Bélgica",
    nl: "België",
  },
  countryUK: {
    fr: "Grande Bretagne",
    en: "Great Britain",
    es: "Gran Bretaña",
    nl: "Groot-Brittannië",
  },
  countrySpain: {
    fr: "Espagne",
    en: "Spain",
    es: "España",
    nl: "Spanje",
  },
  countryNetherlands: {
    fr: "Pays-Bas",
    en: "Netherlands",
    es: "Países Bajos",
    nl: "Nederland",
  },
  month1: {
    fr: "Janvier",
    en: "January",
    es: "Enero",
    nl: "Januari",
  },
  month2: {
    fr: "Février",
    en: "February",
    es: "Febrero",
    nl: "Februari",
  },
  month3: {
    fr: "Mars",
    en: "March",
    es: "Marzo",
    nl: "Maart",
  },
  month4: {
    fr: "Avril",
    en: "April",
    es: "Abril",
    nl: "April",
  },
  month5: {
    fr: "Mai",
    en: "May",
    es: "Mayo",
    nl: "Mei",
  },
  month6: {
    fr: "Juin",
    en: "June",
    es: "Junio",
    nl: "Juni",
  },
  month7: {
    fr: "Juillet",
    en: "July",
    es: "Julio",
    nl: "Juli",
  },
  month8: {
    fr: "Aout",
    en: "August",
    es: "Agosto",
    nl: "Augustus",
  },
  month9: {
    fr: "Septembre",
    en: "September",
    es: "Septiembre",
    nl: "September",
  },
  month10: {
    fr: "Octobre",
    en: "October",
    es: "Octubre",
    nl: "Oktober",
  },
  month11: {
    fr: "Novembre",
    en: "November",
    es: "Noviembre",
    nl: "November",
  },
  month12: {
    fr: "Decembre",
    en: "December",
    es: "Diciembre",
    nl: "December",
  },
  birthdateLabel: {
    fr: "Date de naissance*",
    en: "Birth date *",
    es: "Fecha de nacimiento *",
    nl: "Geboortedatum *",
  },
  birthdateDayPlaceholder: {
    fr: "Jour",
    en: "Day",
    es: "Día",
    nl: "Dag",
  },
  birthdateMonthPlaceholder: {
    fr: "Mois",
    en: "Month",
    es: "Mes",
    nl: "Maand",
  },
  birthdateYearPlaceholder: {
    fr: "Année",
    en: "Year",
    es: "Año",
    nl: "Jaar",
  },
  originCountryLabel: {
    fr: "Pays *",
    en: "Country *",
    es: "País *",
    nl: "Land*",
  },
  originCountryPlaceholder: {
    fr: "Sélectionnez votre pays*",
    en: "Select your country",
    es: "Selecciona tu país",
    nl: "Selecteer uw land",
  },
  orderCountryLabel: {
    fr: "Je commande dans pays *",
    en: "I order in country *",
    es: "Ordeno en el pais *",
    nl: "Ik bestel in het land *",
  },
  orderCountryPlaceholder: {
    fr: "Sélectionnez le pays dans lequel vous souhaitez commander*",
    en: "Select the country in which you want to order",
    es: "Seleccione el país en el que desea realizar el pedido.",
    nl: "Selecteer het land waarin je wilt bestellen",
  },
  signupFirstNameError: {
    fr: "N'oubliez pas de saisir votre prénom",
    en: "Don't forget to enter your first name",
    es: "No olvides ingresar tu primer nombre",
    nl: "Vergeet niet je voornaam in te vullen",
  },
  signupLastNameError: {
    fr: "S'il vous plaît n'oubliez pas d'entrer votre nom",
    en: "Please remember to enter your last name",
    es: "Por favor no olvides ingresar tu nombre",
    nl: "Vergeet niet je naam in te vullen",
  },
  signupPhonenumberError: {
    fr: "N'oubliez pas d'entrer votre numéro de téléphone",
    en: "Don't forget to enter your phone number",
    es: "No olvides ingresar tu número de teléfono",
    nl: "Vergeet niet je telefoonnummer in te vullen",
  },
  signupOriginCountryError: {
    fr: "N'oubliez pas d'entrer votre pays",
    en: "Don't forget to enter your country",
    es: "No olvides ingresar tu país",
    nl: "Vergeet niet uw land in te vullen",
  },
  signupOrderCountryError: {
    fr: "N'oubliez pas d'entrer le pays depuis lequel vous commandez",
    en: "Don't forget to enter the country you are ordering from",
    es: "Recuerde ingresar el país desde el que realiza el pedido",
    nl: "Vergeet niet het land in te voeren van waaruit u bestelt",
  },
  signupBirthdateError: {
    fr: "N'oubliez pas d'indiquer votre date de naissance",
    en: "Do not forget to indicate your date of birth",
    es: "No olvides indicar tu fecha de nacimiento",
    nl: "Vergeet niet je geboortedatum te vermelden",
  },
  signupEmailError: {
    fr: "S'il vous plaît n'oubliez pas d'entrer votre email",
    en: "Please don't forget to enter your email",
    es: "Por favor no olvides ingresar tu correo electrónico",
    nl: "Vergeet niet je e-mailadres in te vullen",
  },
  signupPasswordError: {
    fr: "N'oubliez pas d'entrer votre mot de passe",
    en: "Don't forget to enter your password",
    es: "No olvides ingresar tu contraseña",
    nl: "Vergeet niet uw wachtwoord in te voeren",
  },
  signupTaCError: {
    fr: "N'oubliez pas d'accepter les termes et conditions",
    en: "Don't forget to accept the terms and conditions",
    es: "No olvides aceptar los términos y condiciones",
    nl: "Vergeet niet de algemene voorwaarden te accepteren",
  },
  langSelectHeader: {
    fr: "Choix des langues et pays",
    en: "Choice of languages ​​and countries",
    es: "Elección de idiomas y países",
    nl: "Keuze uit talen en landen",
  },
  langSelectLanguage: {
    fr: "Sélectionnez votre langue",
    en: "Select your language",
    es: "Elige tu idioma",
    nl: "Selecteer je taal",
  },
  langSelectCountry: {
    fr: "Choisissez votre pays",
    en: "Choose your country",
    es: "Elige tu país",
    nl: "Kies je land",
  },
  validerButton: {
    fr: "VALIDER",
    en: "CONFIRM",
    es: "VALIDAR",
    nl: "BEVESTIGEN",
  },
  annulerButton: {
    fr: "ANNULER",
    en: "CANCEL",
    es: "ANULAR",
    nl: "ANNULEREN",
  },
  myAccountHeader: {
    fr: "Mon compte",
    en: "My account",
    es: "Mi cuenta",
    nl: "Mijn account",
  },
  myProfileHeader: {
    fr: "Mon profil",
    en: "My profile",
    es: "Mi perfil",
    nl: "Mijn profiel",
  },
  historyHeader1: {
    fr: "commande en cours",
    en: "pending order",
    es: "orden pendiente",
    nl: "in afwachting van bestelling",
  },
  historyHeader2: {
    fr: "mes commandes passées",
    en: "my past orders",
    es: "mis pedidos anteriores",
    nl: "mijn eerdere bestellingen",
  },
  historyNext: {
    fr: "Commandes suivantes",
    en: "Next orders",
    es: "Comandos siguientes",
    nl: "Volgende opdrachten",
  },
  historyPrev: {
    fr: "Commandes précédentes",
    en: "Previous orders",
    es: "Órdenes previas",
    nl: "Vorige bestellingen",
  },
  orderDate: {
    fr: "EFFECTUÉE LE",
    en: "DONE ON",
    es: "HECHO EN",
    nl: "KLAAR OP",
  },
  orderNumber: {
    fr: "N° DE COMMANDE",
    en: "ORDER NUMBER",
    es: "NÚMERO DE ORDEN",
    nl: "BESTELLINGSNUMMER",
  },
  orderCardButton1: {
    fr: "TELECHARGER LA FACTURE",
    en: "DOWNLOAD INVOICE",
    es: "DESCARGAR FACTURA",
    nl: "FACTUUR DOWNLOADEN",
  },
  orderCardButton2: {
    fr: "COMMANDER A NOUVEAU",
    en: "ORDER AGAIN",
    es: "ORDENAR DE NUEVO",
    nl: "OPNIEUW BESTELLEN",
  },
  saladContent: {
    fr: "Salade avec",
    en: "Salad with",
    es: "Ensalada con",
    nl: "Salade met",
  },
  dessertContent: {
    fr: "Fromage blanc avec",
    en: "Yogurt with",
    es: "Yogurt con",
    nl: "Yoghurt met",
  },
  orderCardContent1: {
    fr: (
      <>
        <span>Reçue</span>
        <span>En cours de préparation</span>
        <span>Prête</span>
      </>
    ),
    en: (
      <>
        <span>Received</span>
        <span>In preparation</span>
        <span>Ready</span>
      </>
    ),
    es: (
      <>
        <span>Recibió</span>
        <span>En preparación</span>
        <span>Listo</span>
      </>
    ),
    nl: (
      <>
        <span>Hebben ontvangen</span>
        <span>In voorbereiding</span>
        <span>Klaar</span>
      </>
    ),
  },
  orderCardContent2: {
    fr: (
      <>
        <p>
          Votre commande est déjà prête ! Il est malheureusement impossible de
          l'annuler.
        </p>
        <p> Nous vous remercions pour votre compréhension.</p>
      </>
    ),
    en: (
      <>
        <p>
          Your order is already ready! Unfortunately, it is impossible to undo
          it.
        </p>
        <p> Thank you for your understanding.</p>
      </>
    ),
    es: (
      <>
        <p>
          ¡Tu pedido ya está listo! Desafortunadamente, es imposible deshacerlo.
        </p>
        <p>Gracias por su comprensión.</p>
      </>
    ),
    nl: (
      <>
        <p>
          Je bestelling staat al klaar! Helaas is het onmogelijk om het ongedaan
          te maken.
        </p>
        <p>Bedankt voor uw begrip.</p>
      </>
    ),
  },
  orderCardContent3: {
    fr: "Pour annuler votre commande, appelez ",
    en: "To cancel your order, call ",
    es: "Para cancelar su pedido, llame ",
    nl: "Bel om uw bestelling te annuleren",
  },
  paymentContent: {
    fr: "Vous n'avez enregistré aucun moyen de paiement, remplissez le formulaire pour ajouter un nouveau moyen de paiement.",
    en: "You have no payment methods registered yet, fill out the form to add a new paymnent method.",
    es: "No has registrado ninguna forma de pago, rellena el formulario para añadir una nueva forma de pago.",
    nl: "U heeft geen betalingsmiddel geregistreerd, vul het formulier in om een nieuw betalingsmiddel toe te voegen.",
  },
  creditCardRows: {
    fr: (
      <>
        <th>Vos cartes de paiement</th>
        <th>Nom du titulaire</th>
        <th>Date d'expiration</th>
        <th></th>
      </>
    ),
    en: (
      <>
        <th>Your payment cards</th>
        <th>Cardholder name</th>
        <th>Expiration date</th>
        <th></th>
      </>
    ),
    es: (
      <>
        <th>Tus tarjetas de pago</th>
        <th>Nombre del titular</th>
        <th>Fecha de caducidad</th>
        <th></th>
      </>
    ),
    nl: (
      <>
        <th>Uw betaalkaarten</th>
        <th>Naam pashouder</th>
        <th>Vervaldatum</th>
        <th></th>
      </>
    ),
  },
  supprimerButton: {
    fr: "SUPPRIMER",
    en: "DELETE",
    es: "ELIMINAR",
    nl: "VERWIJDEREN",
  },
  creditCardButton: {
    fr: "AJOUTER UN NOUVEAU MOYEN DE PAIEMENT",
    en: "ADD A NEW PAYMENT METHOD",
    es: "AÑADIR UN NUEVO MÉTODO DE PAGO",
    nl: "EEN NIEUWE BETAALMETHODE TOEVOEGEN",
  },
  creditCardHeader1: {
    fr: "Informations de paiement",
    en: "Payment details",
    es: "Información sobre el pago",
    nl: "Betalingsinformatie",
  },
  creditCardHeader2: {
    fr: "Moyens de paiement acceptés",
    en: "Accepted payment methods :",
    es: "Formas de pago aceptadas:",
    nl: "Aanvaarde betaalmiddelen :",
  },
  creditCardHeader3: {
    fr: "Numéro de la carte*",
    en: "Card number*",
    es: "Número de la tarjeta*",
    nl: "Kaartnummer*",
  },
  creditCardHeader4: {
    fr: "Date d'expiration*",
    en: "Expiring date*",
    es: "Fecha de caducidad*",
    nl: "Vervaldatum*",
  },
  creditCardInputLabel: {
    fr: "Nom du titulaire de la carte*",
    en: "Name of the card holder*",
    es: "Nombre del titular de la tarjeta*",
    nl: "Naam van de kaarthouder*",
  },
  creditCardInputPlaceholder: {
    fr: "Entrez le nom du titulaire de la carte",
    en: "Enter the name of the card holder",
    es: "Introduce el nombre del titular de la tarjeta",
    nl: "Voer de naam van de kaarthouder in",
  },
  creditCardHeader5: {
    fr: "En ajoutant le moyen de paiement, je déclare avoir pris connaissance et accepte les ",
    en: "By adding a Payment method, I declare that I understand and accept the general terms and conditions ",
    es: "Al añadir la forma de pago, declaro que he leído y acepto las ",
    nl: "Door het betalingsmiddel toe te voegen, verklaar ik dat ik de voorwaarden aanvaard ",
  },
  favoriteRestaurant: {
    fr: "Votre restaurant favori",
    en: "Your favorite restaurant",
    es: "Tu restaurante favorito",
    nl: "Je favoriete restaurant",
  },
  modifyButton: {
    fr: "MODIFIER",
    en: "MODIFY",
    es: "EDITAR",
    nl: "BEWERKEN",
  },
  favoriteRestaurantContent: {
    fr: "Vous n’avez pas encore choisis un restaurant favori Eat Salad, sélectionnez votre restaurant favori pour gagner du temps",
    en: "You have not yet chosen a favorite Eat Salad restaurant, select your favorite restaurant to save time",
    es: "Todavía no has elegido ningún restaurante preferido Eat Salad, selecciona tu restaurante preferido para ahorrar tiempo",
    nl: "U heeft nog geen favoriet Eat Salad-restaurant gekozen, selecteer uw favoriete restaurant om tijd te besparen",
  },
  favoriteRestaurantButton: {
    fr: "TROUVER UN RESTAURANT",
    en: "FIND A LOCATION",
    es: "ENCONTRAR UN RESTAURANTE",
    nl: "ZOEK EEN RESTAURANT",
  },
  clubContent1: {
    fr: "Ma carte Fidélité",
    en: "My Fidelity card",
    es: "Mi tarjeta de fidelidad",
    nl: "Mijn klantenkaart",
  },
  clubContent2: {
    fr: (
      <>
        <h1>Votre carte</h1>
        {/* <p>en restaurant pour cumuler des points.</p> */}
      </>
    ),
    en: (
      <>
        <h1>Your card</h1>
        {/* <p>in a restaurant to accumulate points.</p> */}
      </>
    ),
    es: (
      <>
        <h1>Tu tarjeta</h1>
        {/* <p>en un restaurante para acumular puntos.</p> */}
      </>
    ),
    nl: (
      <>
        <h1>Jouw kaart</h1>
        {/* <p>in een restaurant om punten te verzamelen.</p> */}
      </>
    ),
  },
  clubPasskitServerError: {
    fr: "Une erreur s'est produite lors de la création de votre carte PassKit. Veuillez réessayer plus tard",
    en: "An error has occured while creating your PassKit card. Please try again later",
    es: "Ha ocurrido un error al crear su tarjeta PassKit. Por favor, inténtelo de nuevo más tarde",
    nl: "Er is een fout opgetreden bij het maken van uw PassKit-kaart. Probeer het later opnieuw",
  },
  clubPasskitGetButton: {
    fr: "OBTENIR MA CARTE DE FIDÉLITÉ",
    en: "GET MY LOYALTY CARD",
    es: "OBTENER MI TARJETA DE FIDELIZACIÓN",
    nl: "ONTVANG MIJN KLANTENKAART",
  },
  clubPasskitSeeButton: {
    fr: "AJOUTER A MON WALLET",
    en: "ADD TO MY WALLET",
    es: "AÑADIR A MI BILLETERA",
    nl: "TOEVOEGEN AAN MIJN PORTEMONNEE",
  },
  clubPasskitRegisterSuccess: {
    fr: "Passkit généré avec succès ! Vous allez le recevoir par email.",
    en: "PassKit successfully generated ! You will receive it by email.",
    es: "¡Passkit generado con éxito! Lo recibirás por correo electrónico.",
    nl: "Passkit succesvol gegenereerd! Je ontvangt het per e-mail.",
  },
  clubButton1: {
    fr: "AFFICHER MON CODE BARRE",
    en: "SHOW MY BARCODE",
    es: "MOSTRAR MI CÓDIGO DE BARRAS",
    nl: "TOON MIJN BARCODE",
  },
  clubContent3: {
    fr: (
      <>
        <h1>Le fonctionnement</h1>
        <ul>
          <li>1€ dépensé &nbsp;=&nbsp; 1 point cumulé</li>
          <li>Présentez votre code barre à chaque passage en caisse</li>
          <li>Profitez de vos gratuités</li>
        </ul>
      </>
    ),
    en: (
      <>
        <h1>How it works</h1>
        <ul>
          <li>1€ spent &nbsp;=&nbsp; 1 point accumulated</li>
          <li>Present your barcode at each checkout</li>
          <li>Enjoy your free items</li>
        </ul>
      </>
    ),
    es: (
      <>
        <h1>Como funciona</h1>
        <ul>
          <li>1€ gastado &nbsp;=&nbsp; 1 punto acumulado</li>
          <li>Presenta tu código de barras en cada caja</li>
          <li>Disfruta de tus artículos gratis</li>
        </ul>
      </>
    ),
    nl: (
      <>
        <h1>Hoe het werkt</h1>
        <ul>
          <li>1€ uitgegeven &nbsp;=&nbsp; 1 punt verzameld</li>
          <li>Presenteer uw barcode bij elke kassa</li>
          <li>Geniet van je gratis items</li>
        </ul>
      </>
    ),
  },
  clubContent4: {
    fr: "Comment utiliser mes points ?",
    en: "How do I use my points?",
    es: "¿Cómo uso mis puntos?",
    nl: "Hoe gebruik ik mijn punten?",
  },
  clubContent5: {
    fr: (
      <>
        <p>
          Connectez-vous à votre compte Fidélité sur eatsalad.com et commandez
          pour cumuler vos points.
          <br />
          Ajoutez vos gratuités à votre panier.
        </p>
      </>
    ),
    en: (
      <>
        <p>
          Connect to your Fidelity account on eatsalad.com and order to
          accumulate your points.
          <br />
          Add your free items to your cart.
        </p>
      </>
    ),
    es: (
      <>
        <p>
          Conéctese a su cuenta Fidélité en eatsalad.com y ordene para acumular
          sus puntos.
          <br />
          Agrega tus artículos gratis a tu carrito.
        </p>
      </>
    ),
    nl: (
      <>
        <p>
          Maak verbinding met uw Loyalty-account op eatsalad.com en bestel om uw
          punten te verzamelen.
          <br />
          Voeg je gratis artikelen toe aan je winkelwagen.
        </p>
      </>
    ),
  },
  clubContent6: {
    fr: (
      <p>
        Depuis votre espace fidélité, réservez vos avantage puis présentez votre
        Qrcode au moment du paiement.
        <br />
        Vous n'avez besoin de rien d'autre, tout est relié à votre carte 100%
        digitalisée.
      </p>
    ),
    en: (
      <p>
        From your loyalty space, book your advantage then present your Qrcode at
        the time of payment.
        <br />
        You don't need anything else, everything is linked to your 100%
        digitized card.
      </p>
    ),
    es: (
      <p>
        Desde su espacio de fidelidad, reserve su ventaja y presente su código
        QR en el momento del pago.
        <br />
        No necesitas nada más, todo está vinculado a tu tarjeta digitalizada al
        100%.
      </p>
    ),
    nl: (
      <p>
        Vanuit uw loyaliteitsruimte boekt u uw voordeel en presenteert u uw
        Qrcode op het moment van betaling.
        <br />U heeft niets anders nodig, alles is gekoppeld aan uw 100%
        gedigitaliseerde kaart.
      </p>
    ),
  },
  clubButton2: {
    fr: "CHOISIR MES PRODUITS GRATUITS",
    en: "CHOOSE MY FREE PRODUCTS",
    es: "ELIGE MIS PRODUCTOS GRATIS",
    nl: "KIES MIJN GRATIS PRODUCTEN",
  },
  clubContent7: {
    fr: "Choisir mes produits gratuits",
    en: "Choose my free products",
    es: "Elige nis productos graits",
    nl: "Kies mijn graits producten",
  },
  points: {
    fr: "points",
    en: "points",
    es: "puntos",
    nl: "punten",
  },
  disconnect: {
    fr: "Se déconnecter",
    en: "Log out",
    es: "Desconectarse",
    nl: "Afmelden",
  },
  footerHeader1: {
    fr: "Eat Salad & Vous",
    en: "Eat Salad & You",
    es: "Eat Salad & Tú",
    nl: "Eat Salad & Jij",
  },
  footerHeader2: {
    fr: "Professionnels",
    en: "Careers",
    es: "Profesionales",
    nl: "Bedrijven",
  },
  footerHeader3: {
    fr: "Utils",
    en: "Useful",
    es: "Útil",
    nl: "Bruikbaar",
  },
  footerHeader4: {
    fr: "Social",
    en: "Social",
    es: "Social",
    nl: "Sociaal",
  },
  languageHeader: {
    fr: "LANGUE",
    en: "LANGUAGE",
    es: "IDIOMA",
    nl: "TAAL",
  },
  homepageHeader: {
    fr: "ACCUEIL",
    en: "HOMEPAGE",
    es: "INICIO",
    nl: "STARTPAGINA",
  },
  conceptHeader: {
    fr: "CONCEPT",
    en: "CONCEPT",
    es: "CONCEPTO",
    nl: "CONCEPT",
  },
  franchiseHeader: {
    fr: "DEVENIR FRANCHISÉ",
    en: "BECOME A FRANCHISE",
    es: "CONVIÉRTETE EN FRANQUICIADIO",
    nl: "WORD FRANCHISENEMER",
  },
  fidelityHeader: {
    fr: "LA FIDÉLITÉ",
    en: "FIDELITY",
    es: "FIDELIDAD",
    nl: "KLANTENKART",
  },
  productsHeader: {
    fr: "NOS PRODUITS",
    en: "OUR PRODUCTS",
    es: "NUESTROS PRODUCTOS",
    nl: "ONZE PRODUCTEN",
  },
  newsHeader: {
    fr: "ACTUALITÉS",
    en: "NEWS",
    es: "NOTICIAS",
    nl: "NIEUWS",
  },
  blogHeader: {
    fr: "LE BLOG",
    en: "THE BLOG",
    es: "EL BLOG",
    nl: "DE BLOG",
  },
  allergyHeader: {
    fr: "Liste des allergènes et ",
    en: "List of allergens and ",
    es: "Lista de alérgenos y ",
    nl: "Lijst van allergenen en ",
  },
  allergyHeader2: {
    fr: "Liste des allergènes",
    en: "List of allergens",
    es: "Lista de alérgenos",
    nl: "Lijst van allergenen",
  },
  restaurantsHeader: {
    fr: "Nos restaurants",
    en: "Our restaurants",
    es: "Nuestros restaurants",
    nl: "Onze restaurants",
  },
  ingredientsHeader: {
    fr: "Nos produits",
    en: "Our products",
    es: "Nuestros productos",
    nl: "Onze Producten",
  },
  recruitementHeader: {
    fr: "Recrutement",
    en: "Recruitement",
    es: "Empleo",
    nl: "Aanwerving",
  },
  contactHeader: {
    fr: "Nous contacter",
    en: "Contact us",
    es: "Contáctenos",
    nl: "Neem contact op",
  },
  contactHeader3: {
    fr: "Service client",
    en: "Customer service",
    es: "Servicio al cliente",
    nl: "Klantenservice",
  },
  contactHeader4: {
    fr: "Contact",
    en: "Contact",
    es: "Contacto",
    nl: "Contact",
  },
  and: {
    fr: "et",
    en: "and",
    es: "y",
    nl: "en",
  },
  and2: {
    fr: "ou",
    en: "and",
    es: "y",
    nl: "en",
  },
  ourLocations: {
    fr: "Nos Restaurants",
    en: "Our locations",
    es: "Nuestros restaurantes",
    nl: "Onze restaurants",
  },
  location: {
    fr: "LOCALISATION",
    en: "LOCATION",
    es: "LOCALIZACIÓN",
    nl: "PLAATS",
  },
  restaurantList: {
    fr: "LISTE RESTAURANTS",
    en: "RESTAURANT LIST",
    es: "LISTA DE RESTAURANTES",
    nl: "RESTAURANTLIJST",
  },
  orderNow: {
    fr: "COMMANDER",
    en: "ORDER NOW",
    es: "PEDIR",
    nl: "BESTELLEN",
  },
  toTakeaway: {
    fr: "À EMPORTER",
    en: "TO TAKE AWAY",
    es: "PARA LLEVAR",
    nl: "WEGNEMEN",
  },
  delivery: {
    fr: "EN LIVRAISON",
    en: "IN DELIVERY",
    es: "EN LA ENTREGA",
    nl: "ONDERWEG",
  },
  deliverySearchPlaceholder: {
    fr: "Saisissez votre ville",
    en: "Enter your city",
    es: "Ingresa tu ciudad",
    nl: "Vul je stad in",
  },
  deliverySelection: {
    fr: "Sélectionner",
    en: "Select",
    es: "Seleccione",
    nl: "Kies een bezorgservice",
  },
  deliveryService: {
    fr: "Sélectionnez un service de livraison",
    en: "Select a delivery service",
    es: "Seleccione un servicio de entrega",
    nl: "Selecteer",
  },
  learnMore: {
    fr: "EN SAVOIR PLUS",
    en: "LEARN MORE",
    es: "MÁS INFORMACIÓN",
    nl: "MEER INFORMATIE GEBRUIKSAANWIJZING",
  },
  loyalty: {
    fr: "VOIR MA FIDÉLITÉ",
    en: "SEE MY LOYALTY",
    es: "VER MI LEALTAD",
    nl: "ZIE MIJN LOYALITEIT",
  },
  allOurRestaurants: {
    fr: "TOUS NOS RESTAURANTS",
    en: "ALL OUR RESTAURANTS",
    es: "TODOS NUESTROS RESTAURANTES",
    nl: "AL ONZE RESTAURANTS",
  },
  takeawayHeader1: {
    fr: "Sélectionner votre restaurant de retrait",
    en: "Select your pick-up restaurant",
    es: "Selecciona tu restaurante de retiro",
    nl: "Selecteer je afhaalrestaurant",
  },
  selectRestaurant: {
    fr: "Sélectionner votre restaurant",
    en: "Select your restaurant",
    es: "Selecciona tu restaurante",
    nl: "Selecteer uw restaurant",
  },
  takeawayHeader2: {
    fr: "Adresse ou restaurant de retrait",
    en: "Address or pick-up restaurant",
    es: "Restaurante de retiro",
    nl: "Afhaalrestaurant",
  },
  takeawayHeader3: {
    fr: "Sélectionner le jour",
    en: "Select day",
    es: "Seleccionar día",
    nl: "Selecteer dag",
  },
  takeawayHeader4: {
    fr: "Sélectionner l'horaire",
    en: "Select time",
    es: "Seleccionar hora",
    nl: "Selecteer tijd",
  },
  takeawayHeader5: {
    fr: "Horaire",
    en: "Time",
    es: "Tiempo",
    nl: "Tijd",
  },
  openUntil: {
    fr: "Ouvre à",
    en: "Open until",
    es: "Abierto hasta",
    nl: "Open tot",
  },
  enterAddress: {
    fr: "Saisir une adresse",
    en: "Enter an address",
    es: "Introducir una dirección",
    nl: "Geef een adres in",
  },
  restaurantSelect: {
    fr: "Selectionner un restaurant Eat Salad",
    en: "Select an Eat Salad restaurant",
    es: "Seleccione un restaurante Eat Salad",
    nl: "Selecteer een Eat Salad-restaurant",
  },
  contactHeader1: {
    fr: "Contactez-nous",
    en: "Contact us",
    es: "Contáctanos",
    nl: "Neem contact met ons op",
  },
  contactHeader2: {
    fr: "Suivez nous",
    en: "Follow us",
    es: "Síguenos",
    nl: "Volg ons",
  },
  contactFormHeader1: {
    fr: (
      <h2>
        Vous avez une demande ?<br />
        Dites-nous en plus
      </h2>
    ),
    en: (
      <h2>
        Do you have a request ?<br />
        Tell us more
      </h2>
    ),
    es: (
      <h2>
        ¿Tienes una petición?
        <br />
        Cuéntanos más
      </h2>
    ),
    nl: (
      <h2>
        Heeft u een verzoek?
        <br />
        Vertel ons meer
      </h2>
    ),
  },
  contactFormHeader2: {
    fr: "Vous pouvez nous contacter par téléphone ou en remplissant le formulaire ci-dessous",
    en: "You can contact us by phone or by filling out the form below",
    es: "Puedes ponerte en contacto con nosotros por teléfono o rellenando el siguiente formulario",
    nl: "U kunt telefonisch contact met ons opnemen of onderstaand formulier invullen",
  },
  email: {
    fr: "Adresse e-mail *",
    en: "E-mail address",
    es: "Dirección de correo electrónico *",
    nl: "Email * ",
  },
  enterEmail: {
    fr: "Indiquez votre adresse e-mail",
    en: "Indicate your e-mail address",
    es: "Introduce tu dirección de correo electrónico",
    nl: "Vul uw e-mailadres in",
  },
  message: {
    fr: "Commentaire *",
    en: "Comment *",
    es: "Observación *",
    nl: "Opmerking *",
  },
  enterMessage: {
    fr: "Saisissez votre demande",
    en: "Enter your request",
    es: "Ingrese su solicitud",
    nl: "Voer uw verzoek in",
  },
  submitButton: {
    fr: "ENVOYER",
    en: "SUBMIT",
    es: "ENVIAR",
    nl: "VERZENDEN",
  },
  footerRights: {
    fr: (
      <>
        2023 Eat Salad <br /> <span>Tous droits réservés</span>
      </>
    ),
    en: (
      <>
        2023 Eat Salad <br /> <span>All Rights Reserved</span>
      </>
    ),
    es: (
      <>
        2023 Eat Salad <br /> <span>Todos los derechos reservados</span>
      </>
    ),
    nl: (
      <>
        2023 Eat Salad <br /> <span>Alle rechten voorbehouden</span>
      </>
    ),
  },
  footerLegalNoticeHeader: {
    fr: "Mentions légales",
    en: "Legal notice",
    es: "Aviso legal",
    nl: "Wettelijke bepalingen",
  },
  footerCgvHeader: {
    fr: "C.G.V./C.G.U",
    en: "Terms & conditions",
    es: "C.G.V./C.G.U",
    nl: "A.V.V./A.V.",
  },
  footerPrivacyPolicyHeader: {
    fr: "Données personnelles",
    en: "Privacy Policy",
    es: "Datos personales",
    nl: "Persoonsgegevens",
  },
  footerLeClubTerms: {
    fr: "Réglement La Fidélité",
    en: "Terms & conditions of La Fidélité",
    es: "Reglamento El Club",
    nl: "Regels van de Club",
  },
  footerCookieSettingsHeader: {
    fr: "Paramétrage des cookies",
    en: "Cookies settings",
    es: "Configuración de las cookies",
    nl: "Cookie-instellingen",
  },
  footerContestRulesHeader: {
    fr: "Règlement Jeu Concours",
    en: "Contest rules",
    es: "Las reglas del concurso",
    nl: "Wedstrijdregels",
  },
  cookiesSettings: {
    fr: "Panneau de gestion des cookies",
    en: "Cookies Settings",
    es: "Panel de gestión de cookies",
    nl: "Paneel voor cookiebeheer",
  },
  cookiesFirstParagraph: {
    fr: (
      <>
        Sur notre site Eat Salad, en dehors des cookies strictement nécessaires
        à l’utilisation du site qui ne requièrent pas votre consentement, vous
        pouvez paramétrer les autres cookies qui servent à optimiser les
        performances de notre site, à vous proposer des services
        complémentaires, à collecter les statistiques anonymes en vue
        d'optimiser les fonctionnalités du site, à vous adresser des publicités
        et contenus personnalisés. <br /> <br />
        Vous pouvez également configurer votre navigateur afin de bloquer ou
        être informé de l'existence de ces cookies.
        <br /> <br />
        Ces cookies ne stockent aucune information d’identification personnelle.
        <br /> <br />
        Pour en savoir plus :{" "}
        <span style={{ textDecoration: "underline" }}>
          <a
            href="https://eatsalad.com/donnees-personnelles/"
            target="_blank"
            rel="noreferrer"
          >
            consulter notre politique et la liste des cookies{" "}
          </a>
        </span>
      </>
    ),
    en: (
      <>
        On our Eat Salad website, apart from cookies strictly necessary for the
        use of the site which do not require your consent, you can customize
        other cookies which are used to optimize the performance of our site, to
        offer you additional services, to collect anonymous statistics in order
        to optimize the functionality of the site, to send you personalized
        advertising and content. <br /> <br />
        You can also configure your browser to block or be informed of the
        existence of these cookies.
        <br /> <br />
        These cookies do not store any personal identification information.
        <br /> <br />
        To know more{" "}
        <span style={{ textDecoration: "underline" }}>
          <a
            href="https://eatsalad.com/donnees-personnelles/"
            target="_blank"
            rel="noreferrer"
          >
            consult our policy and the list of cookies
          </a>
        </span>
      </>
    ),
    es: (
      <>
        En nuestro sitio web Eat Salad, Aparte de las cookies estrictamente
        necesarias para el uso del sitio, que no requieren tu consentimiento,
        puedes configurar las demás cookies que se utilizan para optimizar el
        rendimiento de nuestro sitio, para ofrecerte servicios adicionales, para
        recoger estadísticas anónimas con vistas a optimizar las funciones del
        sitio, para enviarte publicidad y contenidos personalizados <br />{" "}
        <br />
        También puedes configurar el navegador para bloquear o ser informado de
        la existencia de estas cookies.
        <br /> <br />
        Estas cookies no almacenan ninguna información de identificación
        personal. <br /> <br />
        Más información:{" "}
        <span style={{ textDecoration: "underline" }}>
          <a
            href="https://eatsalad.com/donnees-personnelles/"
            target="_blank"
            rel="noreferrer"
          >
            consulta nuestra política y nuestra lista de cookies
          </a>
        </span>
      </>
    ),
    nl: (
      <>
        Op onze Eat Salad website kunt u, afgezien van cookies die strikt
        noodzakelijk zijn voor het gebruik van de site waarvoor uw toestemming
        niet vereist is, andere cookies configureren die worden gebruikt om de
        prestaties van onze site te optimaliseren, om u aanvullende diensten aan
        te bieden, om anonieme statistieken te verzamelen om de functionaliteit
        van de site te optimaliseren, om u gepersonaliseerde advertenties en
        inhoud te sturen. <br /> <br />U kunt uw browser ook zo configureren dat
        deze cookies blokkeert of op de hoogte wordt gesteld van het bestaan van
        deze cookies. <br /> <br />
        Deze cookies slaan geen persoonlijk identificeerbare informatie op.{" "}
        <br /> <br />
        Voor meer informatie:{" "}
        <span style={{ textDecoration: "underline" }}>
          <a
            href="https://eatsalad.com/donnees-personnelles/"
            target="_blank"
            rel="noreferrer"
          >
            raadpleeg ons beleid en de lijst met cookies
          </a>
        </span>
      </>
    ),
  },
  cookieTypesHeader: {
    fr: "Sélectionnez votre choix pour chaque catégorie de cookies. Vous pouvez modifier à tout moment votre choix depuis la page paramétrer les cookies",
    en: "Select your choice for each category of cookies. You can change your choice at any time from the cookies settings page",
    es: "Selecciona tu elección para cada categoría de cookies. Puedes cambiar tu elección en cualquier momento desde la página de configuración de las cookies",
    nl: "Selecteer uw keuze voor elke categorie cookies. U kunt uw keuze op elk moment wijzigen via de pagina cookies configureren",
  },
  necessaryCookiesHeader: {
    fr: "Cookies strictement nécessaires",
    en: "Strictly necessary cookies",
    es: "Cookies estrictamente necesarias",
    nl: "Cookies die strikt noodzakelijk zijn.",
  },
  necessaryCookiesParagraph: {
    fr: "Ces cookies sont nécessaires au fonctionnement de notre site web et ne peuvent être désactivés. Ils servent à votre connexion, à créer votre compte, passer votre commande et localiser votre restaurant le plus proche pour votre retrait de commande. Si vous souhaitez les désactiver, vous pouvez configurer votre navigateur afin de les bloquer mais certaines parties du site web seront affectées et vous ne pourrez utiliser toutes les fonctionnalités du site.",
    en: "These cookies are necessary for the operation of our website and cannot be disabled. They are used for your connection, to create your account, to place your order and to locate your nearest restaurant for your order pick-up. If you wish to disable them, you can configure your browser to block them, but some parts of the website will be affected and you will not be able to use all the features of the site.",
    es: "Estas cookies son necesarias para el funcionamiento de nuestro sitio web y no se pueden desactivar. Se utilizan para iniciar la sesión, crear la cuenta, hacer el pedido y localizar el restaurante más cercano para recogerlo. Si deseaS desactivarlas, puedeS configurar tu navegador para que las bloquee, pero algunas partes del sitio web se verán afectadas y no podrás utilizar toda su funcionalidad.",
    nl: "Deze cookies zijn noodzakelijk voor de werking van onze website en kunnen niet worden uitgeschakeld. Ze worden gebruikt voor uw verbinding, om uw account aan te maken, om uw bestelling te plaatsen en om uw dichtstbijzijnde restaurant te vinden om uw bestelling af te halen. Als u ze wilt uitschakelen, kunt u uw browser zo configureren dat ze worden geblokkeerd, maar sommige delen van de website zullen hierdoor worden beïnvloed en u zult niet alle functies van de site kunnen gebruiken.",
  },
  necessaryCookiesActive: {
    fr: "TOUJOURS ACTIFS",
    en: "ALWAYS ACTIVE",
    es: "SIEMPRE ACTIVAS",
    nl: "ALTIJD ACTIEF",
  },
  adsCookieHeader: {
    fr: "Cookies publicitaires",
    en: "Advertising cookies",
    es: "Cookies publicitarias",
    nl: "Advertentiecookies",
  },
  adsCookieParagraph: {
    fr: "Ces cookies permettent à des tiers, partenaires publicitaires, de vous adresser des publicités ciblées et adaptées à vos centres d'intérêt en dehors de notre site. Ils vous permettent également de recevoir des offres Eat Salad sur des sites tiers.",
    en: "These cookies allow third parties, advertising partners, to send you targeted advertising tailored to your areas of interest outside our site. They also allow you to receive Eat Salad offers on third party sites.",
    es: "Estas cookies permiten a terceros, socios publicitarios, enviarte publicidad dirigida y adaptada a tus áreas de interés fuera de nuestro sitio. También te permiten recibir ofertas de Eat Salad en sitios de terceros.",
    nl: "Met deze cookies kunnen derde partijen, advertentiepartners, u gerichte advertenties sturen die zijn afgestemd op uw interessegebieden buiten onze site. Ze stellen u ook in staat om Eat Salad-aanbiedingen te ontvangen op sites van derden.",
  },
  performanceCookieHeader: {
    fr: "Cookies de performance et d’analyse",
    en: "Performance and analysis cookies",
    es: "Cookies de rendimiento y análisis",
    nl: "Prestatie- en analysecookies",
  },
  performanceCookieParagraph: {
    fr: "Ces cookies nous permettent d’obtenir des statistiques de fréquentation du site pour améliorer son ergonomie, ses contenus et donc améliorer votre navigation.",
    en: "These cookies allow us to obtain site traffic statistics to improve its ergonomics and content and therefore improve your browsing.",
    es: "Estas cookies nos permiten obtener estadísticas sobre el número de visitantes de la página web para mejorar su ergonomía y contenido y así mejorar la experiencia de navegación.",
    nl: "Deze cookies stellen ons in staat om statistieken over het siteverkeer te verkrijgen om de ergonomie en de inhoud ervan te verbeteren en dus uw browsen te verbeteren.",
  },
  personalizationCookieHeader: {
    fr: "Cookies de personnalisation",
    en: "Personalization cookies",
    es: "Cookies de personalización",
    nl: "Personalisatie cookies",
  },
  personalizationCookieParagraph: {
    fr: "Ils vous permettent de visiter le site de manière personnalisée, selon vos anciennes visites, vos achats, etc. Vous trouverez de manière plus rapide les offres qui vous correspondent le mieux.",
    en: "They allow you to visit the site in a personalized way, according to your previous visits, your purchases, etc. You will find the offers that best suit you more quickly.",
    es: "Cookies estrictamente necesarias",
    nl: "Ze stellen u in staat om de site op een gepersonaliseerde manier te bezoeken, in overeenstemming met uw eerdere bezoeken, uw aankopen, enz. U vindt sneller de aanbiedingen die het beste bij u passen.",
  },
  cookieAcceptButtonLabel: {
    fr: "ACCEPTER",
    en: "ACCEPT",
    es: "ACCEPTAR",
    nl: "ACCEPTEREN",
  },
  cookieRefuseButtonLabel: {
    fr: "REFUSER",
    en: "REFUSE",
    es: "RECHAZAR",
    nl: "WEIGEREN",
  },
  restaurantChangePopupMessage1: {
    fr: "Certains des ingrédients que vous avez choisis ne sont plus disponibles et ont été retirés de votre panier :",
    en: "Some of the ingredients you selected are no longer available and have been removed from your cart :",
    es: "Algunos de los ingredientes que seleccionó ya no están disponibles y se han eliminado de su carrito:",
    nl: "Sommige ingrediënten die u hebt geselecteerd, zijn niet langer beschikbaar en zijn uit uw winkelwagen verwijderd:",
  },
  restaurantChangePopupMessage2: {
    fr: "Merci de compléter votre commande ou de poursuivre en cliquant sur PAYER.",
    en: "Please complete your order or continue by clicking PAY.",
    es: "Complete su pedido o continúe haciendo clic en PAGAR.",
    nl: "Rond uw bestelling af of ga verder door op BETALEN te klikken.",
  },
  userCookiesPopupTitle: {
    fr: "Notre politique de gestion des cookies",
    en: "Our cookie management policy",
    es: "Nuestra política de gestión de cookies",
    nl: "Ons cookiebeheerbeleid",
  },
  userCookiesPopupDescription: {
    fr: "Notre site Eat Salad utilise des cookies pour vous garantir une meilleure navigation, pour vous proposer des offres et services personnalisés et pour réaliser des statistiques de visite pour améliorer votre expérience client sur notre site.",
    en: "Our Eat Salad site uses cookies to guarantee you better navigation, to provide you with personalized offers and services and to compile visit statistics to improve your customer experience on our site.",
    es: "Nuestro sitio Eat Salad utiliza cookies para garantizarle una mejor navegación, brindarle ofertas y servicios personalizados y recopilar estadísticas de visitas para mejorar su experiencia como cliente en nuestro sitio.",
    nl: "Onze Eat Salad-site maakt gebruik van cookies om u een betere navigatie te garanderen, om u gepersonaliseerde aanbiedingen en diensten te bieden en om bezoekstatistieken samen te stellen om uw klantervaring op onze site te verbeteren.",
  },
  userCookiesPopupLink: {
    fr: "Voir notre politique et la liste des cookies.",
    en: "See our policy and the list of cookies.",
    es: "Consulte nuestra política y la lista de cookies.",
    nl: "Zie ons beleid en de lijst met cookies.",
  },
  userCookiesPopupReject: {
    fr: "TOUT REFUSER",
    en: "REFUSE ALL",
    es: "RECHAZAR TODO",
    nl: "ALLES WEIGEREN",
  },
  userCookiesPopupAccept: {
    fr: "TOUT ACCEPTER",
    en: "ACCEPT ALL",
    es: "ACEPTAR TODO",
    nl: "ACCEPTEER ALLES",
  },
  userCookiesPopupMore: {
    fr: "PARAMÉTRER LES COOKIES",
    en: "SET COOKIES",
    es: "ESTABLECER COOKIES",
    nl: "COOKIES INSTELLEN",
  },
  outOfServicePopupText: {
    fr: "Chers clients, notre nouveau site arrive bientôt ! Brève maintenance en cours. Revenez vite pour commander vos salades avec une fraîcheur inégalée. Merci !",
    en: "Dear customers, our new website is coming soon! Brief maintenance in progress. Come back soon to order your salads with unparalleled freshness. Thank You!",
    es: "Estimados clientes, ¡nuestro nuevo sitio web llegará pronto! Breve mantenimiento en curso. Vuelve pronto para pedir tus ensaladas con una frescura inigualable. Gracias !",
    nl: "Beste klanten, onze nieuwe website komt eraan! Er wordt kort onderhoud gepleegd. Kom snel terug om uw salades met een ongeëvenaarde versheid te bestellen. Dankjewel !",
  },
  eatfid12PointsInfo: {
    fr: (
      <>
        1 pain
        <br />
        <span>ou</span>
        <br />1 sauce
      </>
    ),
    en: (
      <>
        1 bread
        <br />
        <span>or</span>
        <br />1 sauce
      </>
    ),
    es: (
      <>
        1 pan
        <br />
        <span>o</span>
        <br />1 salsa
      </>
    ),
    nl: (
      <>
        1 brood
        <br />
        <span>of</span>
        <br />1 saus
      </>
    ),
  },
  eatfid36PointsInfo: {
    fr: (
      <>
        1 boisson <span>ou</span>
        <br />1 dessert <span>ou</span>
        <br />1 petit gaspacho / soupe
      </>
    ),
    en: (
      <>
        1 drink <span>or</span>
        <br />1 dessert <span>or</span>
        <br />1 gazpacho / soup
      </>
    ),
    es: (
      <>
        1 beber <span>o</span>
        <br />1 postre <span>o</span>
        <br />1 gazpacho / sopa
      </>
    ),
    nl: (
      <>
        1 drankje <span>of</span>
        <br />1 dessert <span>of</span>
        <br />1 gazpacho / soep
      </>
    ),
  },
  eatfid60PointsInfo: {
    fr: (
      <>
        1 boisson chaude <br />
        <span>+</span>
        <br />1 dessert
      </>
    ),
    en: (
      <>
        1 hot beverage <br />
        <span>+</span>
        <br />1 dessert
      </>
    ),
    es: (
      <>
        1 bebida caliente
        <br />
        <span>+</span>
        <br />1 postre
      </>
    ),
    nl: (
      <>
        1 warme drank
        <br />
        <span>+</span>
        <br />1 dessert
      </>
    ),
  },
  eatfid120PointsInfo: {
    fr: (
      <>
        1 Formule <span>4</span> ingrédients
        <br />
        <span>+</span> boisson
        <br />
        <span>ou</span> dessert
      </>
    ),
    en: (
      <>
        1 Formula <span>4</span> ingredients
        <br />
        <span>+</span> drink
        <br />
        <span>or</span> dessert
      </>
    ),
    es: (
      <>
        1 Fórmula <span>4</span> ingredientes
        <br />
        <span>+</span> bebida
        <br />
        <span>o</span> postre
      </>
    ),
    nl: (
      <>
        1 Formule <span>4</span> ingrediënten
        <br />
        <span>+</span> drankje
        <br />
        <span>of</span> toetje
      </>
    ),
  },
  eatfid132PointsInfo: {
    fr: (
      <>
        1 Formule <span>4</span> ingrédients
        <br />
        <span>+</span> boisson
        <br />
        <span>+</span> dessert
      </>
    ),
    en: (
      <>
        1 Formula <span>4</span> ingredients
        <br />
        <span>+</span> drink
        <br />
        <span>+</span> dessert
      </>
    ),
    es: (
      <>
        1 Fórmula <span>4</span> ingredientes
        <br />
        <span>+</span> bebida
        <br />
        <span>+</span> postre
      </>
    ),
    nl: (
      <>
        1 Formule <span>4</span> ingrediënten
        <br />
        <span>+</span> drankje
        <br />
        <span>+</span> toetje
      </>
    ),
  },
  eatfid152PointsInfo: {
    fr: (
      <>
        1 Formule <span>6</span> ingrédients
        <br />
        <span>+</span> boisson
        <br />
        <span>+</span> dessert
      </>
    ),
    en: (
      <>
        1 Formula <span>6</span> ingredients
        <br />
        <span>+</span> drink
        <br />
        <span>+</span> dessert
      </>
    ),
    es: (
      <>
        1 Fórmula <span>6</span> ingredientes
        <br />
        <span>+</span> bebida
        <br />
        <span>+</span> postre
      </>
    ),
    nl: (
      <>
        1 Formule <span>6</span> ingrediënten
        <br />
        <span>+</span> drankje
        <br />
        <span>+</span> toetje
      </>
    ),
  },
  stripeRedirectIframeLoader: {
    fr: "Votre commande est en cours de préparation. Merci de ne pas rafraichir la page",
    en: "Your order is being prepared. Please do not refresh the page",
    es: "Su pedido está siendo preparado. Por favor, no actualices la página.",
    nl: "Uw bestelling wordt voorbereid. Gelieve de pagina niet te vernieuwen",
  },
  recipeIdeaDisclaimerText: {
    fr: "Cette recette est composée de :",
    en: "This recipe is composed of:",
    es: "Esta receta está compuesta de :",
    nl: "Dit recept bestaat uit :",
  },
  fideliteTitleText: {
    fr: "Comment ça fonctionne ?",
    en: "How does it work?",
    es: "Cómo funciona?",
    nl: "Hoe werkt het?",
  },

  fideliteBulletPoint1part2: {
    fr: "créez un compte fidélité en cliquant ici",
    en: "create a loyalty account by clicking here",
    es: "cree una cuenta de fidelidad haciendo clic aquí",
    nl: "maak een loyaliteitsaccount aan door hier te klikken",
  },
  fideliteBulletPoint1part1: {
    fr: "Connectez-vous à votre compte fidélité via votre profil",
    en: "Log in to your loyalty account via your profile",
    es: "Inicie sesión en su cuenta de fidelidad a través de su perfi",
    nl: "Log in op uw loyaliteitsaccount via uw profiel",
  },
  fideliteBulletPoint1part3: {
    fr: "ou ",
    en: "or ",
    es: "o ",
    nl: "of ",
  },

  fideliteBulletPoint2: {
    fr: <div>Ajoutez vos gratuité à votre panier</div>,
    en: <div>Add your freebies to your cart</div>,
    es: <div>Agregue sus regalos a su carrito</div>,
    nl: <div>Voeg uw gratis producten toe aan uw winkelwagen</div>,
  },
  fideliteBulletPoint3: {
    fr: (
      <div>
        Réservez vos avantages depuis votre compte <br />
        et présentez votre QR code via votre téléphone <br />
        en caisse
      </div>
    ),
    en: (
      <div>
        Book your benefits from your account <br />
        and present your QR code via your phone <br />
        at the checkout
      </div>
    ),
    es: (
      <div>
        Reserve sus beneficios desde su cuenta <br />
        y presente su código QR a través de su teléfono <br />
        en la caja
      </div>
    ),
    nl: (
      <div>
        Reserveer uw voordelen vanuit uw account <br />
        en toon uw QR-code via uw telefoon <br />
        bij de kassa
      </div>
    ),
  },

  fideliteNoteText: {
    fr: "*Le membre fidélité cumule au maximum 280 points",
    en: "*The loyalty member can accumulate up to 280 points",
    es: "*El miembro de fidelidad puede acumular hasta 280 puntos",
    nl: "*Het loyaliteitslid kan tot 280 punten verzamelen",
  },
  buttonContent: {
    fr: "Créer un compte ou se connecter >",
    en: "Create an account or log in >",
    es: "Crear una cuenta o iniciar sesión >",
    nl: "Maak een account aan of log in >",
  },
  fideliteLastText: {
    fr: (
      <div>
        Astuce: Vous pouvez stocker votre de fidélité dans la partie Wallet de
        <br /> votre Téléphone
      </div>
    ),
    en: (
      <div>
        Tip: You can store your loyalty card in the Wallet section of <br />
        your Phone
      </div>
    ),
    es: (
      <div>
        Consejo: Puede guardar su tarjeta de fidelidad en la sección Wallet de
        <br />
        su teléfono
      </div>
    ),
    nl: (
      <div>
        Tip: U kunt uw loyaliteitskaart opslaan in het Wallet-gedeelte van
        <br />
        uw telefoon
      </div>
    ),
  },
  dietSection1: {
    fr: "points",
    en: "points",
    es: "puntos",
    nl: "punten",
  },
  dietSection2: {
    fr: (
      <div>
        1 pain <br /> <span className={classes.span1}>ou</span> 1 sauce
      </div>
    ),

    en: (
      <div>
        1 bread <br />
        <span className={classes.span1}>or</span> 1 sauce
      </div>
    ),
    es: (
      <div>
        1 pan <br />
        <span className={classes.span1}>o</span> 1 salsa
      </div>
    ),
    nl: (
      <div>
        1 brood <br />
        <span className={classes.span1}>of</span> 1 saus
      </div>
    ),
  },
  dietSection3: {
    fr: (
      <div>
        1 boissson <br />
        <span className={classes.span2}>ou</span> 1 dessert
      </div>
    ),
    en: (
      <div>
        1 drink <br />
        <span className={classes.span2}>or</span> 1 dessert
      </div>
    ),
    es: (
      <div>
        1 bebida <br />
        <span className={classes.span2}>o</span> 1 postre
      </div>
    ),
    nl: (
      <div>
        1 drankje <br />
        <span className={classes.span2}>of</span> 1 dessert
      </div>
    ),
  },
  dietSectionFour: {
    fr: (
      <div>
        1 boisson chaude <br />
        <span className={classes.span3}>&</span> 1 dessert
      </div>
    ),
    en: (
      <div>
        1 hot drink <br />
        <span className={classes.span3}>&</span> 1 dessert
      </div>
    ),
    es: (
      <div>
        1 bebida caliente <br />
        <span className={classes.span3}>&</span> 1 postre
      </div>
    ),
    nl: (
      <div>
        1 warme drank <br />
        <span className={classes.span3}>&</span> 1 dessert
      </div>
    ),
  },
  dietSectionFive: {
    fr: (
      <div>
        1 formule <span className={classes.numberFour}>4</span>
        <br /> boisson <span className={classes.span4}>ou</span> dessert
      </div>
    ),
    en: (
      <div>
        1 drink <span className={classes.numberFour}>4</span>
        <br />
        <span className={classes.span4}>or</span> dessert formula
      </div>
    ),
    es: (
      <div>
        1 fórmula<span className={classes.numberFour}>4</span>
        <br />
        <span className={classes.span4}>de</span> bebida o postre
      </div>
    ),
    nl: (
      <div>
        1 drankje <span className={classes.numberFour}>4</span> <br />
        <span className={classes.span4}>of</span> dessert formule
      </div>
    ),
  },
  dietSectionSix: {
    fr: (
      <div>
        1 formule <span className={classes.number4}>4</span>
        <br /> boisson <span className={classes.span5}>&</span> dessert
      </div>
    ),
    en: (
      <div>
        1 drink <span className={classes.number4}>4</span> <br />
        <span className={classes.span5}>&</span> dessert formula
      </div>
    ),
    es: (
      <div>
        1 fórmula <span className={classes.number4}>4</span> <br />
        de bebida <span className={classes.span5}>&</span>y postre
      </div>
    ),
    nl: (
      <div>
        1 drankje <span className={classes.number4}>4</span> <br />
        <span className={classes.span5}>&</span>en dessert formule
      </div>
    ),
  },
  dietSectionSeven: {
    fr: (
      <div>
        1 formule <span className={classes.numbers}>6</span>
        <br /> boisson <span className={classes.span6}>&</span> dessert
      </div>
    ),
    en: (
      <div>
        1 drink <span className={classes.numbers}>6</span> <br /> dessert
        <span className={classes.span6}>&</span> formula
      </div>
    ),
    es: (
      <div>
        1 fórmula <span className={classes.numbers}>6</span> <br />
        de bebida<span className={classes.span6}>&</span> y postre
      </div>
    ),
    nl: (
      <div>
        1 drankje <span className={classes.numbers}>6</span> <br />
        <span className={classes.span6}>&</span> en dessert formule
      </div>
    ),
  },
  footerNote: {
    fr: (
      <div>
        Salades, plats chauds, sandwichs et <br /> desserts sur-mesure. Dégustez
        chaque
        <br />
        jour un menu savoureux et équilibré,
        <br /> composé par vos soins.
      </div>
    ),

    en: (
      <div>
        Salads, hot dishes, sandwiches and <br /> custom desserts. Enjoy every
        day a tasty and balanced menu, <br /> composed by you.
      </div>
    ),
    es: (
      <div>
        Ensaladas, platos calientes, sándwiches y <br /> postres personalizados.
        Disfrute todos los días de un menú sabroso y equilibrado, <br />
        compuesto por usted.
      </div>
    ),
    nl: (
      <div>
        Salades, warme gerechten, sandwiches en <br /> aangepaste desserts.
        Geniet elke dag van een smakelijk en uitgebalanceerd menu, <br />
        samengesteld door u.
      </div>
    ),
  },
  PointsAddition: {
    fr: "Boisson + Dessert",
    en: "Drink + Dessert",
    es: "Bebida + Postre",
    nl: "Drankje + Dessert",
  },
};
