import "../styles/globals.scss";
import "react-toastify/dist/ReactToastify.css";

import type { AppProps } from "next/app";
import { useRouter } from "next/router";
import Script from "next/script";
import { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";

import { getRestaurants } from "../api/restaurants/getRestaurants";
import { MapType } from "../api/types/map.type";
import { RestaurantItemType } from "../api/types/restaurant.type";
import { UserData } from "../api/types/user.type";
import { getUser } from "../api/users/getMe";
import { Locale } from "../api/utils/helpers/langRedirection";
import Footer from "../components/layout/Footer";
import RestaurantChangePopup from "../components/RestaurantChangePopup/index";
import Loader from "../components/ui/loader";
import { store } from "../redux/store";
import { LanguageContext } from "../store/lang.context";
import { LoaderContext } from "../store/loader.context";
import { MapContext, mapObject } from "../store/map.context";
import {
  popupIsOpenObject,
  PopupObject,
  RestaurantChangePopupContext,
} from "../store/restaurantChangePopup";
import { RestaurantsContext } from "../store/restaurants.context";
import {
  TakeawaySelectorContext,
  takeawaySelectorObject,
} from "../store/takeawaySelector.context";
import { UserContext } from "../store/user.context";

function MyApp({ Component, pageProps }: AppProps) {
  const [language, setLanguage] = useState<Locale>("fr");
  const [user, setUser] = useState<UserData>();
  const [map, setMap] = useState<MapType>(mapObject);
  const [restaurants, setRestaurants] = useState<RestaurantItemType[]>([]);
  const [loader, setLoader] = useState(false);
  const [takeawaySelector, setTakeawaySelector] = useState(
    takeawaySelectorObject
  );
  const [popupIsOpen, setPopupIsOpen] =
    useState<PopupObject>(popupIsOpenObject);

  const router = useRouter();

  const getUserData = async () => {
    const res = await getUser();
    if (res.data)
      setUser({ ...res.data, left_points: +(res.data.eatfid_points ?? 0) });
  };

  const handleRestaurantChangeCloseModal = () => {
    setPopupIsOpen({ isOpen: false, notAvailableIngredients: [] });
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      const localLanguage = localStorage.getItem("language") as Locale | null;
      setLanguage(localLanguage ?? "fr");
      localStorage.setItem("country", "France");
    }
    getUserData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getRestaurants(100, language).then(({ data }) => {
      const filteredRestaurants =
        data?.results.filter((item) => !item.closed && item.active) ?? [];
      setRestaurants(filteredRestaurants);
    });
  }, [language]);

  return (
    <>
      <Script id="gtag-GTMMF2SMGP">{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-MF2SMGP');`}</Script>
      <script
        async
        defer
        src="https://maps.googleapis.com/maps/api/js?key=AIzaSyDArNxVSBDVbq1t8E5QuI1sm2wJAhzT5nU&libraries=places"
      ></script>
      <Provider store={store}>
        <noscript>
          <iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-MF2SMGP"
            height="0"
            width="0"
            style={{ display: "none", visibility: "hidden" }}
          ></iframe>
        </noscript>
        <LanguageContext.Provider value={{ language, setLanguage }}>
          <MapContext.Provider value={{ map, setMap }}>
            <UserContext.Provider value={{ user, setUser }}>
              <RestaurantsContext.Provider
                value={{ restaurants, setRestaurants }}
              >
                <LoaderContext.Provider value={{ loader, setLoader }}>
                  <TakeawaySelectorContext.Provider
                    value={{ takeawaySelector, setTakeawaySelector }}
                  >
                    <RestaurantChangePopupContext.Provider
                      value={{ popupIsOpen, setPopupIsOpen }}
                    >
                      <Component {...pageProps} />

                      {!(
                        router.pathname.includes("/carte/") ||
                        router.pathname.includes("stripe-redirect-page-iframe")
                      ) && <Footer />}
                      {loader && <Loader />}
                      {popupIsOpen.isOpen && (
                        <RestaurantChangePopup
                          onClose={handleRestaurantChangeCloseModal}
                        />
                      )}
                      {/* <OutOfServicePopup /> */}
                      <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                      />
                    </RestaurantChangePopupContext.Provider>
                  </TakeawaySelectorContext.Provider>
                </LoaderContext.Provider>
              </RestaurantsContext.Provider>
            </UserContext.Provider>
          </MapContext.Provider>
        </LanguageContext.Provider>
      </Provider>
    </>
  );
}

export default MyApp;
