import { useState } from "react";
import Select, {
  ActionMeta,
  PropsValue,
  SingleValue,
  StylesConfig,
} from "react-select";

export interface OptionProps {
  value: string | number;
  label?: string | number;
  selected?: boolean;
  disabled?: boolean;
  hidden?: boolean;
}

interface Props {
  customStyles?: () => StylesConfig<OptionProps>;
  name: string;
  value?: PropsValue<OptionProps>;
  label?: string;
  defaultValue: PropsValue<OptionProps>;
  error?: boolean;
  isValid?: boolean;
  placeholder?: string;
  notRequired?: boolean;
  onChange: (
    newValue: SingleValue<OptionProps>,
    actionMeta: ActionMeta<OptionProps>
  ) => void;
  options: OptionProps[];
}

const SimpleDropdown = ({
  options,
  name,
  value,
  error,
  isValid,
  label,
  onChange,
  defaultValue,
  placeholder,
  notRequired,
  customStyles,
}: Props) => {
  const [open, setOpen] = useState(false);

  const styles = (): StylesConfig<OptionProps> => ({
    control: (styles) => ({
      ...styles,
      cursor: "pointer",
      backgroundColor: `${
        name.includes("User")
          ? "var(--alternate-background-color)"
          : "var(--primary-background-color)"
      }`,
      borderRadius: "25px",
      color: "black",
      border: `${
        !notRequired
          ? !isValid && error
            ? "1px solid red"
            : !isValid
            ? "1px solid red"
            : "1px solid black !important"
          : "1px solid black !important"
      }`,
      alignContent: "center",
      textAlign: `${name.includes("User") ? "center" : "start"}`,
      minHeight: `${name.includes("birthday") ? "48px" : "42px"}`,
      "&:after": {
        display: "none",
      },
      "&:focus": {
        border: `${
          !notRequired
            ? !isValid && error
              ? "1px solid red"
              : !isValid
              ? "1px solid red"
              : "none"
            : "none"
        }`,
      },
      "&:hover": {
        border: `${
          !notRequired
            ? !isValid && error
              ? "1px solid red"
              : !isValid
              ? "1px solid red"
              : "1px solid var(--alternate-background-color)"
            : "1px solid var(--alternate-background-color)"
        }`,
      },
    }),
    valueContainer: (styles) => ({
      ...styles,
      height: "100%",
    }),
    placeholder: (styles) => ({
      ...styles,
      color: `${name.includes("User") ? "var(--primary-text-color)" : "black"}`,
      padding: `${name.includes("birthday") ? "7px 0 0 10px" : ""}`,
      height: "100%",
    }),
    singleValue: (styles) => ({
      ...styles,
      color: "var(--primary-text-color)",
    }),
    dropdownIndicator: () => ({
      color: `${
        name.includes("User") ? "var(--primary-text-color)" : "transparent"
      }`,
      height: "0 !important",
      "&:after": {
        // eslint-disable-next-line quotes
        content: '""',
        overflow: "none",
        position: "absolute",
        top: "45%",
        right: "25px",
        width: 0,
        border: "6px solid transparent",
        borderColor: `${
          name.includes("User")
            ? "var(--alternate-background-color)"
            : "var(--alternate-background-color) transparent transparent transparent"
        }`,
      },
    }),
    indicatorSeparator: () => ({ display: "none" }),
    menu: (styles) => ({
      ...styles,
      position: "absolute",
      backgroundColor: "",
      margin: "2px 0",
      padding: "0 !important",
      maxWidth: "250px",
      transition: "all 200ms ease-in-out",
      overflow: "none",
    }),
    menuList: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      transition: "all 200ms ease-in-out",
      overflow: "none",
    }),
    option: (styles, state) => ({
      ...styles,
      height: "47px",
      backgroundColor: `${
        state.isSelected
          ? "var(--alternate-background-color)"
          : "var(--primary-background-color)"
      }`,
      textAlign: "start",
      color: `${
        state.isSelected
          ? "var(--secondary-text-color)"
          : "var(--primary-text-color)"
      }`,
      borderBottom: "1px solid var(--alternate-background-color)",
      "&:hover": {
        backgroundColor: "var(--alternate-background-color)",
        color: "var(--secondary-text-color)",
        cursor: "pointer",
      },
    }),
  });

  return (
    <>
      {label ? <p>{label}</p> : <br />}
      <div onClick={() => setOpen(!open)}>
        <Select<OptionProps>
          defaultValue={defaultValue}
          onChange={onChange}
          options={options}
          isSearchable={false}
          onBlur={() => setOpen(false)}
          styles={customStyles ? customStyles() : styles()}
          value={value}
          name={name}
          placeholder={placeholder}
        />
      </div>
    </>
  );
};

export default SimpleDropdown;
