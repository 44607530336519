import Link from "next/link";
import { useRouter } from "next/router";

import { translationsContent } from "../../../../../localization/translations";
import { useLanguage } from "../../../../../store/lang.context";
import { translate } from "../../../../../utils/translate";
import { ColumnItemsType1 } from "..";
import classes from "./item.module.scss";

interface Props {
  title: string;
  items: string[];
  id?: boolean;
}

const ColumnItem = ({ title, items, id }: Props) => {
  const router = useRouter();
  const { language } = useLanguage();
  const getRoute = (item: string) => {
    let route = router.pathname;
    switch (item) {
      case "restaurants":
        switch (language) {
          case "fr":
            route = "/nos-restaurants/";
            break;
          case "en":
            route = "/en/our-locations/";
            break;
          case "es":
            route = "/es/nuestros-restaurantes/";
            break;
          case "nl":
            route = "/nl/onze-restaurants/";
            break;
        }
        break;
      case "contact":
        if (language === "es") route = "/es/contacto/";
        else if (language === "fr") route = "/contact/";
        else route = "/" + language + "/contact/";
        break;
    }
    return route;
  };

  const getId = (item: ColumnItemsType1) => {
    switch (item) {
      case "Nous contacter":
        return "contact";
      case "Service client":
        return "contact";
      case "Nos restaurants":
        return "restaurants";
      case "Recrutement":
        return "";
    }
  };

  const getText = (item: ColumnItemsType1) => {
    switch (item) {
      case "Nous contacter":
        return translate(translationsContent, "contactHeader", language);
      case "Service client":
        return translate(translationsContent, "contactHeader3", language);
      case "Nos restaurants":
        return translate(translationsContent, "restaurantsHeader", language);
      case "Recrutement":
        return translate(translationsContent, "recruitementHeader", language);
    }
  };
  return (
    <div className={classes.container}>
      <div className={classes.column}>
        <h4>{title}</h4>
        <ul>
          {items.map((item: string) => {
            return (
              <li key={item}>
                {item === "Recrutement" ? (
                  <a
                    href="https://recrutement.eatsalad.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span>{getText(item as ColumnItemsType1)}</span>
                  </a>
                ) : (
                  <Link
                    href={getRoute(getId(item as ColumnItemsType1))}
                    passHref
                  >
                    <a>{getText(item as ColumnItemsType1)}</a>
                  </Link>
                )}
              </li>
            );
          })}
          {id && (
            <li>
              <a
                href="/pdfs/allergenesajour.pdf"
                target="_blank"
                rel="noreferrer"
              >
                <span>
                  {translate(translationsContent, "allergyHeader2", language)}
                </span>
              </a>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default ColumnItem;
