import axios from "axios";
import { getCookie, hasCookie, removeCookies, setCookies } from "cookies-next";

import { AuthResponse } from "../../types/auth.type";

export function setToken() {
  if (hasCookie("accessToken")) {
    const accessToken = getCookie("accessToken") as string;
    axios.defaults.headers.common.Authorization = "Bearer " + accessToken;
  } else {
    axios.defaults.headers.common.Authorization = false;
  }
}

export function storeAuthResult(response: AuthResponse) {
  setCookies("accessToken", response.access);
  setCookies("refreshToken", response.refresh);
  setToken();
}

export function storeSocialAuthResult(token: string) {
  setCookies("accessToken", token);
  setToken();
}

export function removeAuth() {
  removeCookies("accessToken");
  removeCookies("refreshToken");
  setToken();
}
