import Link from "next/link";
import { useEffect, useState } from "react";

import { translationsContent } from "../../../../localization/translations";
import { useLanguage } from "../../../../store/lang.context";
import { translate } from "../../../../utils/translate";
import { TranslationHeader } from "../../../layout/Footer/Legals";

interface Props {
  href: string;
  header: string;
}

const LegalLink = ({ href, header }: Props) => {
  const { language } = useLanguage();
  const [route, setRoute] = useState<string>("");

  useEffect(() => {
    setRoute(href);
  }, [href]);

  return (
    <Link href={route} passHref>
      <a target={header === "footerPrivacyPolicyHeader" ? "_blank" : undefined}>
        {translate(translationsContent, header as TranslationHeader, language)}
      </a>
    </Link>
  );
};

export default LegalLink;
