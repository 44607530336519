import Image from "next/image";

import imageLoader from "../../../../utils/loader";
import classes from "./modal.module.scss";

interface Props {
  onClose: () => void;
  map?: boolean;
  noPadding?: boolean;
  transparentOnMobile?: boolean;
  hideCancel?: boolean;
  contentScroll?: boolean;
}

const DynamicModal = ({
  children,
  onClose,
  map,
  noPadding = false,
  transparentOnMobile = false,
  hideCancel = false,
  contentScroll = false,
}: React.PropsWithChildren<Props>) => {
  const handleCloseModal = () => {
    onClose();
  };

  return (
    <div className={classes.darkBG}>
      <div
        className={`${classes.modal} ${
          transparentOnMobile && classes.transparentOnMobile
        }`}
      >
        <div
          className={`${classes.content} ${
            map || noPadding ? classes.noPadding : ""
          } ${contentScroll ? classes.contentScroll : ""}`}
        >
          {!hideCancel && (
            <div className={classes.cancel}>
              <Image
                layout="fixed"
                loader={imageLoader}
                width={"15px"}
                height={"15px"}
                src={"cancel.svg"}
                alt="cancel"
                onClick={handleCloseModal}
              />
            </div>
          )}
          {children}
        </div>
      </div>
    </div>
  );
};

export default DynamicModal;
