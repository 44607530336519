import { AxiosResponse } from "axios";

import { sendRequest } from "../request/axios";
import { AxiosHttpError } from "../types/error.type";
import { UserData } from "../types/user.type";

export async function getUser() {
  try {
    const res = await sendRequest<never, AxiosResponse<UserData>>(
      "get",
      "/me/"
    );
    return { data: res.data, success: true };
  } catch (error) {
    console.log(error);
    const axiosError = error as AxiosHttpError;
    return {
      success: false,
      error: axiosError.response?.data,
    };
  }
}
