import Image from "next/image";

import imageLoader from "../../../utils/loader";
import classes from "./loader.module.scss";

const Loader = () => {
  return (
    <div className={classes.darkBG}>
      <div className={classes.modal}>
        <div className={classes.content}>
          <div className={classes.loader}>
            <Image
              loader={imageLoader}
              src="loader.png"
              alt="Loader"
              height={140}
              width={140}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
