import { MouseEventHandler } from "react";
import { RiLoader4Fill } from "react-icons/ri";

import classes from "./button.module.scss";

interface Props {
  label: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  disabledOutline?: boolean;
  loading?: boolean;
  className?: string;
}

const Button = ({
  label,
  onClick,
  disabled,
  loading,
  disabledOutline = false,
  className,
}: Props) => {
  return (
    <button
      className={`${classes.button} ${
        disabledOutline ? classes.disabledOutline : ""
      } ${className}`}
      onClick={!loading ? onClick : () => {}}
      disabled={!!(loading || disabled)}
    >
      {loading && <RiLoader4Fill className={classes.loader} />}
      {!loading && label}
    </button>
  );
};

export default Button;
