// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;
const isProduction = process.env.NEXT_PUBLIC_NODE_ENV === "production";

if (isProduction)
  Sentry.init({
    environment: process.env.NEXT_PUBLIC_NODE_ENV,
    dsn: SENTRY_DSN,
    tracesSampleRate: 0,
    beforeSend(event) {
      if (
        event.culprit &&
        (event.culprit.containes("?(maps/api/js)") ||
          event.culprit.containes("?(chunks"))
      ) {
        return null;
      }
      return event;
    },
  });
