import { useRouter } from "next/router";
import React from "react";
import { ActionMeta, SingleValue } from "react-select";

import {
  Locale,
  redirectToEn,
  redirectToEs,
  redirectToFr,
  redirectToNl,
} from "../../api/utils/helpers/langRedirection";
import { translationsContent } from "../../localization/translations";
import { useLanguage } from "../../store/lang.context";
import { translate } from "../../utils/translate";
import Button from "../ui/button/SimpleButton";
import SimpleDropdown, { OptionProps } from "../ui/dropdown/SimpleDropdown";
import DynamicModal from "../ui/modal/DynamicModal";
import classes from "./languageSelect.module.scss";

interface Props {
  setModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const LanguageSelect = ({ setModalIsOpen }: Props) => {
  const { language, setLanguage } = useLanguage();
  const handleDropdownInputChange = (
    newValue: SingleValue<OptionProps>,
    actionMeta: ActionMeta<OptionProps>
  ) => {
    const value = newValue?.value + "";
    const identifier = actionMeta.name;
    switch (identifier) {
      case "language":
        localStorage.setItem("language", value);
        break;
      case "country":
        localStorage.setItem("country", value);
        break;
    }
  };
  const languageOptions: OptionProps[] = [
    {
      value: "fr",
      label: translate(translationsContent, "footerLangFrench", language),
    },
    {
      value: "en",
      label: translate(translationsContent, "footerLangEnglish", language),
    },
    {
      value: "es",
      label: translate(translationsContent, "footerLangSpanish", language),
    },
    {
      value: "nl",
      label: translate(translationsContent, "footerLangDutch", language),
    },
  ];
  const countryOptions: OptionProps[] = [
    {
      value: "France",
      label: translate(translationsContent, "footerCountryFrance", language),
    },
    // {
    //   value: "Belgique",
    //   label: translate(translationsContent, "footerCountryBelgium", language),
    // },
  ];
  const handleLanguagePlaceholder = () => {
    let placeholder = "";
    languageOptions.forEach((lang) => {
      if (lang.value === localStorage.getItem("language")) {
        placeholder = lang.label as string;
      }
    });
    return placeholder;
  };
  const handleCountryPlaceholder = () => {
    let placeholder = "";
    if (localStorage.getItem("country"))
      placeholder = localStorage.getItem("country") as string;
    return placeholder;
  };
  const router = useRouter();

  const getCurrentRoute = () => {
    let currentRoute = router.route;
    if (router.query.restaurant || router.query.restaurantId) {
      currentRoute = `${router.route.substr(0, router.route.indexOf("["))}${
        router.query.restaurant
      }/${router.query.restaurantId}`;
    }
    if (router.query.orderId) {
      currentRoute = `${router.route.substr(0, router.route.indexOf("["))}${
        router.query.orderId
      }`;
    }
    if (router.query.slug) {
      currentRoute = `${router.route.substr(0, router.route.indexOf("["))}${
        router.query.slug
      }`;
    }
    return currentRoute;
  };

  const handleSwitchToFR = () => {
    const currentRoute = getCurrentRoute();
    if (
      !currentRoute.includes("carte") &&
      !currentRoute.includes("payment") &&
      !currentRoute.includes("confirmation") &&
      !currentRoute.includes("nos-ingredients") &&
      !currentRoute.includes("notre-restaurant")
    ) {
      const newRoute = redirectToFr(currentRoute);
      router.push(newRoute);
    }
  };

  const handleSwitchToEN = () => {
    const currentRoute = getCurrentRoute();
    if (
      !currentRoute.includes("carte") &&
      !currentRoute.includes("payment") &&
      !currentRoute.includes("confirmation") &&
      !currentRoute.includes("nos-ingredients") &&
      !currentRoute.includes("notre-restaurant")
    ) {
      const newRoute = redirectToEn(currentRoute);
      router.push(newRoute);
    }
  };
  const handleSwitchToES = () => {
    const currentRoute = getCurrentRoute();
    if (
      !currentRoute.includes("carte") &&
      !currentRoute.includes("payment") &&
      !currentRoute.includes("confirmation") &&
      !currentRoute.includes("nos-ingredients") &&
      !currentRoute.includes("notre-restaurant")
    ) {
      const newRoute = redirectToEs(currentRoute);
      router.push(newRoute);
    }
  };
  const handleSwitchToNL = () => {
    const currentRoute = getCurrentRoute();
    if (
      !currentRoute.includes("carte") &&
      !currentRoute.includes("payment") &&
      !currentRoute.includes("confirmation") &&
      !currentRoute.includes("nos-ingredients") &&
      !currentRoute.includes("notre-restaurant")
    ) {
      const newRoute = redirectToNl(currentRoute);
      router.push(newRoute);
    }
  };

  const handleOnClick = () => {
    if (localStorage.getItem("language") !== language) {
      setLanguage(localStorage.getItem("language") as Locale);
      switch (localStorage.getItem("language")) {
        case "fr":
          handleSwitchToFR();
          break;
        case "en":
          handleSwitchToEN();
          break;
        case "es":
          handleSwitchToES();
          break;
        case "nl":
          handleSwitchToNL();
          break;
      }
    }
    setModalIsOpen(false);
  };

  const handleOnClose = () => {
    setModalIsOpen(false);
  };

  return (
    <div className={classes.container}>
      <DynamicModal onClose={handleOnClose}>
        <div className={classes.innerContainer}>
          <h2>
            {translate(translationsContent, "langSelectHeader", language)}
          </h2>
          <SimpleDropdown
            label={`${translate(
              translationsContent,
              "langSelectLanguage",
              language
            )}`}
            options={languageOptions}
            defaultValue={null}
            name="language"
            placeholder={handleLanguagePlaceholder()}
            notRequired={true}
            onChange={handleDropdownInputChange}
          />
          <SimpleDropdown
            label={`${translate(
              translationsContent,
              "langSelectCountry",
              language
            )}`}
            options={countryOptions}
            defaultValue={null}
            name="country"
            placeholder={handleCountryPlaceholder()}
            notRequired={true}
            onChange={handleDropdownInputChange}
          />
        </div>
        <Button
          label={`${translate(translationsContent, "validerButton", language)}`}
          onClick={handleOnClick}
        />
      </DynamicModal>
    </div>
  );
};

export default LanguageSelect;
