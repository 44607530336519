import { createContext, useContext } from "react";

import { UserData } from "../api/types/user.type";

export type UserContextType = {
  user?: UserData;
  setUser: React.Dispatch<React.SetStateAction<UserData | undefined>>;
};

export const UserContext = createContext<UserContextType>({
  user: undefined,
  setUser: () => {},
});

export const useUser = () => useContext(UserContext);
