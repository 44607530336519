import { createContext, useContext } from "react";

import { Locale } from "../api/utils/helpers/langRedirection";

if (typeof window !== "undefined") {
  if (localStorage.getItem("language") === null) {
    localStorage.setItem("language", "fr");
  }
}

export type LanguageContextType = {
  language: Locale;
  setLanguage: React.Dispatch<React.SetStateAction<Locale>>;
};

export const LanguageContext = createContext<LanguageContextType>({
  language: "fr",
  setLanguage: () => {},
});

export const useLanguage = () => useContext(LanguageContext);
