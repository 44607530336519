export type Locale = "fr" | "en" | "es" | "nl";

export const redirectToEn = (url: string) => {
  let route = "/en";
  switch (url) {
    case "/":
      route += "/";
      break;
    case "/mentions-legales":
      route += "/legal-notice/";
      break;
    case "/es":
      route += "/";
      break;
    case "/es/aviso-legal":
      route += "/legal-notice/";
      break;
    case "/nl":
      route += "/";
      break;
    case "/nl/wettelijke-bepalingen":
      route += "/legal-notice/";
      break;
    case "/nos-restaurants":
      route += "/our-locations/";
      break;
    case "/es/nuestros-restaurantes":
      route += "/our-locations/";
      break;
    case "/nl/onze-restaurants":
      route += "/our-locations/";
      break;
    case "/contact":
      route += "/contact/";
      break;
    case "/es/contacto":
      route += "/contact/";
      break;
    case "/nl/contact":
      route += "/contact/";
      break;
    case "/la-fidelite":
      route += "/the-fidelity/";
      break;
    case "/es/la-fidelite":
      route += "/the-fidelity/";
      break;
    case "/nl/de-fidelite":
      route += "/the-fidelity/";
      break;
    case "/panier":
      route += "/cart/";
      break;
    case "/es/cesta":
      route += "/cart/";
      break;
    case "/nl/winkelmandje":
      route += "/cart/";
      break;
    case "/conditions-generales-vente":
      route += "/terms-and-conditions/";
      break;
    case "/es/condiciones-generales-venta":
      route += "/terms-and-conditions/";
      break;
    case "/nl/algemene-verkoopvoorwaarden":
      route += "/terms-and-conditions/";
      break;
    case "/reglement-la-fidelite":
      route += "/regulation-the-fidelity/";
      break;
    case "/es/reglamento-el-fidelite":
      route += "/regulation-the-fidelity/";
      break;
    case "/nl/reglement-de-fidelite":
      route += "/regulation-the-fidelity/";
      break;
    case "/donnees-personnelles":
      route += "/personal-information/";
      break;
    case "/es/datos-personales":
      route += "/personal-information/";
      break;
    case "/nl/persoonsgegevens":
      route += "/personal-information/";
      break;
    case "/mon-compte/informations-personnelles":
      route += "/my-account/personal-information";
      break;
    case "/es/mi-cuenta/datos-personales":
      route += "/my-account/personal-information";
      break;
    case "/nl/mijn-account/persoonlijke-gegevens":
      route += "/my-account/personal-information";
      break;
    case "/mon-compte/historique-commandes":
      route += "/my-account/order-history";
      break;
    case "/es/mi-cuenta/historial-pedidos":
      route += "/my-account/order-history";
      break;
    case "/nl/mijn-account/bestelgeschiedenis":
      route += "/my-account/order-history";
      break;
    case "/mon-compte/cartes":
      route += "/my-account/credit-cards";
      break;
    case "/es/mi-cuenta/tarjetas":
      route += "/my-account/credit-cards";
      break;
    case "/nl/mijn-account/kaarten":
      route += "/my-account/credit-cards";
      break;
    case "/mon-compte/restaurant-favori":
      route += "/my-account/favorite-location";
      break;
    case "/es/mi-cuenta/restaurante-preferido":
      route += "/my-account/favorite-location";
      break;
    case "/nl/mijn-account/favoriete-restaurant":
      route += "/my-account/favorite-location";
      break;
    case "/mon-compte/la-fidelite":
      route += "/my-account/the-fidelity";
      break;
    case "/es/mi-cuenta/el-fidelite":
      route += "/my-account/the-fidelity";
      break;
    case "/nl/mijn-account/de-fidelite":
      route += "/my-account/the-fidelity";
      break;
    default:
      route += url;
      break;
  }
  return route;
};

export const redirectToFr = (url: string) => {
  let route = "/";
  switch (url) {
    case "/en":
      route += "/";
      break;
    case "/en/legal-notice":
      route += "mentions-legales/";
      break;
    case "/es":
      route += "/";
      break;
    case "/es/aviso-legal":
      route += "mentions-legales/";
      break;
    case "/nl":
      route += "/";
      break;
    case "/nl/wettelijke-bepalingen":
      route += "mentions-legales/";
      break;
    case "/en/our-locations":
      route += "nos-restaurants/";
      break;
    case "/es/nuestros-restaurantes":
      route += "nos-restaurants/";
      break;
    case "/nl/onze-restaurants":
      route += "nos-restaurants/";
      break;
    case "/en/contact":
      route += "contact/";
      break;
    case "/es/contacto":
      route += "contact/";
      break;
    case "/nl/contact":
      route += "contact/";
      break;
    case "/en/the-fidelity":
      route += "la-fidelite/";
      break;
    case "/es/la-fidelite":
      route += "la-fidelite/";
      break;
    case "/nl/de-fidelite":
      route += "la-fidelite/";
      break;
    case "/en/cart":
      route += "panier/";
      break;
    case "/es/cesta":
      route += "panier/";
      break;
    case "/nl/winkelmandje":
      route += "panier/";
      break;
    case "/en/terms-and-conditions":
      route += "conditions-generales-vente/";
      break;
    case "/es/condiciones-generales-venta":
      route += "conditions-generales-vente/";
      break;
    case "/nl/algemene-verkoopvoorwaarden":
      route += "conditions-generales-vente/";
      break;
    case "/en/regulation-the-fidelity":
      route += "reglement-la-fidelite/";
      break;
    case "/es/reglamento-el-fidelite":
      route += "reglement-la-fidelite/";
      break;
    case "/nl/reglement-de-fidelite":
      route += "reglement-la-fidelite/";
      break;
    case "/en/personal-information":
      route += "donnees-personnelles/";
      break;
    case "/es/datos-personales":
      route += "donnees-personnelles/";
      break;
    case "/nl/persoonsgegevens":
      route += "donnees-personnelles/";
      break;
    case "/en/my-account/personal-information":
      route += "mon-compte/informations-personnelles";
      break;
    case "/es/mi-cuenta/datos-personales":
      route += "mon-compte/informations-personnelles";
      break;
    case "/nl/mijn-account/persoonlijke-gegevens":
      route += "mon-compte/informations-personnelles";
      break;
    case "/en/my-account/order-history":
      route += "mon-compte/historique-commandes";
      break;
    case "/es/mi-cuenta/historial-pedidos":
      route += "mon-compte/historique-commandes";
      break;
    case "/nl/mijn-account/bestelgeschiedenis":
      route += "mon-compte/historique-commandes";
      break;
    case "/en/my-account/credit-cards":
      route += "mon-compte/cartes";
      break;
    case "/es/mi-cuenta/tarjetas":
      route += "mon-compte/cartes";
      break;
    case "/nl/mijn-account/kaarten":
      route += "mon-compte/cartes";
      break;
    case "/en/my-account/favorite-location":
      route += "mon-compte/restaurant-favori";
      break;
    case "/es/mi-cuenta/restaurante-preferido":
      route += "mon-compte/restaurant-favori";
      break;
    case "/nl/mijn-account/favoriete-restaurant":
      route += "mon-compte/restaurant-favori";
      break;
    case "/en/my-account/the-fidelite":
      route += "mon-compte/la-fidelite";
      break;
    case "/es/mi-cuenta/el-fidelite":
      route += "mon-compte/la-fidelite";
      break;
    case "/nl/mijn-account/de-fidelite":
      route += "mon-compte/la-fidelite";
      break;
    default:
      route += url.substring(4);
      break;
  }
  return route;
};

export const redirectToEs = (url: string) => {
  let route = "/es";
  switch (url) {
    case "/":
      route += "/";
      break;
    case "/mentions-legales":
      route += "/aviso-legal/";
      break;
    case "/en":
      route += "/";
      break;
    case "/en/legal-notice":
      route += "/aviso-legal/";
      break;
    case "/nl":
      route += "/";
      break;
    case "/nl/wettelijke-bepalingen":
      route += "/aviso-legal/";
      break;
    case "/nos-restaurants":
      route += "/nuestros-restaurantes/";
      break;
    case "/en/our-locations":
      route += "/nuestros-restaurantes/";
      break;
    case "/nl/onze-restaurants":
      route += "/nuestros-restaurantes/";
      break;
    case "/contact":
      route += "/contacto/";
      break;
    case "/en/contact":
      route += "/contacto/";
      break;
    case "/nl/contact":
      route += "/contacto/";
      break;
    case "/la-fidelite":
      route += "/la-fidelite/";
      break;
    case "/en/the-fidelity":
      route += "/la-fidelite/";
      break;
    case "/nl/de-fidelite":
      route += "/la-fidelite/";
      break;
    case "/panier":
      route += "/cesta/";
      break;
    case "/en/cart":
      route += "/cesta/";
      break;
    case "/nl/winkelmandje":
      route += "/cesta/";
      break;
    case "/conditions-generales-vente":
      route += "/condiciones-generales-venta/";
      break;
    case "/en/terms-and-conditions":
      route += "/condiciones-generales-venta/";
      break;
    case "/nl/algemene-verkoopvoorwaarden":
      route += "/condiciones-generales-venta/";
      break;
    case "/reglement-la-fidelite":
      route += "/reglamento-el-fidelite/";
      break;
    case "/en/regulation-the-fidelity":
      route += "/reglamento-el-fidelite/";
      break;
    case "/nl/reglement-de-fidelite":
      route += "/reglamento-el-fidelite/";
      break;
    case "/donnees-personnelles":
      route += "/datos-personales/";
      break;
    case "/en/personal-information":
      route += "/datos-personales/";
      break;
    case "/nl/persoonsgegevens":
      route += "/datos-personales/";
      break;
    case "/en/my-account/personal-information":
      route += "/mi-cuenta/datos-personales";
      break;
    case "/mon-compte/informations-personnelles":
      route += "/mi-cuenta/datos-personales";
      break;
    case "/nl/mijn-account/persoonlijke-gegevens":
      route += "/mi-cuenta/datos-personales";
      break;
    case "/en/my-account/order-history":
      route += "/mi-cuenta/historial-pedidos";
      break;
    case "/mon-compte/historique-commandes":
      route += "/mi-cuenta/historial-pedidos";
      break;
    case "/nl/mijn-account/bestelgeschiedenis":
      route += "/mi-cuenta/historial-pedidos";
      break;
    case "/en/my-account/credit-cards":
      route += "/mi-cuenta/tarjetas";
      break;
    case "/mon-compte/cartes":
      route += "/mi-cuenta/tarjetas";
      break;
    case "/nl/mijn-account/kaarten":
      route += "/mi-cuenta/tarjetas";
      break;
    case "/en/my-account/favorite-location":
      route += "/mi-cuenta/restaurante-preferido";
      break;
    case "/mon-compte/restaurant-favori":
      route += "/mi-cuenta/restaurante-preferido";
      break;
    case "/nl/mijn-account/favoriete-restaurant":
      route += "/mi-cuenta/restaurante-preferido";
      break;
    case "/en/my-account/the-fidelity":
      route += "/mi-cuenta/el-fidelite";
      break;
    case "/mon-compte/la-fidelite":
      route += "/mi-cuenta/el-fidelite";
      break;
    case "/nl/mijn-account/de-fidelite":
      route += "/mi-cuenta/el-fidelite";
      break;
    default:
      route += url;
      break;
  }
  return route;
};

export const redirectToNl = (url: string) => {
  let route = "/nl";
  switch (url) {
    case "/":
      route += "/";
      break;
    case "/mentions-legales":
      route += "/wettelijke-bepalingen/";
      break;
    case "/en":
      route += "/";
      break;
    case "/en/legal-notice":
      route += "/wettelijke-bepalingen/";
      break;
    case "/es":
      route += "/";
      break;
    case "/es/aviso-legal":
      route += "/wettelijke-bepalingen/";
      break;
    case "/nos-restaurants":
      route += "/onze-restaurants/";
      break;
    case "/es/nuestros-restaurantes":
      route += "/onze-restaurants/";
      break;
    case "/en/our-locations":
      route += "/onze-restaurants/";
      break;
    case "/contact":
      route += "/contact/";
      break;
    case "/es/contacto":
      route += "/contact/";
      break;
    case "/en/contact":
      route += "/contact/";
      break;
    case "/la-fidelite":
      route += "/de-fidelite/";
      break;
    case "/es/la-fidelite":
      route += "/de-fidelite/";
      break;
    case "/en/the-fidelity":
      route += "/de-fidelite/";
      break;
    case "/panier":
      route += "/winkelmandje/";
      break;
    case "/es/cesta":
      route += "/winkelmandje/";
      break;
    case "/en/cart":
      route += "/winkelmandje/";
      break;
    case "/conditions-generales-vente":
      route += "/algemene-verkoopvoorwaarden/";
      break;
    case "/es/condiciones-generales-venta":
      route += "/algemene-verkoopvoorwaarden/";
      break;
    case "/en/terms-and-conditions":
      route += "/algemene-verkoopvoorwaarden/";
      break;
    case "/reglement-la-fidelite":
      route += "/reglement-de-fidelite/";
      break;
    case "/es/reglamento-el-fidelite":
      route += "/reglement-de-fidelite/";
      break;
    case "/en/regulation-the-fidelity":
      route += "/reglement-de-fidelite/";
      break;
    case "/donnees-personnelles":
      route += "/persoonsgegevens/";
      break;
    case "/es/datos-personales":
      route += "/persoonsgegevens/";
      break;
    case "/en/personal-information":
      route += "/persoonsgegevens/";
      break;
    case "/en/my-account/personal-information":
      route += "/mijn-account/persoonlijke-gegevens";
      break;
    case "/mon-compte/informations-personnelles":
      route += "/mijn-account/persoonlijke-gegevens";
      break;
    case "/es/mi-cuenta/datos-personales":
      route += "/mijn-account/persoonlijke-gegevens";
      break;
    case "/en/my-account/order-history":
      route += "/mijn-account/bestelgeschiedenis";
      break;
    case "/mon-compte/historique-commandes":
      route += "/mijn-account/bestelgeschiedenis";
      break;
    case "/es/mi-cuenta/historial-pedidos":
      route += "/mijn-account/bestelgeschiedenis";
      break;
    case "/en/my-account/credit-cards":
      route += "/mijn-account/kaarten";
      break;
    case "/mon-compte/cartes":
      route += "/mijn-account/kaarten";
      break;
    case "/es/mi-cuenta/tarjetas":
      route += "/mijn-account/kaarten";
      break;
    case "/en/my-account/favorite-location":
      route += "/mijn-account/favoriete-restaurant";
      break;
    case "/mon-compte/restaurant-favori":
      route += "/mijn-account/favoriete-restaurant";
      break;
    case "/es/mi-cuenta/restaurante-preferido":
      route += "/mijn-account/favoriete-restaurant";
      break;
    case "/en/my-account/the-fidelity":
      route += "/mijn-account/de-fidelite";
      break;
    case "/mon-compte/la-fidelite":
      route += "/mijn-account/de-fidelite";
      break;
    case "/es/mi-cuenta/el-fidelite":
      route += "/mijn-account/de-fidelite";
      break;
    default:
      route += url;
      break;
  }
  return route;
};
