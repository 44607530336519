import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  Dessert,
  Formula,
  Ingredient,
  SignatureSalad,
} from "../../api/types/restaurant.type";
import {
  cartContainsItem,
  cartContainsSignature,
  itemsAreBothEatFidOrNot,
} from "../../api/utils/helpers/cartContainsItem";
import type { RootState } from "../store";

export interface PromoCodePayload {
  discount: number;
  promoCode: string;
}

interface SuggestedCount {
  id: number;
  count: number;
}

export interface Item {
  ingredient: Ingredient;
  quantity: number;
  itemTotal: number;
  is_warm?: boolean;
  points?: number;
  is_paid_with_eatfid_points?: boolean;
  is_suggestion?: boolean;
}

export interface WarmItem {
  ingredient: Ingredient;
  is_warm?: boolean;
  is_suggestion?: boolean;
}

export interface SignatureSaladItem {
  ingredient: Ingredient;
  freeSauce?: Ingredient;
  quantity: number;
  itemTotal: number;
}

interface BaseFormulaItem {
  orderId: number;
  bases: Item[];
  caloriesTotal: number;
  supplementsTotal: number;
  formulaTotal: number;
  extraBases: Item[];
  quantity: number;
  completed: boolean;
  is_paid_with_eatfid_points?: boolean;
}

export interface FormulaItem extends BaseFormulaItem {
  formula: Formula;
  ingredients: Item[];
  extraIngredients: Item[];
  sauces: Item[];
  extraSauces: Item[];
  sides1: Item[];
  extraSides1: Item[];
  sides2: Item[];
  extraSides2: Item[];
  desserts: DessertItem[];
  recipeTitle?: string;
  notModifiable?: boolean;
}

export interface DessertItem {
  orderId: number;
  coulis: Item[];
  extraCoulis: Item[];
  toppings: Item[];
  extraToppings: Item[];
  dessert: Dessert;
  supplementsTotal: number;
  formulaTotal: number;
  quantity: number;
  completed: boolean;
}

export interface CustomFormulaItem {
  orderId: number;
  bases: Item[];
  ingredients: Item[];
  sauces: Item[];
  extraSauces: Item[];
  sides1: Item[];
  sides2: Item[];
  caloriesTotal: number;
  formulaTotal: number;
  quantity: number;
  completed: boolean;
  recipeTitle?: string;
}

export interface FormulaItemInput {
  formula: Formula;
  ingredient: Ingredient;
  id: number;
  isWarm?: boolean;
}
export interface DessertItemInput {
  dessert: Dessert;
  ingredient: Ingredient;
  id: number;
}

export interface CustomFormulaItemInput {
  ingredient: Ingredient;
  id: number;
  isWarm?: boolean;
}

export interface RecipeIdeaItem {
  title: string;
  ingredients: Item[];
  base: Item;
  sauce: Item;
}

export type CartState = {
  data: {
    cartTotal: number;
    discount: number;
    promoCode: string;
    items: Item[];
    signatures: SignatureSaladItem[];
    formulaItems: FormulaItem[];
    customFormulaItems: CustomFormulaItem[];
    eatfid_points_cost: number;
    suggested_count: SuggestedCount[];
    customDessertItems: DessertItem[];
  };
};

const initialState: CartState = {
  data: {
    cartTotal: 0,
    discount: 0,
    promoCode: "",
    items: [],
    signatures: [],
    formulaItems: [],
    customFormulaItems: [],
    eatfid_points_cost: 0,
    suggested_count: [],
    customDessertItems: [],
  },
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setItemComplete: (
      state: CartState,
      action: PayloadAction<{ formula?: Formula; id: number }>
    ) => {
      if (action.payload.formula) {
        let i = -1;
        state.data.formulaItems.forEach((item, j) => {
          if (j === action.payload.id) {
            i = j;
            return;
          }
        });
        state.data.formulaItems[i].completed = true;
      } else {
        let i = -1;
        state.data.customFormulaItems.forEach((item, j) => {
          if (j === action.payload.id) {
            i = j;
            return;
          }
        });
        const empty = state.data.customFormulaItems[i].bases.length == 0;
        state.data.customFormulaItems[i].completed = !empty;
      }
    },
    setDessertComplete: (
      state: CartState,
      action: PayloadAction<{ id: number }>
    ) => {
      let i = -1;
      state.data.customDessertItems.forEach((item, j) => {
        if (j === action.payload.id) {
          i = j;
          return;
        }
      });
      // state.data.customDessertItems[i].completed = true;
    },
    addItemToCart: (state: CartState, action: PayloadAction<WarmItem>) => {
      if (action.payload.is_suggestion) {
        const index = state.data.signatures.length - 1;
        state.data.signatures[index].freeSauce = action.payload.ingredient;
      } else {
        const i = cartContainsItem(action.payload.ingredient, state.data.items);
        if (i < 0)
          state.data.items.push({
            ingredient: action.payload.ingredient,
            quantity: 1,
            itemTotal: action.payload.ingredient.price,
            is_paid_with_eatfid_points: action.payload.ingredient.points
              ? true
              : false,
            points: action.payload.ingredient.points
              ? action.payload.ingredient.points
              : undefined,
            is_warm: action.payload.is_warm,
          });
        else {
          state.data.items[i].quantity++;
          state.data.items[i].itemTotal += action.payload.ingredient.price;
        }
      }
      if (!action.payload.is_suggestion)
        if (!action.payload.ingredient.points)
          state.data.cartTotal += action.payload.ingredient.price * 100;
        else state.data.eatfid_points_cost += action.payload.ingredient.points;
    },

    addSignatureSaladToCart: (
      state: CartState,
      action: PayloadAction<{
        ingredient: Ingredient;
        freeSauce?: Ingredient | undefined;
        asNew: boolean;
      }>
    ) => {
      if (action.payload.asNew) {
        state.data.signatures.push({
          ingredient: action.payload.ingredient,
          quantity: 1,
          itemTotal: action.payload.ingredient.price,
          freeSauce: undefined,
        });
        state.data.cartTotal += action.payload.ingredient.price * 100;
      } else {
        const i = cartContainsSignature(action.payload, state.data.signatures);

        if (i < 0)
          state.data.signatures.push({
            ingredient: action.payload.ingredient,
            quantity: 1,
            itemTotal: action.payload.ingredient.price,
          });
        else {
          state.data.signatures[i].quantity++;
          state.data.signatures[i].itemTotal += action.payload.ingredient.price;
        }

        state.data.cartTotal += action.payload.ingredient.price * 100;
      }
    },

    removeSauceFromSignature: (state: CartState) => {
      const index = state.data.signatures.length - 1;
      state.data.signatures[index].freeSauce = undefined;
    },
    removeToppingsFromDessert: (state: CartState) => {
      const index = state.data.customDessertItems.length - 1;
      state.data.customDessertItems[index].toppings = [];
    },

    removeItemFromCart: (
      state: CartState,
      action: PayloadAction<Ingredient>
    ) => {
      const i = cartContainsItem(action.payload, state.data.items);
      if (i < 0) return;
      if (action.payload)
        if (action.payload.points)
          state.data.eatfid_points_cost -= action.payload.points;
        else state.data.cartTotal -= state.data.items[i].ingredient.price * 100;
      if (state.data.items[i].quantity > 1) {
        state.data.items[i].itemTotal -= action.payload.price;
        state.data.items[i].quantity--;
      } else {
        state.data.items = state.data.items.filter((item) => {
          return !(
            item.ingredient.id === action.payload.id &&
            itemsAreBothEatFidOrNot(item, action.payload)
          );
        });
      }
    },

    removeSignatureSaladFromCart: (
      state: CartState,
      action: PayloadAction<SignatureSalad>
    ) => {
      const i = cartContainsSignature(action.payload, state.data.signatures);
      if (i < 0) return;
      state.data.cartTotal -= state.data.signatures[i].ingredient.price * 100;
      if (state.data.signatures[i].quantity > 1) {
        state.data.signatures[i].quantity--;
        state.data.signatures[i].itemTotal -=
          state.data.signatures[i].ingredient.price;
      } else {
        state.data.signatures.splice(i, 1);
      }
    },

    addNewFormulaToCart: (state: CartState, action: PayloadAction<Formula>) => {
      const id = state.data.formulaItems.length;

      const formulaItem: FormulaItem = {
        orderId: id,
        formula: action.payload,
        bases: [],
        extraBases: [],
        ingredients: [],
        extraIngredients: [],
        sauces: [],
        extraSauces: [],
        sides1: [],
        extraSides1: [],
        sides2: [],
        extraSides2: [],
        desserts: [],
        caloriesTotal: 0,
        supplementsTotal: 0,
        formulaTotal: action.payload.price,
        quantity: 1,
        completed: false,
        notModifiable: action.payload.notModifiable,
      };

      if (action.payload.is_paid_with_eatfid_points || action.payload.points) {
        formulaItem.is_paid_with_eatfid_points = true;
      }

      state.data.formulaItems.push(formulaItem);

      if (action.payload.points)
        state.data.eatfid_points_cost += action.payload.points;
      else {
        state.data.cartTotal += state.data.formulaItems[id].formulaTotal * 100;
      }
    },

    addRecipeIdeaAsFormulaToCart: (
      state: CartState,
      action: PayloadAction<{ recipeIdea: RecipeIdeaItem; formula: Formula }>
    ) => {
      const id = state.data.formulaItems.length;

      const { formula, recipeIdea } = action.payload;

      const extraPrice = recipeIdea.ingredients.reduce(
        (acc, ingredient, i) =>
          i >= formula.num_of_ingredients
            ? acc + ingredient.ingredient.price
            : 0,
        0
      );

      const formulaItem: FormulaItem = {
        orderId: id,
        formula,
        bases: [recipeIdea.base],
        extraBases: [],
        ingredients: recipeIdea.ingredients.slice(
          0,
          formula.num_of_ingredients
        ),
        extraIngredients: recipeIdea.ingredients.slice(
          formula.num_of_ingredients,
          20
        ),
        sauces: [recipeIdea.sauce],
        extraSauces: [],
        sides1: [],
        extraSides1: [],
        sides2: [],
        extraSides2: [],
        desserts: [],
        caloriesTotal: 0,
        supplementsTotal: extraPrice,
        formulaTotal: formula.price + extraPrice,
        quantity: 1,
        completed: false,
        recipeTitle: recipeIdea.title,
      };

      state.data.formulaItems.push(formulaItem);
      state.data.cartTotal += (formula.price + extraPrice) * 100;
    },

    addFormulaToCart: (
      state: CartState,
      action: PayloadAction<{ formula: Formula; id: number }>
    ) => {
      let i = -1;
      state.data.formulaItems.forEach((item, j) => {
        if (j === action.payload.id) {
          i = j;
          return;
        }
      });
      if (i < 0) {
        const formulaItem: FormulaItem = {
          orderId: state.data.formulaItems.length,
          formula: action.payload.formula,
          bases: [],
          extraBases: [],
          ingredients: [],
          extraIngredients: [],
          sauces: [],
          extraSauces: [],
          sides1: [],
          extraSides1: [],
          sides2: [],
          extraSides2: [],
          desserts: [],
          caloriesTotal: 0,
          supplementsTotal: 0,
          formulaTotal: action.payload.formula.price,
          quantity: 1,
          completed: false,
        };
        state.data.formulaItems.push(formulaItem);
        state.data.cartTotal += action.payload.formula.price * 100;
      } else {
        state.data.formulaItems[i].quantity++;
        state.data.cartTotal += state.data.formulaItems[i].formulaTotal * 100;
      }
    },

    addNewDessertToCart: (
      state: CartState,
      action: PayloadAction<{ dessert: Dessert }>
    ) => {
      const id = state.data.customDessertItems.length;

      const dessertItem: DessertItem = {
        orderId: id,
        supplementsTotal: 0,
        formulaTotal: action.payload.dessert.price,
        dessert: action.payload.dessert,
        quantity: 1,
        completed: false,
        coulis: [],
        toppings: [],
        extraCoulis: [],
        extraToppings: [],
      };

      state.data.customDessertItems.push(dessertItem);
      if (action.payload.dessert.points)
        state.data.eatfid_points_cost += action.payload.dessert.points;
      else {
        state.data.cartTotal +=
          state.data.customDessertItems[id].formulaTotal * 100;
      }
    },

    addDessertToCart: (
      state: CartState,
      action: PayloadAction<{ id: number }>
    ) => {
      let i = -1;
      state.data.customDessertItems.forEach((item, j) => {
        if (j === action.payload.id) {
          i = j;
          return;
        }
      });
      if (i < 0) {
        // const dessertItem: DessertItem = {
        //   orderId: state.data.customDessertItems.length,
        //   supplementsTotal: 0,
        //   formulaTotal: action.payload.dessert.price,
        //   quantity: 1,
        //   completed: false,
        //   coulis: [],
        //   toppings: [],
        // };
        // state.data.customDessertItems.push(dessertItem);
        // state.data.cartTotal += action.payload.dessert.price * 100;
        return;
      }
      state.data.customDessertItems[i].quantity++;
      state.data.cartTotal +=
        state.data.customDessertItems[i].formulaTotal * 100;
    },
    addRecipeIdeaAsCustomFormulaToCart: (
      state: CartState,
      action: PayloadAction<RecipeIdeaItem>
    ) => {
      const id = state.data.customFormulaItems.length;
      const price =
        action.payload.ingredients.reduce(
          (acc, ingredient) => acc + ingredient.ingredient.price * 100,
          0
        ) +
        action.payload.base.ingredient.price * 100;

      const customFormula: CustomFormulaItem = {
        orderId: id,
        bases: [action.payload.base],
        ingredients: action.payload.ingredients,
        sauces: [action.payload.sauce],
        extraSauces: [],
        sides1: [],
        sides2: [],
        caloriesTotal: 0,
        formulaTotal: price / 100,
        quantity: 1,
        completed: true,
        recipeTitle: action.payload.title,
      };

      state.data.customFormulaItems.push(customFormula);
      state.data.cartTotal += price;
    },
    addCustomFormulaToCart: (
      state: CartState,
      action: PayloadAction<{ id: number }>
    ) => {
      let i = -1;
      state.data.customFormulaItems.forEach((item, j) => {
        if (j === action.payload.id) {
          i = j;
          return;
        }
      });
      if (i < 0) {
        const customFormula: CustomFormulaItem = {
          orderId: state.data.customFormulaItems.length,
          bases: [],
          ingredients: [],
          sauces: [],
          extraSauces: [],
          sides1: [],
          sides2: [],
          caloriesTotal: 0,
          formulaTotal: 0,
          quantity: 1,
          completed: false,
        };
        state.data.customFormulaItems.push(customFormula);
      } else {
        state.data.customFormulaItems[i].quantity++;
        state.data.cartTotal +=
          state.data.customFormulaItems[i].formulaTotal * 100;
      }
    },

    removeCustomFormulaFromCart: (
      state: CartState,
      action: PayloadAction<{ id: number }>
    ) => {
      const id = action.payload.id;
      let i = -1;
      state.data.customFormulaItems.forEach((item, j) => {
        if (id === j) {
          i = j;
        }
      });
      if (i < 0) return;
      state.data.cartTotal -=
        state.data.customFormulaItems[i].formulaTotal * 100;
      if (state.data.customFormulaItems[i].quantity > 1)
        state.data.customFormulaItems[i].quantity--;
      else
        state.data.customFormulaItems = state.data.customFormulaItems.filter(
          (formula, j) => j !== i
        );
    },

    addBaseToCartCustomFormula: (
      state: CartState,
      action: PayloadAction<CustomFormulaItemInput>
    ) => {
      let i = -1;
      state.data.customFormulaItems.forEach((item, j) => {
        if (j === action.payload.id) {
          i = j;
          return;
        }
      });
      if (i < 0) return;
      if (state.data.customFormulaItems[i].bases.length === 0) {
        state.data.customFormulaItems[i].bases.push({
          ingredient: action.payload.ingredient,
          quantity: 1,
          itemTotal: action.payload.ingredient.price,
          is_warm: action.payload.isWarm,
        });
        state.data.customFormulaItems[i].caloriesTotal +=
          action.payload.ingredient.calories;
        state.data.customFormulaItems[i].formulaTotal +=
          action.payload.ingredient.price;
        state.data.cartTotal +=
          action.payload.ingredient.price *
          state.data.customFormulaItems[i].quantity *
          100;
        return;
      }
      const j = cartContainsItem(
        action.payload.ingredient,
        state.data.customFormulaItems[i].bases
      );
      if (j < 0)
        state.data.customFormulaItems[i].bases.push({
          ingredient: action.payload.ingredient,
          quantity: 1,
          itemTotal: action.payload.ingredient.price,
          is_warm: action.payload.isWarm,
        });
      else {
        state.data.customFormulaItems[i].bases[j].quantity++;
        state.data.customFormulaItems[i].bases[j].itemTotal +=
          action.payload.ingredient.price;
      }
      state.data.customFormulaItems[i].caloriesTotal +=
        action.payload.ingredient.calories;
      state.data.customFormulaItems[i].formulaTotal +=
        action.payload.ingredient.price;
      state.data.cartTotal +=
        action.payload.ingredient.price *
        state.data.customFormulaItems[i].quantity *
        100;
    },

    removeBaseFromCartCustomFormula: (
      state: CartState,
      action: PayloadAction<CustomFormulaItemInput>
    ) => {
      let i = -1;
      state.data.customFormulaItems.forEach((item, j) => {
        if (j === action.payload.id) {
          i = j;
          return;
        }
      });
      if (i < 0) return;
      const j = cartContainsItem(
        action.payload.ingredient,
        state.data.customFormulaItems[i].bases
      );
      if (j < 0) return;
      if (state.data.customFormulaItems[i].bases[j].quantity === 1) {
        state.data.customFormulaItems[i].bases = state.data.customFormulaItems[
          i
        ].bases.filter(
          (item) => item.ingredient.id !== action.payload.ingredient.id
        );
      } else {
        state.data.customFormulaItems[i].bases[j].quantity--;
        state.data.customFormulaItems[i].bases[j].itemTotal -=
          action.payload.ingredient.price;
      }
      state.data.customFormulaItems[i].formulaTotal -=
        action.payload.ingredient.price;
      state.data.cartTotal -=
        action.payload.ingredient.price *
        state.data.customFormulaItems[i].quantity *
        100;
      state.data.customFormulaItems[i].caloriesTotal -=
        action.payload.ingredient.calories;
    },

    addIngredientToCartCustomFormula: (
      state: CartState,
      action: PayloadAction<CustomFormulaItemInput>
    ) => {
      let i = -1;
      state.data.customFormulaItems.forEach((item, j) => {
        if (j === action.payload.id) {
          i = j;
          return;
        }
      });
      if (i < 0) return;
      const j = cartContainsItem(
        action.payload.ingredient,
        state.data.customFormulaItems[i].ingredients
      );
      if (j < 0)
        state.data.customFormulaItems[i].ingredients.push({
          ingredient: action.payload.ingredient,
          quantity: 1,
          itemTotal: action.payload.ingredient.price,
        });
      else {
        state.data.customFormulaItems[i].ingredients[j].quantity++;
        state.data.customFormulaItems[i].ingredients[j].itemTotal +=
          action.payload.ingredient.price;
      }
      state.data.customFormulaItems[i].caloriesTotal +=
        action.payload.ingredient.calories;
      state.data.customFormulaItems[i].formulaTotal +=
        action.payload.ingredient.price;
      state.data.cartTotal +=
        action.payload.ingredient.price *
        state.data.customFormulaItems[i].quantity *
        100;
    },

    addSauceToCartCustomFormula: (
      state: CartState,
      action: PayloadAction<CustomFormulaItemInput>
    ) => {
      let i = -1;
      state.data.customFormulaItems.forEach((item, j) => {
        if (j === action.payload.id) {
          i = j;
          return;
        }
      });
      if (i < 0) return;
      if (state.data.customFormulaItems[i].sauces.length === 0) {
        state.data.customFormulaItems[i].sauces.push({
          ingredient: action.payload.ingredient,
          quantity: 1,
          itemTotal: 0,
        });
      } else {
        const k = cartContainsItem(
          action.payload.ingredient,
          state.data.customFormulaItems[i].extraSauces
        );
        if (k < 0) {
          const k1 = cartContainsItem(
            action.payload.ingredient,
            state.data.customFormulaItems[i].extraSauces
          );
          if (k1 < 0)
            state.data.customFormulaItems[i].extraSauces.push({
              ingredient: action.payload.ingredient,
              quantity: 1,
              itemTotal: action.payload.ingredient.price,
            });
          else {
            state.data.customFormulaItems[i].extraSauces[k1].quantity++;
            state.data.customFormulaItems[i].extraSauces[k1].itemTotal +=
              action.payload.ingredient.price;
          }
        } else {
          state.data.customFormulaItems[i].extraSauces[k].quantity++;
          state.data.customFormulaItems[i].extraSauces[k].itemTotal +=
            action.payload.ingredient.price;
        }
        state.data.cartTotal +=
          action.payload.ingredient.price *
          state.data.customFormulaItems[i].quantity *
          100;
        state.data.customFormulaItems[i].formulaTotal +=
          action.payload.ingredient.price;
      }
      state.data.customFormulaItems[i].caloriesTotal +=
        action.payload.ingredient.calories;
    },

    addSide1ToCartCustomFormula: (
      state: CartState,
      action: PayloadAction<CustomFormulaItemInput>
    ) => {
      let i = -1;
      state.data.customFormulaItems.forEach((item, j) => {
        if (j === action.payload.id) {
          i = j;
          return;
        }
      });
      if (i < 0) return;
      const j = cartContainsItem(
        action.payload.ingredient,
        state.data.customFormulaItems[i].sides1
      );
      if (j < 0)
        state.data.customFormulaItems[i].sides1.push({
          ingredient: action.payload.ingredient,
          quantity: 1,
          itemTotal: action.payload.ingredient.price,
        });
      else {
        state.data.customFormulaItems[i].sides1[j].quantity++;
        state.data.customFormulaItems[i].sides1[j].itemTotal +=
          action.payload.ingredient.price;
      }
      state.data.customFormulaItems[i].caloriesTotal +=
        action.payload.ingredient.calories;
      state.data.customFormulaItems[i].formulaTotal +=
        action.payload.ingredient.price;
      state.data.cartTotal +=
        action.payload.ingredient.price *
        state.data.customFormulaItems[i].quantity *
        100;
    },

    addSide2ToCartCustomFormula: (
      state: CartState,
      action: PayloadAction<CustomFormulaItemInput>
    ) => {
      let i = -1;
      state.data.customFormulaItems.forEach((item, j) => {
        if (j === action.payload.id) {
          i = j;
          return;
        }
      });
      if (i < 0) return;
      const j = cartContainsItem(
        action.payload.ingredient,
        state.data.customFormulaItems[i].sides2
      );
      if (j < 0)
        state.data.customFormulaItems[i].sides2.push({
          ingredient: action.payload.ingredient,
          quantity: 1,
          itemTotal: action.payload.ingredient.price,
        });
      else {
        state.data.customFormulaItems[i].sides2[j].quantity++;
        state.data.customFormulaItems[i].sides2[j].itemTotal +=
          action.payload.ingredient.price;
      }
      state.data.customFormulaItems[i].caloriesTotal +=
        action.payload.ingredient.calories;
      state.data.customFormulaItems[i].formulaTotal +=
        action.payload.ingredient.price;
      state.data.cartTotal +=
        action.payload.ingredient.price *
        state.data.customFormulaItems[i].quantity *
        100;
    },

    addDessertToFormula: (
      state: CartState,
      action: PayloadAction<{ id: number }>
    ) => {
      const { id } = action.payload;
      const dessertId = state.data.customDessertItems.length - 1;
      const dessert = state.data.customDessertItems[dessertId];
      state.data.formulaItems[id].desserts.push(dessert);
      state.data.cartTotal -= dessert.formulaTotal * 100;
      state.data.customDessertItems = state.data.customDessertItems.filter(
        (item, i) => i < state.data.customDessertItems.length - 1
      );
    },

    removeDessertFromFormula: (
      state: CartState,
      action: PayloadAction<{ id: number }>
    ) => {
      const { id } = action.payload;
      let i = -1;
      state.data.formulaItems.forEach((item, j) => {
        if (id === j) i = j;
      });

      if (i < 0) return;

      if (
        state.data.formulaItems[i].formula.sides.length === 1 &&
        state.data.formulaItems[i].extraSides1.length > 0
      ) {
        const side1 = state.data.formulaItems[i].extraSides1[0];
        if (side1.quantity > 1) {
          state.data.formulaItems[i].extraSides1[0].itemTotal -=
            side1.ingredient.price;
          state.data.formulaItems[i].extraSides1[0].quantity--;
        } else {
          state.data.formulaItems[i].extraSides1.splice(0, 1);
        }
        state.data.formulaItems[i].sides1.push({
          ...side1,
          quantity: 1,
          itemTotal: side1.ingredient.price,
        });
        state.data.formulaItems[i].formulaTotal -= side1.ingredient.price;
        state.data.formulaItems[i].supplementsTotal -= side1.ingredient.price;
        state.data.cartTotal -= side1.ingredient.price * 100;
      }

      if (
        state.data.formulaItems[i].formula.sides.length === 2 &&
        state.data.formulaItems[i].extraSides2.length > 0
      ) {
        const side2 = state.data.formulaItems[i].extraSides2[0];
        if (side2.quantity > 1) {
          state.data.formulaItems[i].extraSides2[0].itemTotal -=
            side2.ingredient.price;
          state.data.formulaItems[i].extraSides2[0].quantity--;
        } else {
          state.data.formulaItems[i].extraSides2.splice(0, 1);
        }
        state.data.formulaItems[i].sides1.push({
          ...side2,
          quantity: 1,
          itemTotal: side2.ingredient.price,
        });
        state.data.formulaItems[i].formulaTotal -= side2.ingredient.price;
        state.data.formulaItems[i].supplementsTotal -= side2.ingredient.price;
        state.data.cartTotal -= side2.ingredient.price * 100;
      }

      state.data.formulaItems[i].desserts = [];
    },

    removeIngredientFromCartCustomFormula: (
      state: CartState,
      action: PayloadAction<CustomFormulaItemInput>
    ) => {
      let i = -1;
      state.data.customFormulaItems.forEach((item, j) => {
        if (j === action.payload.id) {
          i = j;
          return;
        }
      });
      if (i < 0) return;

      const j = cartContainsItem(
        action.payload.ingredient,
        state.data.customFormulaItems[i].ingredients
      );
      if (j < 0) return;
      if (state.data.customFormulaItems[i].ingredients[j].quantity === 1) {
        state.data.customFormulaItems[i].ingredients =
          state.data.customFormulaItems[i].ingredients.filter(
            (item) => item.ingredient.id !== action.payload.ingredient.id
          );
      } else {
        state.data.customFormulaItems[i].ingredients[j].quantity--;
        state.data.customFormulaItems[i].ingredients[j].itemTotal -=
          action.payload.ingredient.price;
      }
      state.data.customFormulaItems[i].formulaTotal -=
        action.payload.ingredient.price;
      state.data.cartTotal -=
        action.payload.ingredient.price *
        state.data.customFormulaItems[i].quantity *
        100;
      state.data.customFormulaItems[i].caloriesTotal -=
        action.payload.ingredient.calories;
    },

    removeSauceFromCartCustomFromula: (
      state: CartState,
      action: PayloadAction<CustomFormulaItemInput>
    ) => {
      let i = -1;
      state.data.customFormulaItems.forEach((item, j) => {
        if (j === action.payload.id) {
          i = j;
          return;
        }
      });
      if (i < 0) return;
      const j = cartContainsItem(
        action.payload.ingredient,
        state.data.customFormulaItems[i].sauces
      );
      if (j < 0) {
        const k = cartContainsItem(
          action.payload.ingredient,
          state.data.customFormulaItems[i].extraSauces
        );
        if (k >= 0) {
          if (state.data.customFormulaItems[i].extraSauces[k].quantity === 1) {
            state.data.customFormulaItems[i].extraSauces =
              state.data.customFormulaItems[i].extraSauces.filter(
                (item) => item.ingredient.id !== action.payload.ingredient.id
              );
          } else {
            state.data.customFormulaItems[i].extraSauces[k].quantity--;
            state.data.customFormulaItems[i].extraSauces[k].itemTotal -=
              action.payload.ingredient.price;
          }
          state.data.customFormulaItems[i].formulaTotal -=
            action.payload.ingredient.price;
          state.data.cartTotal -=
            action.payload.ingredient.price *
            state.data.customFormulaItems[i].quantity *
            100;
          state.data.customFormulaItems[i].caloriesTotal -=
            action.payload.ingredient.calories;
        }
        return;
      }
      if (state.data.customFormulaItems[i].sauces[j].quantity === 1) {
        const k = cartContainsItem(
          action.payload.ingredient,
          state.data.customFormulaItems[i].extraSauces
        );
        if (k >= 0) {
          if (state.data.customFormulaItems[i].extraSauces[k].quantity === 1) {
            state.data.customFormulaItems[i].extraSauces.push({
              ingredient: action.payload.ingredient,
              quantity: 1,
              itemTotal: 0,
            });
            state.data.customFormulaItems[i].extraSauces =
              state.data.customFormulaItems[i].extraSauces.filter(
                (item) => item.ingredient.id !== action.payload.ingredient.id
              );
          } else {
            state.data.customFormulaItems[i].extraSauces[k].quantity--;
            state.data.customFormulaItems[i].extraSauces[k].itemTotal -=
              action.payload.ingredient.price;
          }
          state.data.customFormulaItems[i].formulaTotal -=
            action.payload.ingredient.price;
          state.data.cartTotal -=
            action.payload.ingredient.price *
            state.data.customFormulaItems[i].quantity *
            100;
          return;
        } else {
          state.data.customFormulaItems[i].sauces =
            state.data.customFormulaItems[i].sauces.filter(
              (item) => item.ingredient.id !== action.payload.ingredient.id
            );
          if (state.data.customFormulaItems[i].extraSauces.length > 0) {
            if (
              state.data.customFormulaItems[i].extraSauces[0].quantity === 1
            ) {
              const object = state.data.customFormulaItems[i].extraSauces[0];
              state.data.customFormulaItems[i].sauces.push({
                ingredient:
                  state.data.customFormulaItems[i].extraSauces[0].ingredient,
                quantity: 1,
                itemTotal: 0,
              });
              state.data.customFormulaItems[i].extraSauces =
                state.data.customFormulaItems[i].extraSauces.filter(
                  (item) => item.ingredient.id !== object.ingredient.id
                );
              state.data.customFormulaItems[i].formulaTotal -=
                object.ingredient.price;
            } else {
              const object = state.data.customFormulaItems[i].extraSauces[0];
              state.data.customFormulaItems[i].sauces.push({
                ingredient: object.ingredient,
                itemTotal: object.ingredient.price,
                quantity: 1,
              });
              state.data.customFormulaItems[i].extraSauces[0].quantity--;
              state.data.customFormulaItems[i].formulaTotal -=
                object.ingredient.price;
            }
            state.data.cartTotal -=
              action.payload.ingredient.price *
              state.data.customFormulaItems[i].quantity *
              100;
          }
        }
      } else {
        state.data.customFormulaItems[i].sauces[j].quantity--;
        state.data.customFormulaItems[i].sauces[j].itemTotal -=
          action.payload.ingredient.price;
        state.data.customFormulaItems[i].formulaTotal -=
          action.payload.ingredient.price;
        state.data.cartTotal -=
          action.payload.ingredient.price *
          state.data.customFormulaItems[i].quantity *
          100;
      }
      state.data.customFormulaItems[i].caloriesTotal -=
        action.payload.ingredient.calories;
    },

    removeSide1FromCartCustomFormula: (
      state: CartState,
      action: PayloadAction<CustomFormulaItemInput>
    ) => {
      let i = -1;
      state.data.customFormulaItems.forEach((item, j) => {
        if (j === action.payload.id) {
          i = j;
          return;
        }
      });
      if (i < 0) return;

      const j = cartContainsItem(
        action.payload.ingredient,
        state.data.formulaItems[i].sides1
      );
      if (j < 0) return;
      if (state.data.customFormulaItems[i].sides1[j].quantity === 1) {
        state.data.customFormulaItems[i].sides1 = state.data.customFormulaItems[
          i
        ].sides1.filter(
          (item) => item.ingredient.id !== action.payload.ingredient.id
        );
      } else {
        state.data.customFormulaItems[i].sides1[j].quantity--;
        state.data.customFormulaItems[i].sides1[j].itemTotal -=
          action.payload.ingredient.price;
      }
      state.data.customFormulaItems[i].formulaTotal -=
        action.payload.ingredient.price;
      state.data.cartTotal -=
        action.payload.ingredient.price *
        state.data.customFormulaItems[i].quantity *
        100;
      state.data.customFormulaItems[i].caloriesTotal -=
        action.payload.ingredient.calories;
    },

    removeSide2FromCartCustomFormula: (
      state: CartState,
      action: PayloadAction<CustomFormulaItemInput>
    ) => {
      let i = -1;
      state.data.customFormulaItems.forEach((item, j) => {
        if (j === action.payload.id) {
          i = j;
          return;
        }
      });
      if (i < 0) return;

      const j = cartContainsItem(
        action.payload.ingredient,
        state.data.formulaItems[i].sides2
      );
      if (j < 0) return;
      if (state.data.customFormulaItems[i].sides2[j].quantity === 1) {
        state.data.customFormulaItems[i].sides2 = state.data.customFormulaItems[
          i
        ].sides2.filter(
          (item) => item.ingredient.id !== action.payload.ingredient.id
        );
      } else {
        state.data.customFormulaItems[i].sides2[j].quantity--;
        state.data.customFormulaItems[i].sides2[j].itemTotal -=
          action.payload.ingredient.price;
      }
      state.data.customFormulaItems[i].formulaTotal -=
        action.payload.ingredient.price;
      state.data.cartTotal -=
        action.payload.ingredient.price *
        state.data.customFormulaItems[i].quantity *
        100;
      state.data.customFormulaItems[i].caloriesTotal -=
        action.payload.ingredient.calories;
    },

    addBaseToCartFormula: (
      state: CartState,
      action: PayloadAction<FormulaItemInput>
    ) => {
      let i = -1;
      state.data.formulaItems.forEach((item, j) => {
        if (j === action.payload.id) {
          i = j;
          return;
        }
      });
      if (i < 0) return;
      if (
        state.data.formulaItems[i].bases.length <
        action.payload.formula.num_of_base
      ) {
        const j = cartContainsItem(
          action.payload.ingredient,
          state.data.formulaItems[i].bases
        );
        if (j < 0)
          state.data.formulaItems[i].bases.push({
            ingredient: action.payload.ingredient,
            quantity: 1,
            itemTotal:
              action.payload.ingredient.price +
              action.payload.ingredient.premium_price,
            is_warm: action.payload.isWarm,
          });
        else {
          state.data.formulaItems[i].bases[j].quantity++;
          state.data.formulaItems[i].bases[j].itemTotal +=
            action.payload.ingredient.price +
            action.payload.ingredient.premium_price;
        }
        state.data.formulaItems[i].caloriesTotal +=
          action.payload.ingredient.calories;
        if (action.payload.ingredient.premium_price) {
          state.data.cartTotal += action.payload.ingredient.premium_price * 100;
          state.data.formulaItems[i].formulaTotal +=
            action.payload.ingredient.premium_price;
        }
        return;
      }
      const j = cartContainsItem(
        action.payload.ingredient,
        state.data.formulaItems[i].extraBases
      );
      if (j < 0)
        state.data.formulaItems[i].extraBases.push({
          ingredient: action.payload.ingredient,
          quantity: 1,
          itemTotal: action.payload.ingredient.price,
          is_warm: action.payload.isWarm,
        });
      else {
        state.data.formulaItems[i].extraBases[j].quantity++;
        state.data.formulaItems[i].extraBases[j].itemTotal +=
          action.payload.ingredient.price;
      }
      state.data.formulaItems[i].caloriesTotal +=
        action.payload.ingredient.calories;
      state.data.formulaItems[i].supplementsTotal +=
        action.payload.ingredient.price;
      state.data.formulaItems[i].formulaTotal +=
        action.payload.ingredient.price;
      state.data.cartTotal +=
        action.payload.ingredient.price *
        state.data.formulaItems[i].quantity *
        100;
    },

    addIngredientToCartFormula: (
      state: CartState,
      action: PayloadAction<FormulaItemInput>
    ) => {
      let i = -1;
      state.data.formulaItems.forEach((item, j) => {
        if (j === action.payload.id) {
          i = j;
          return;
        }
      });
      if (i < 0) return;
      let itemsNumber = 0;
      state.data.formulaItems[i].ingredients.forEach(
        (item) => (itemsNumber += item.quantity)
      );
      if (itemsNumber < action.payload.formula.num_of_ingredients) {
        const j = cartContainsItem(
          action.payload.ingredient,
          state.data.formulaItems[i].ingredients
        );
        if (j < 0)
          state.data.formulaItems[i].ingredients.push({
            ingredient: action.payload.ingredient,
            quantity: 1,
            itemTotal:
              action.payload.ingredient.price +
              action.payload.ingredient.premium_price,
          });
        else {
          state.data.formulaItems[i].ingredients[j].quantity++;
          state.data.formulaItems[i].ingredients[j].itemTotal +=
            action.payload.ingredient.price +
            action.payload.ingredient.premium_price;
        }
        state.data.formulaItems[i].caloriesTotal +=
          action.payload.ingredient.calories;
        if (action.payload.ingredient.premium_price) {
          state.data.cartTotal += action.payload.ingredient.premium_price * 100;
          state.data.formulaItems[i].formulaTotal +=
            action.payload.ingredient.premium_price;
        }
        return;
      } else {
        const j = cartContainsItem(
          action.payload.ingredient,
          state.data.formulaItems[i].extraIngredients
        );
        if (j < 0)
          state.data.formulaItems[i].extraIngredients.push({
            ingredient: action.payload.ingredient,
            quantity: 1,
            itemTotal: action.payload.ingredient.price,
          });
        else {
          state.data.formulaItems[i].extraIngredients[j].quantity++;
          state.data.formulaItems[i].extraIngredients[j].itemTotal +=
            action.payload.ingredient.price;
        }
        state.data.formulaItems[i].caloriesTotal +=
          action.payload.ingredient.calories;
        state.data.formulaItems[i].supplementsTotal +=
          action.payload.ingredient.price;
        state.data.formulaItems[i].formulaTotal +=
          action.payload.ingredient.price;
        state.data.cartTotal +=
          action.payload.ingredient.price *
          state.data.formulaItems[i].quantity *
          100;
      }
    },

    addSauceToCartFormula: (
      state: CartState,
      action: PayloadAction<FormulaItemInput>
    ) => {
      let i = -1;
      state.data.formulaItems.forEach((item, j) => {
        if (j === action.payload.id) {
          i = j;
          return;
        }
      });
      if (i < 0) return;
      if (
        state.data.formulaItems[i].sauces.length <
        action.payload.formula.num_of_sauce
      ) {
        const j = cartContainsItem(
          action.payload.ingredient,
          state.data.formulaItems[i].sauces
        );
        if (j < 0)
          state.data.formulaItems[i].sauces.push({
            ingredient: action.payload.ingredient,
            quantity: 1,
            itemTotal:
              action.payload.ingredient.price +
              action.payload.ingredient.premium_price,
          });
        else {
          state.data.formulaItems[i].sauces[j].quantity++;
          state.data.formulaItems[i].sauces[j].itemTotal +=
            action.payload.ingredient.price +
            action.payload.ingredient.premium_price;
        }
        state.data.formulaItems[i].caloriesTotal +=
          action.payload.ingredient.calories;
        if (action.payload.ingredient.premium_price) {
          state.data.cartTotal += action.payload.ingredient.premium_price * 100;
          state.data.formulaItems[i].formulaTotal +=
            action.payload.ingredient.premium_price;
        }
        return;
      }
      const j = cartContainsItem(
        action.payload.ingredient,
        state.data.formulaItems[i].extraSauces
      );
      if (j < 0)
        state.data.formulaItems[i].extraSauces.push({
          ingredient: action.payload.ingredient,
          quantity: 1,
          itemTotal: action.payload.ingredient.price,
        });
      else {
        state.data.formulaItems[i].extraSauces[j].quantity++;
        state.data.formulaItems[i].extraSauces[j].itemTotal +=
          action.payload.ingredient.price;
      }
      state.data.formulaItems[i].caloriesTotal +=
        action.payload.ingredient.calories;
      state.data.formulaItems[i].supplementsTotal +=
        action.payload.ingredient.price;
      state.data.formulaItems[i].formulaTotal +=
        action.payload.ingredient.price;
      state.data.cartTotal +=
        action.payload.ingredient.price *
        state.data.formulaItems[i].quantity *
        100;
    },

    addCoulisToDessert: (
      state: CartState,
      action: PayloadAction<DessertItemInput>
    ) => {
      let i = -1;
      state.data.customDessertItems.forEach((item, j) => {
        if (j === action.payload.id) {
          i = j;
          return;
        }
      });
      if (i < 0) return;
      if (
        state.data.customDessertItems[i].coulis.length <
        action.payload.dessert.num_of_coulis
      ) {
        const j = cartContainsItem(
          action.payload.ingredient,
          state.data.customDessertItems[i].coulis
        );
        if (j < 0)
          state.data.customDessertItems[i].coulis.push({
            ingredient: action.payload.ingredient,
            quantity: 1,
            itemTotal:
              action.payload.ingredient.price +
              action.payload.ingredient.premium_price,
          });
        else {
          state.data.customDessertItems[i].coulis[j].quantity++;
          state.data.customDessertItems[i].coulis[j].itemTotal +=
            action.payload.ingredient.price +
            action.payload.ingredient.premium_price;
        }
        if (action.payload.ingredient.premium_price) {
          state.data.cartTotal += action.payload.ingredient.premium_price * 100;
          state.data.customDessertItems[i].formulaTotal +=
            action.payload.ingredient.premium_price;
        }
        return;
      }
      const j = cartContainsItem(
        action.payload.ingredient,
        state.data.customDessertItems[i].extraCoulis
      );
      if (j < 0)
        state.data.customDessertItems[i].extraCoulis.push({
          ingredient: action.payload.ingredient,
          quantity: 1,
          itemTotal: action.payload.ingredient.price,
        });
      else {
        state.data.customDessertItems[i].extraCoulis[j].quantity++;
        state.data.customDessertItems[i].extraCoulis[j].itemTotal +=
          action.payload.ingredient.price;
      }
      state.data.customDessertItems[i].supplementsTotal +=
        action.payload.ingredient.price;
      state.data.customDessertItems[i].formulaTotal +=
        action.payload.ingredient.price;
      state.data.cartTotal +=
        action.payload.ingredient.price *
        state.data.customDessertItems[i].quantity *
        100;
    },
    addToppingToDessert: (
      state: CartState,
      action: PayloadAction<DessertItemInput>
    ) => {
      let i = -1;
      state.data.customDessertItems.forEach((item, j) => {
        if (j === action.payload.id) {
          i = j;
          return;
        }
      });
      if (i < 0) return;
      if (
        state.data.customDessertItems[i].toppings.length <
        action.payload.dessert.num_of_toppings
      ) {
        const j = cartContainsItem(
          action.payload.ingredient,
          state.data.customDessertItems[i].toppings
        );
        if (j < 0)
          state.data.customDessertItems[i].toppings.push({
            ingredient: action.payload.ingredient,
            quantity: 1,
            itemTotal:
              action.payload.ingredient.price +
              action.payload.ingredient.premium_price,
          });
        else {
          state.data.customDessertItems[i].toppings[j].quantity++;
          state.data.customDessertItems[i].toppings[j].itemTotal +=
            action.payload.ingredient.price +
            action.payload.ingredient.premium_price;
        }
        if (action.payload.ingredient.premium_price) {
          state.data.cartTotal += action.payload.ingredient.premium_price * 100;
          state.data.customDessertItems[i].formulaTotal +=
            action.payload.ingredient.premium_price;
        }
        return;
      }
      const j = cartContainsItem(
        action.payload.ingredient,
        state.data.customDessertItems[i].extraToppings
      );
      if (j < 0)
        state.data.customDessertItems[i].extraToppings.push({
          ingredient: action.payload.ingredient,
          quantity: 1,
          itemTotal: action.payload.ingredient.price,
        });
      else {
        state.data.customDessertItems[i].extraToppings[j].quantity++;
        state.data.customDessertItems[i].extraToppings[j].itemTotal +=
          action.payload.ingredient.price;
      }
      state.data.customDessertItems[i].supplementsTotal +=
        action.payload.ingredient.price;
      state.data.customDessertItems[i].formulaTotal +=
        action.payload.ingredient.price;
      state.data.cartTotal +=
        action.payload.ingredient.price *
        state.data.customDessertItems[i].quantity *
        100;
    },

    addSide1ToCartFormula: (
      state: CartState,
      action: PayloadAction<FormulaItemInput>
    ) => {
      let i = -1;
      state.data.formulaItems.forEach((item, j) => {
        if (j === action.payload.id) {
          i = j;
          return;
        }
      });
      if (i < 0) return;
      if (
        state.data.formulaItems[i].sides1.length < 1 &&
        (action.payload.ingredient.category.slug.includes("dessert")
          ? !state.data.formulaItems[i].desserts.length
          : true)
      ) {
        const j = cartContainsItem(
          action.payload.ingredient,
          state.data.formulaItems[i].sides1
        );
        if (j < 0)
          state.data.formulaItems[i].sides1.push({
            ingredient: action.payload.ingredient,
            quantity: 1,
            itemTotal:
              action.payload.ingredient.price +
              action.payload.ingredient.premium_price,
          });
        else {
          state.data.formulaItems[i].sides1[j].quantity++;
          state.data.formulaItems[i].sides1[j].itemTotal +=
            action.payload.ingredient.price +
            action.payload.ingredient.premium_price;
        }
        state.data.formulaItems[i].caloriesTotal +=
          action.payload.ingredient.calories;
        if (action.payload.ingredient.premium_price) {
          state.data.cartTotal += action.payload.ingredient.premium_price * 100;
          state.data.formulaItems[i].formulaTotal +=
            action.payload.ingredient.premium_price;
        }
        return;
      }
      const j = cartContainsItem(
        action.payload.ingredient,
        state.data.formulaItems[i].extraSides1
      );
      if (j < 0)
        state.data.formulaItems[i].extraSides1.push({
          ingredient: action.payload.ingredient,
          quantity: 1,
          itemTotal: action.payload.ingredient.price,
        });
      else {
        state.data.formulaItems[i].extraSides1[j].quantity++;
        state.data.formulaItems[i].extraSides1[j].itemTotal +=
          action.payload.ingredient.price;
      }
      state.data.formulaItems[i].caloriesTotal +=
        action.payload.ingredient.calories;
      state.data.formulaItems[i].supplementsTotal +=
        action.payload.ingredient.price;
      state.data.formulaItems[i].formulaTotal +=
        action.payload.ingredient.price;
      state.data.cartTotal +=
        action.payload.ingredient.price *
        state.data.formulaItems[i].quantity *
        100;
    },

    addSide2ToCartFormula: (
      state: CartState,
      action: PayloadAction<FormulaItemInput>
    ) => {
      let i = -1;
      state.data.formulaItems.forEach((item, j) => {
        if (j === action.payload.id) {
          i = j;
          return;
        }
      });
      if (i < 0) return;
      if (
        state.data.formulaItems[i].sides2.length < 1 &&
        !state.data.formulaItems[i].desserts.length
      ) {
        const j = cartContainsItem(
          action.payload.ingredient,
          state.data.formulaItems[i].sides2
        );
        if (j < 0)
          state.data.formulaItems[i].sides2.push({
            ingredient: action.payload.ingredient,
            quantity: 1,
            itemTotal:
              action.payload.ingredient.price +
              action.payload.ingredient.premium_price,
          });
        else {
          state.data.formulaItems[i].sides2[j].quantity++;
          state.data.formulaItems[i].sides2[j].itemTotal +=
            action.payload.ingredient.price +
            action.payload.ingredient.premium_price;
        }
        state.data.formulaItems[i].caloriesTotal +=
          action.payload.ingredient.calories;
        if (action.payload.ingredient.premium_price) {
          state.data.cartTotal += action.payload.ingredient.premium_price * 100;
          state.data.formulaItems[i].formulaTotal +=
            action.payload.ingredient.premium_price;
        }
        return;
      }
      const j = cartContainsItem(
        action.payload.ingredient,
        state.data.formulaItems[i].extraSides2
      );
      if (j < 0)
        state.data.formulaItems[i].extraSides2.push({
          ingredient: action.payload.ingredient,
          quantity: 1,
          itemTotal: action.payload.ingredient.price,
        });
      else {
        state.data.formulaItems[i].extraSides2[j].quantity++;
        state.data.formulaItems[i].extraSides2[j].itemTotal +=
          action.payload.ingredient.price;
      }
      state.data.formulaItems[i].caloriesTotal +=
        action.payload.ingredient.calories;
      state.data.formulaItems[i].supplementsTotal +=
        action.payload.ingredient.price;
      state.data.formulaItems[i].formulaTotal +=
        action.payload.ingredient.price;
      state.data.cartTotal +=
        action.payload.ingredient.price *
        state.data.formulaItems[i].quantity *
        100;
    },

    removeBaseFromCartFormula: (
      state: CartState,
      action: PayloadAction<FormulaItemInput>
    ) => {
      let i = -1;
      state.data.formulaItems.forEach((item, j) => {
        if (j === action.payload.id) {
          i = j;
          return;
        }
      });
      if (i < 0) return;
      if (
        cartContainsItem(
          action.payload.ingredient,
          state.data.formulaItems[i].bases
        ) !== -1
      ) {
        const j = cartContainsItem(
          action.payload.ingredient,
          state.data.formulaItems[i].bases
        );
        if (j < 0) return;
        if (state.data.formulaItems[i].extraBases.length > 0) {
          const k = cartContainsItem(
            action.payload.ingredient,
            state.data.formulaItems[i].extraBases
          );
          if (k !== -1) {
            if (state.data.formulaItems[i].extraBases[k].quantity === 1) {
              state.data.formulaItems[i].extraBases = state.data.formulaItems[
                i
              ].extraBases.filter(
                (item) => item.ingredient.id !== action.payload.ingredient.id
              );
            } else if (state.data.formulaItems[i].extraBases[k].quantity > 1) {
              state.data.formulaItems[i].extraBases[k].quantity--;
              state.data.formulaItems[i].extraBases[k].itemTotal -=
                state.data.formulaItems[i].extraBases[k].ingredient.price;
            }
            state.data.formulaItems[i].supplementsTotal -=
              action.payload.ingredient.price;
            state.data.formulaItems[i].formulaTotal -=
              action.payload.ingredient.price;
            state.data.cartTotal -=
              action.payload.ingredient.price *
              state.data.formulaItems[i].quantity *
              100;
            state.data.formulaItems[i].caloriesTotal -=
              action.payload.ingredient.calories;
            return;
          }
        }
        if (state.data.formulaItems[i].bases[j].quantity === 1) {
          state.data.formulaItems[i].bases = state.data.formulaItems[
            i
          ].bases.filter(
            (item) => item.ingredient.id !== action.payload.ingredient.id
          );
          if (state.data.formulaItems[i].extraBases.length > 0) {
            if (state.data.formulaItems[i].extraBases[0].quantity === 1) {
              const object = state.data.formulaItems[i].extraBases[0];
              state.data.cartTotal -=
                object.ingredient.price *
                state.data.formulaItems[i].quantity *
                100;
              state.data.formulaItems[i].bases.push({
                ingredient: object.ingredient,
                quantity: 1,
                itemTotal:
                  object.ingredient.price + object.ingredient.premium_price,
                is_warm: object.is_warm,
              });
              state.data.formulaItems[i].extraBases = state.data.formulaItems[
                i
              ].extraBases.filter(
                (item) => item.ingredient.id !== object.ingredient.id
              );
              state.data.formulaItems[i].supplementsTotal -=
                object.ingredient.price;
              state.data.formulaItems[i].formulaTotal -=
                object.ingredient.price;
              state.data.formulaItems[i].formulaTotal +=
                object.ingredient.premium_price;
            } else {
              const object = state.data.formulaItems[i].extraBases[0];
              state.data.cartTotal -=
                object.ingredient.price *
                state.data.formulaItems[i].quantity *
                100;
              state.data.formulaItems[i].bases.push({
                ingredient: object.ingredient,
                itemTotal: object.ingredient.price,
                quantity: 1,
              });
              state.data.formulaItems[i].extraBases[0].quantity--;
              state.data.formulaItems[i].supplementsTotal -=
                object.ingredient.price;
              state.data.formulaItems[i].formulaTotal -=
                object.ingredient.price;
            }
          }
        } else {
          state.data.formulaItems[i].bases[j].quantity--;
          state.data.formulaItems[i].bases[j].itemTotal -=
            action.payload.ingredient.price -
            action.payload.ingredient.premium_price;
        }
        state.data.formulaItems[i].caloriesTotal -=
          action.payload.ingredient.calories;
        if (action.payload.ingredient.premium_price > 0) {
          state.data.cartTotal -= action.payload.ingredient.premium_price * 100;
          state.data.formulaItems[i].formulaTotal -=
            action.payload.ingredient.premium_price;
        }
        return;
      } else if (
        cartContainsItem(
          action.payload.ingredient,
          state.data.formulaItems[i].extraBases
        ) !== -1
      ) {
        const j = cartContainsItem(
          action.payload.ingredient,
          state.data.formulaItems[i].extraBases
        );
        if (j < 0) return;
        state.data.formulaItems[i].caloriesTotal -=
          action.payload.ingredient.calories;
        state.data.formulaItems[i].supplementsTotal -=
          action.payload.ingredient.price;
        state.data.formulaItems[i].formulaTotal -=
          action.payload.ingredient.price;
        state.data.cartTotal -=
          action.payload.ingredient.price *
          state.data.formulaItems[i].quantity *
          100;
        if (state.data.formulaItems[i].extraBases[j].quantity === 1)
          state.data.formulaItems[i].extraBases = state.data.formulaItems[
            i
          ].extraBases.filter(
            (item) => item.ingredient.id !== action.payload.ingredient.id
          );
        else {
          state.data.formulaItems[i].extraBases[j].quantity--;
          state.data.formulaItems[i].extraBases[j].itemTotal -=
            action.payload.ingredient.price;
        }
      }
    },

    removeIngredientFromCartFormula: (
      state: CartState,
      action: PayloadAction<FormulaItemInput>
    ) => {
      let i = -1;
      state.data.formulaItems.forEach((item, j) => {
        if (j === action.payload.id) {
          i = j;
          return;
        }
      });
      if (i < 0) return;
      if (
        cartContainsItem(
          action.payload.ingredient,
          state.data.formulaItems[i].ingredients
        ) !== -1
      ) {
        const j = cartContainsItem(
          action.payload.ingredient,
          state.data.formulaItems[i].ingredients
        );
        if (j < 0) return;
        if (state.data.formulaItems[i].extraIngredients.length > 0) {
          const k = cartContainsItem(
            action.payload.ingredient,
            state.data.formulaItems[i].extraIngredients
          );
          if (k !== -1) {
            if (state.data.formulaItems[i].extraIngredients[k].quantity === 1) {
              state.data.formulaItems[i].extraIngredients =
                state.data.formulaItems[i].extraIngredients.filter(
                  (item) => item.ingredient.id !== action.payload.ingredient.id
                );
            } else if (
              state.data.formulaItems[i].extraIngredients[k].quantity > 1
            ) {
              state.data.formulaItems[i].extraIngredients[k].quantity--;
              state.data.formulaItems[i].extraIngredients[k].itemTotal -=
                state.data.formulaItems[i].extraIngredients[k].ingredient.price;
            }
            state.data.formulaItems[i].supplementsTotal -=
              action.payload.ingredient.price;
            state.data.formulaItems[i].formulaTotal -=
              action.payload.ingredient.price;
            state.data.cartTotal -=
              action.payload.ingredient.price *
              state.data.formulaItems[i].quantity *
              100;
            state.data.formulaItems[i].caloriesTotal -=
              action.payload.ingredient.calories;
            return;
          }
        }
        if (state.data.formulaItems[i].ingredients[j].quantity === 1) {
          state.data.formulaItems[i].ingredients = state.data.formulaItems[
            i
          ].ingredients.filter(
            (item) => item.ingredient.id !== action.payload.ingredient.id
          );
          if (state.data.formulaItems[i].extraIngredients.length > 0) {
            if (state.data.formulaItems[i].extraIngredients[0].quantity === 1) {
              const object = state.data.formulaItems[i].extraIngredients[0];
              state.data.cartTotal -=
                object.ingredient.price *
                state.data.formulaItems[i].quantity *
                100;
              const k1 = cartContainsItem(
                object.ingredient,
                state.data.formulaItems[i].ingredients
              );
              if (k1 < 0) {
                state.data.formulaItems[i].ingredients.push({
                  ingredient: object.ingredient,
                  itemTotal:
                    object.ingredient.price + object.ingredient.premium_price,
                  quantity: 1,
                });
              } else {
                state.data.formulaItems[i].ingredients[k1].quantity++;
                state.data.formulaItems[i].ingredients[k1].itemTotal +=
                  state.data.formulaItems[i].ingredients[k1].ingredient.price +
                  state.data.formulaItems[i].ingredients[k1].ingredient
                    .premium_price;
              }
              state.data.formulaItems[i].extraIngredients =
                state.data.formulaItems[i].extraIngredients.filter(
                  (item) => item.ingredient.id !== object.ingredient.id
                );
              state.data.formulaItems[i].supplementsTotal -=
                object.ingredient.price;
              state.data.formulaItems[i].formulaTotal -=
                object.ingredient.price;
              if (object.ingredient.premium_price > 0) {
                state.data.cartTotal += object.ingredient.premium_price * 100;
                state.data.formulaItems[i].formulaTotal +=
                  object.ingredient.premium_price;
              }
            } else {
              const object = state.data.formulaItems[i].extraIngredients[0];
              state.data.cartTotal -=
                object.ingredient.price *
                state.data.formulaItems[i].quantity *
                100;
              const k1 = cartContainsItem(
                object.ingredient,
                state.data.formulaItems[i].ingredients
              );
              if (k1 < 0) {
                state.data.formulaItems[i].ingredients.push({
                  ingredient: object.ingredient,
                  itemTotal:
                    object.ingredient.price + object.ingredient.premium_price,
                  quantity: 1,
                });
              } else {
                state.data.formulaItems[i].ingredients[k1].quantity++;
                state.data.formulaItems[i].ingredients[k1].itemTotal +=
                  state.data.formulaItems[i].ingredients[k1].ingredient.price +
                  state.data.formulaItems[i].ingredients[k1].ingredient
                    .premium_price;
              }
              state.data.formulaItems[i].extraIngredients[0].quantity--;
              state.data.formulaItems[i].supplementsTotal -=
                object.ingredient.price;
              state.data.formulaItems[i].formulaTotal -=
                object.ingredient.price;
              if (object.ingredient.premium_price > 0) {
                state.data.cartTotal += object.ingredient.premium_price * 100;
                state.data.formulaItems[i].formulaTotal +=
                  object.ingredient.premium_price;
              }
            }
          }
        } else {
          state.data.formulaItems[i].ingredients[j].quantity--;
          state.data.formulaItems[i].ingredients[j].itemTotal -=
            action.payload.ingredient.price;
          let q = 0;
          state.data.formulaItems[i].ingredients.forEach(
            (item) => (q += item.quantity)
          );
          if (q < action.payload.formula.num_of_ingredients) {
            if (state.data.formulaItems[i].extraIngredients.length > 0) {
              if (
                state.data.formulaItems[i].extraIngredients[0].quantity === 1
              ) {
                const object = state.data.formulaItems[i].extraIngredients[0];
                const k1 = cartContainsItem(
                  object.ingredient,
                  state.data.formulaItems[i].ingredients
                );
                state.data.cartTotal -=
                  object.ingredient.price *
                  state.data.formulaItems[i].quantity *
                  100;
                if (k1 < 0) {
                  state.data.formulaItems[i].ingredients.push({
                    ingredient: object.ingredient,
                    itemTotal:
                      object.ingredient.price + object.ingredient.premium_price,
                    quantity: 1,
                  });
                } else {
                  state.data.formulaItems[i].ingredients[k1].quantity++;
                  state.data.formulaItems[i].ingredients[k1].itemTotal +=
                    state.data.formulaItems[i].ingredients[k1].ingredient
                      .price +
                    state.data.formulaItems[i].ingredients[k1].ingredient
                      .premium_price;
                }
                state.data.formulaItems[i].extraIngredients =
                  state.data.formulaItems[i].extraIngredients.filter(
                    (item) => item.ingredient.id !== object.ingredient.id
                  );
                state.data.formulaItems[i].supplementsTotal -=
                  object.ingredient.price;
                state.data.formulaItems[i].formulaTotal -=
                  object.ingredient.price;

                if (object.ingredient.premium_price > 0) {
                  state.data.cartTotal += object.ingredient.premium_price * 100;
                  state.data.formulaItems[i].formulaTotal +=
                    object.ingredient.premium_price;
                }
              } else {
                const object = state.data.formulaItems[i].extraIngredients[0];
                const k1 = cartContainsItem(
                  object.ingredient,
                  state.data.formulaItems[i].ingredients
                );
                if (k1 < 0) {
                  state.data.formulaItems[i].ingredients.push({
                    ingredient: object.ingredient,
                    itemTotal:
                      object.ingredient.price + object.ingredient.premium_price,
                    quantity: 1,
                  });
                } else {
                  state.data.formulaItems[i].ingredients[k1].quantity++;
                  state.data.formulaItems[i].ingredients[k1].itemTotal +=
                    state.data.formulaItems[i].ingredients[k1].ingredient
                      .price +
                    state.data.formulaItems[i].ingredients[k1].ingredient
                      .premium_price;
                }
                state.data.formulaItems[i].extraIngredients[0].itemTotal -=
                  object.ingredient.price;
                state.data.cartTotal -=
                  object.ingredient.price *
                  state.data.formulaItems[i].quantity *
                  100;
                state.data.formulaItems[i].extraIngredients[0].quantity--;
                state.data.formulaItems[i].supplementsTotal -=
                  object.ingredient.price;
                state.data.formulaItems[i].formulaTotal -=
                  object.ingredient.price;

                if (object.ingredient.premium_price > 0) {
                  state.data.cartTotal += object.ingredient.premium_price * 100;
                  state.data.formulaItems[i].formulaTotal +=
                    object.ingredient.premium_price;
                }
              }
            }
          }
        }
        state.data.formulaItems[i].caloriesTotal -=
          action.payload.ingredient.calories;
        if (action.payload.ingredient.premium_price > 0) {
          state.data.formulaItems[i].formulaTotal -=
            action.payload.ingredient.premium_price;
          state.data.cartTotal -= action.payload.ingredient.premium_price * 100;
        }
        return;
      } else if (
        cartContainsItem(
          action.payload.ingredient,
          state.data.formulaItems[i].extraIngredients
        ) !== -1
      ) {
        const j = cartContainsItem(
          action.payload.ingredient,
          state.data.formulaItems[i].extraIngredients
        );
        if (j < 0) return;
        state.data.formulaItems[i].caloriesTotal -=
          action.payload.ingredient.calories;
        state.data.formulaItems[i].supplementsTotal -=
          action.payload.ingredient.price;
        state.data.formulaItems[i].formulaTotal -=
          action.payload.ingredient.price;
        state.data.cartTotal -=
          action.payload.ingredient.price *
          state.data.formulaItems[i].quantity *
          100;
        if (state.data.formulaItems[i].extraIngredients[j].quantity === 1)
          state.data.formulaItems[i].extraIngredients = state.data.formulaItems[
            i
          ].extraIngredients.filter(
            (item) => item.ingredient.id !== action.payload.ingredient.id
          );
        else {
          state.data.formulaItems[i].extraIngredients[j].quantity--;
          state.data.formulaItems[i].extraIngredients[j].itemTotal -=
            action.payload.ingredient.price;
        }
      }
    },

    removeSauceFromCartFormula: (
      state: CartState,
      action: PayloadAction<FormulaItemInput>
    ) => {
      let i = -1;
      state.data.formulaItems.forEach((item, j) => {
        if (j === action.payload.id) {
          i = j;
          return;
        }
      });
      if (i < 0) return;
      if (
        cartContainsItem(
          action.payload.ingredient,
          state.data.formulaItems[i].sauces
        ) !== -1
      ) {
        const j = cartContainsItem(
          action.payload.ingredient,
          state.data.formulaItems[i].sauces
        );
        if (j < 0) return;
        if (state.data.formulaItems[i].extraSauces.length > 0) {
          const k = cartContainsItem(
            action.payload.ingredient,
            state.data.formulaItems[i].extraSauces
          );
          if (k !== -1) {
            if (state.data.formulaItems[i].extraSauces[k].quantity === 1) {
              state.data.formulaItems[i].extraSauces = state.data.formulaItems[
                i
              ].extraSauces.filter(
                (item) => item.ingredient.id !== action.payload.ingredient.id
              );
            } else if (state.data.formulaItems[i].extraSauces[k].quantity > 1) {
              state.data.formulaItems[i].extraSauces[k].quantity--;
              state.data.formulaItems[i].extraSauces[k].itemTotal -=
                state.data.formulaItems[i].extraSauces[k].ingredient.price;
            }
            state.data.formulaItems[i].supplementsTotal -=
              action.payload.ingredient.price;
            state.data.formulaItems[i].formulaTotal -=
              action.payload.ingredient.price;
            state.data.cartTotal -=
              action.payload.ingredient.price *
              state.data.formulaItems[i].quantity *
              100;
            state.data.formulaItems[i].caloriesTotal -=
              action.payload.ingredient.calories;
            return;
          }
        }
        if (state.data.formulaItems[i].sauces[j].quantity === 1) {
          state.data.formulaItems[i].sauces = state.data.formulaItems[
            i
          ].sauces.filter(
            (item) => item.ingredient.id !== action.payload.ingredient.id
          );
          if (state.data.formulaItems[i].extraSauces.length > 0) {
            if (state.data.formulaItems[i].extraSauces[0].quantity === 1) {
              const object = state.data.formulaItems[i].extraSauces[0];
              state.data.cartTotal -=
                object.ingredient.price *
                state.data.formulaItems[i].quantity *
                100;
              state.data.formulaItems[i].sauces.push({
                ingredient: object.ingredient,
                quantity: 1,
                itemTotal:
                  object.ingredient.price + object.ingredient.premium_price,
                is_warm: object.is_warm,
              });
              state.data.formulaItems[i].extraSauces = state.data.formulaItems[
                i
              ].extraSauces.filter(
                (item) => item.ingredient.id !== object.ingredient.id
              );
              state.data.formulaItems[i].supplementsTotal -=
                object.ingredient.price;
              state.data.formulaItems[i].formulaTotal -=
                object.ingredient.price;
              state.data.formulaItems[i].formulaTotal +=
                object.ingredient.premium_price;
            } else {
              const object = state.data.formulaItems[i].extraSauces[0];
              state.data.cartTotal -=
                object.ingredient.price *
                state.data.formulaItems[i].quantity *
                100;
              state.data.formulaItems[i].sauces.push({
                ingredient: object.ingredient,
                itemTotal: object.ingredient.price,
                quantity: 1,
              });
              state.data.formulaItems[i].extraSauces[0].quantity--;
              state.data.formulaItems[i].supplementsTotal -=
                object.ingredient.price;
              state.data.formulaItems[i].formulaTotal -=
                object.ingredient.price;
            }
          }
        } else {
          state.data.formulaItems[i].sauces[j].quantity--;
          state.data.formulaItems[i].bases[j].itemTotal -=
            action.payload.ingredient.price -
            action.payload.ingredient.premium_price;
        }
        state.data.formulaItems[i].caloriesTotal -=
          action.payload.ingredient.calories;
        if (action.payload.ingredient.premium_price > 0) {
          state.data.cartTotal -= action.payload.ingredient.premium_price * 100;
          state.data.formulaItems[i].formulaTotal -=
            action.payload.ingredient.premium_price;
        }
        return;
      } else if (
        cartContainsItem(
          action.payload.ingredient,
          state.data.formulaItems[i].extraSauces
        ) !== -1
      ) {
        const j = cartContainsItem(
          action.payload.ingredient,
          state.data.formulaItems[i].extraSauces
        );
        if (j < 0) return;
        state.data.formulaItems[i].caloriesTotal -=
          action.payload.ingredient.calories;
        state.data.formulaItems[i].supplementsTotal -=
          action.payload.ingredient.price;
        state.data.formulaItems[i].formulaTotal -=
          action.payload.ingredient.price;
        state.data.cartTotal -=
          action.payload.ingredient.price *
          state.data.formulaItems[i].quantity *
          100;
        if (state.data.formulaItems[i].extraSauces[j].quantity === 1)
          state.data.formulaItems[i].extraSauces = state.data.formulaItems[
            i
          ].extraSauces.filter(
            (item) => item.ingredient.id !== action.payload.ingredient.id
          );
        else {
          state.data.formulaItems[i].extraSauces[j].quantity--;
          state.data.formulaItems[i].extraSauces[j].itemTotal -=
            action.payload.ingredient.price;
        }
      }
    },
    removeCoulisFromDessert: (
      state: CartState,
      action: PayloadAction<DessertItemInput>
    ) => {
      let i = -1;
      state.data.customDessertItems.forEach((item, j) => {
        if (j === action.payload.id) {
          i = j;
          return;
        }
      });
      if (i < 0) return;
      if (
        cartContainsItem(
          action.payload.ingredient,
          state.data.customDessertItems[i].coulis
        ) !== -1
      ) {
        const j = cartContainsItem(
          action.payload.ingredient,
          state.data.customDessertItems[i].coulis
        );
        if (j < 0) return;
        if (state.data.customDessertItems[i].extraCoulis.length > 0) {
          const k = cartContainsItem(
            action.payload.ingredient,
            state.data.customDessertItems[i].extraCoulis
          );
          if (k !== -1) {
            if (
              state.data.customDessertItems[i].extraCoulis[k].quantity === 1
            ) {
              state.data.customDessertItems[i].extraCoulis =
                state.data.customDessertItems[i].extraCoulis.filter(
                  (item) => item.ingredient.id !== action.payload.ingredient.id
                );
            } else if (
              state.data.customDessertItems[i].extraCoulis[k].quantity > 1
            ) {
              state.data.customDessertItems[i].extraCoulis[k].quantity--;
              state.data.customDessertItems[i].extraCoulis[k].itemTotal -=
                state.data.customDessertItems[i].extraCoulis[
                  k
                ].ingredient.price;
            }
            state.data.customDessertItems[i].supplementsTotal -=
              action.payload.ingredient.price;
            state.data.customDessertItems[i].formulaTotal -=
              action.payload.ingredient.price;
            state.data.cartTotal -=
              action.payload.ingredient.price *
              state.data.customDessertItems[i].quantity *
              100;
            return;
          }
        }
        if (state.data.customDessertItems[i].coulis[j].quantity === 1) {
          state.data.customDessertItems[i].coulis =
            state.data.customDessertItems[i].coulis.filter(
              (item) => item.ingredient.id !== action.payload.ingredient.id
            );
          if (state.data.customDessertItems[i].extraCoulis.length > 0) {
            if (
              state.data.customDessertItems[i].extraCoulis[0].quantity === 1
            ) {
              const object = state.data.customDessertItems[i].extraCoulis[0];
              state.data.cartTotal -=
                object.ingredient.price *
                state.data.customDessertItems[i].quantity *
                100;
              state.data.customDessertItems[i].coulis.push({
                ingredient: object.ingredient,
                quantity: 1,
                itemTotal:
                  object.ingredient.price + object.ingredient.premium_price,
                is_warm: object.is_warm,
              });
              state.data.customDessertItems[i].extraCoulis =
                state.data.customDessertItems[i].extraCoulis.filter(
                  (item) => item.ingredient.id !== object.ingredient.id
                );
              state.data.customDessertItems[i].supplementsTotal -=
                object.ingredient.price;
              state.data.customDessertItems[i].formulaTotal -=
                object.ingredient.price;
              state.data.customDessertItems[i].formulaTotal +=
                object.ingredient.premium_price;
            } else {
              const object = state.data.customDessertItems[i].extraCoulis[0];
              state.data.cartTotal -=
                object.ingredient.price *
                state.data.customDessertItems[i].quantity *
                100;
              state.data.customDessertItems[i].coulis.push({
                ingredient: object.ingredient,
                itemTotal: object.ingredient.price,
                quantity: 1,
              });
              state.data.customDessertItems[i].extraCoulis[0].quantity--;
              state.data.customDessertItems[i].supplementsTotal -=
                object.ingredient.price;
              state.data.customDessertItems[i].formulaTotal -=
                object.ingredient.price;
            }
          }
        } else {
          state.data.customDessertItems[i].coulis[j].quantity--;
          state.data.customDessertItems[i].coulis[j].itemTotal -=
            action.payload.ingredient.price -
            action.payload.ingredient.premium_price;
        }
        if (action.payload.ingredient.premium_price > 0) {
          state.data.cartTotal -= action.payload.ingredient.premium_price * 100;
          state.data.customDessertItems[i].formulaTotal -=
            action.payload.ingredient.premium_price;
        }
        return;
      } else if (
        cartContainsItem(
          action.payload.ingredient,
          state.data.customDessertItems[i].extraCoulis
        ) !== -1
      ) {
        const j = cartContainsItem(
          action.payload.ingredient,
          state.data.customDessertItems[i].extraCoulis
        );
        if (j < 0) return;
        state.data.customDessertItems[i].supplementsTotal -=
          action.payload.ingredient.price;
        state.data.customDessertItems[i].formulaTotal -=
          action.payload.ingredient.price;
        state.data.cartTotal -=
          action.payload.ingredient.price *
          state.data.customDessertItems[i].quantity *
          100;
        if (state.data.customDessertItems[i].extraCoulis[j].quantity === 1)
          state.data.customDessertItems[i].extraCoulis =
            state.data.customDessertItems[i].extraCoulis.filter(
              (item) => item.ingredient.id !== action.payload.ingredient.id
            );
        else {
          state.data.customDessertItems[i].extraCoulis[j].quantity--;
          state.data.customDessertItems[i].extraCoulis[j].itemTotal -=
            action.payload.ingredient.price;
        }
      }
    },
    removeToppingFromDessert: (
      state: CartState,
      action: PayloadAction<DessertItemInput>
    ) => {
      let i = -1;
      state.data.customDessertItems.forEach((item, j) => {
        if (j === action.payload.id) {
          i = j;
          return;
        }
      });
      if (i < 0) return;
      if (
        cartContainsItem(
          action.payload.ingredient,
          state.data.customDessertItems[i].toppings
        ) !== -1
      ) {
        const j = cartContainsItem(
          action.payload.ingredient,
          state.data.customDessertItems[i].toppings
        );
        if (j < 0) return;
        if (state.data.customDessertItems[i].extraToppings.length > 0) {
          const k = cartContainsItem(
            action.payload.ingredient,
            state.data.customDessertItems[i].extraToppings
          );
          if (k !== -1) {
            if (
              state.data.customDessertItems[i].extraToppings[k].quantity === 1
            ) {
              state.data.customDessertItems[i].extraToppings =
                state.data.customDessertItems[i].extraToppings.filter(
                  (item) => item.ingredient.id !== action.payload.ingredient.id
                );
            } else if (
              state.data.customDessertItems[i].extraToppings[k].quantity > 1
            ) {
              state.data.customDessertItems[i].extraToppings[k].quantity--;
              state.data.customDessertItems[i].extraToppings[k].itemTotal -=
                state.data.customDessertItems[i].extraToppings[
                  k
                ].ingredient.price;
            }
            state.data.customDessertItems[i].supplementsTotal -=
              action.payload.ingredient.price;
            state.data.customDessertItems[i].formulaTotal -=
              action.payload.ingredient.price;
            state.data.cartTotal -=
              action.payload.ingredient.price *
              state.data.customDessertItems[i].quantity *
              100;
            return;
          }
        }
        if (state.data.customDessertItems[i].toppings[j].quantity === 1) {
          state.data.customDessertItems[i].toppings =
            state.data.customDessertItems[i].toppings.filter(
              (item) => item.ingredient.id !== action.payload.ingredient.id
            );
          if (state.data.customDessertItems[i].extraToppings.length > 0) {
            if (
              state.data.customDessertItems[i].extraToppings[0].quantity === 1
            ) {
              const object = state.data.customDessertItems[i].extraToppings[0];
              state.data.cartTotal -=
                object.ingredient.price *
                state.data.customDessertItems[i].quantity *
                100;
              state.data.customDessertItems[i].toppings.push({
                ingredient: object.ingredient,
                quantity: 1,
                itemTotal:
                  object.ingredient.price + object.ingredient.premium_price,
                is_warm: object.is_warm,
              });
              state.data.customDessertItems[i].extraToppings =
                state.data.customDessertItems[i].extraToppings.filter(
                  (item) => item.ingredient.id !== object.ingredient.id
                );
              state.data.customDessertItems[i].supplementsTotal -=
                object.ingredient.price;
              state.data.customDessertItems[i].formulaTotal -=
                object.ingredient.price;
              state.data.customDessertItems[i].formulaTotal +=
                object.ingredient.premium_price;
            } else {
              const object = state.data.customDessertItems[i].extraToppings[0];
              state.data.cartTotal -=
                object.ingredient.price *
                state.data.customDessertItems[i].quantity *
                100;
              state.data.customDessertItems[i].toppings.push({
                ingredient: object.ingredient,
                itemTotal: object.ingredient.price,
                quantity: 1,
              });
              state.data.customDessertItems[i].extraToppings[0].quantity--;
              state.data.customDessertItems[i].supplementsTotal -=
                object.ingredient.price;
              state.data.customDessertItems[i].formulaTotal -=
                object.ingredient.price;
            }
          }
        } else {
          state.data.customDessertItems[i].toppings[j].quantity--;
          state.data.customDessertItems[i].toppings[j].itemTotal -=
            action.payload.ingredient.price -
            action.payload.ingredient.premium_price;
        }
        if (action.payload.ingredient.premium_price > 0) {
          state.data.cartTotal -= action.payload.ingredient.premium_price * 100;
          state.data.customDessertItems[i].formulaTotal -=
            action.payload.ingredient.premium_price;
        }
        return;
      } else if (
        cartContainsItem(
          action.payload.ingredient,
          state.data.customDessertItems[i].extraToppings
        ) !== -1
      ) {
        const j = cartContainsItem(
          action.payload.ingredient,
          state.data.customDessertItems[i].extraToppings
        );
        if (j < 0) return;
        state.data.customDessertItems[i].supplementsTotal -=
          action.payload.ingredient.price;
        state.data.customDessertItems[i].formulaTotal -=
          action.payload.ingredient.price;
        state.data.cartTotal -=
          action.payload.ingredient.price *
          state.data.customDessertItems[i].quantity *
          100;
        if (state.data.customDessertItems[i].extraToppings[j].quantity === 1)
          state.data.customDessertItems[i].extraToppings =
            state.data.customDessertItems[i].extraToppings.filter(
              (item) => item.ingredient.id !== action.payload.ingredient.id
            );
        else {
          state.data.customDessertItems[i].extraToppings[j].quantity--;
          state.data.customDessertItems[i].extraToppings[j].itemTotal -=
            action.payload.ingredient.price;
        }
      }
    },
    removeSide1FromCartFormula: (
      state: CartState,
      action: PayloadAction<FormulaItemInput>
    ) => {
      let i = -1;
      state.data.formulaItems.forEach((item, j) => {
        if (j === action.payload.id) {
          i = j;
          return;
        }
      });
      if (i < 0) return;
      if (
        cartContainsItem(
          action.payload.ingredient,
          state.data.formulaItems[i].sides1
        ) !== -1
      ) {
        const j = cartContainsItem(
          action.payload.ingredient,
          state.data.formulaItems[i].sides1
        );
        if (j < 0) return;
        if (state.data.formulaItems[i].extraSides1.length > 0) {
          const k = cartContainsItem(
            action.payload.ingredient,
            state.data.formulaItems[i].extraSides1
          );
          if (k !== -1) {
            if (state.data.formulaItems[i].extraSides1[k].quantity === 1) {
              state.data.formulaItems[i].extraSides1 = state.data.formulaItems[
                i
              ].extraSides1.filter(
                (item) => item.ingredient.id !== action.payload.ingredient.id
              );
            } else if (state.data.formulaItems[i].extraSides1[k].quantity > 1) {
              state.data.formulaItems[i].extraSides1[k].quantity--;
              state.data.formulaItems[i].extraSides1[k].itemTotal -=
                state.data.formulaItems[i].extraSides1[k].ingredient.price;
            }
            state.data.formulaItems[i].supplementsTotal -=
              action.payload.ingredient.price;
            state.data.formulaItems[i].formulaTotal -=
              action.payload.ingredient.price;
            state.data.cartTotal -=
              action.payload.ingredient.price *
              state.data.formulaItems[i].quantity *
              100;
            state.data.formulaItems[i].caloriesTotal -=
              action.payload.ingredient.calories;
            return;
          }
        }
        if (state.data.formulaItems[i].sides1[j].quantity === 1) {
          state.data.formulaItems[i].sides1 = state.data.formulaItems[
            i
          ].sides1.filter(
            (item) => item.ingredient.id !== action.payload.ingredient.id
          );
          if (state.data.formulaItems[i].extraSides1.length > 0) {
            if (state.data.formulaItems[i].extraSides1[0].quantity === 1) {
              const object = state.data.formulaItems[i].extraSides1[0];
              state.data.cartTotal -=
                object.ingredient.price *
                state.data.formulaItems[i].quantity *
                100;
              state.data.formulaItems[i].sides1.push({
                ingredient: object.ingredient,
                quantity: 1,
                itemTotal:
                  object.ingredient.price + object.ingredient.premium_price,
                is_warm: object.is_warm,
              });
              state.data.formulaItems[i].extraSides1 = state.data.formulaItems[
                i
              ].extraSides1.filter(
                (item) => item.ingredient.id !== object.ingredient.id
              );
              state.data.formulaItems[i].supplementsTotal -=
                object.ingredient.price;
              state.data.formulaItems[i].formulaTotal -=
                object.ingredient.price;
              state.data.formulaItems[i].formulaTotal +=
                object.ingredient.premium_price;
            } else {
              const object = state.data.formulaItems[i].extraSides1[0];
              state.data.cartTotal -=
                object.ingredient.price *
                state.data.formulaItems[i].quantity *
                100;
              state.data.formulaItems[i].sides1.push({
                ingredient: object.ingredient,
                itemTotal: object.ingredient.price,
                quantity: 1,
              });
              state.data.formulaItems[i].extraSides1[0].quantity--;
              state.data.formulaItems[i].supplementsTotal -=
                object.ingredient.price;
              state.data.formulaItems[i].formulaTotal -=
                object.ingredient.price;
            }
          }
        } else {
          state.data.formulaItems[i].sides1[j].quantity--;
          state.data.formulaItems[i].bases[j].itemTotal -=
            action.payload.ingredient.price -
            action.payload.ingredient.premium_price;
        }
        state.data.formulaItems[i].caloriesTotal -=
          action.payload.ingredient.calories;
        if (action.payload.ingredient.premium_price > 0) {
          state.data.cartTotal -= action.payload.ingredient.premium_price * 100;
          state.data.formulaItems[i].formulaTotal -=
            action.payload.ingredient.premium_price;
        }
        return;
      } else if (
        cartContainsItem(
          action.payload.ingredient,
          state.data.formulaItems[i].extraSides1
        ) !== -1
      ) {
        const j = cartContainsItem(
          action.payload.ingredient,
          state.data.formulaItems[i].extraSides1
        );
        if (j < 0) return;
        state.data.formulaItems[i].caloriesTotal -=
          action.payload.ingredient.calories;
        state.data.formulaItems[i].supplementsTotal -=
          action.payload.ingredient.price;
        state.data.formulaItems[i].formulaTotal -=
          action.payload.ingredient.price;
        state.data.cartTotal -=
          action.payload.ingredient.price *
          state.data.formulaItems[i].quantity *
          100;
        if (state.data.formulaItems[i].extraSides1[j].quantity === 1)
          state.data.formulaItems[i].extraSides1 = state.data.formulaItems[
            i
          ].extraSides1.filter(
            (item) => item.ingredient.id !== action.payload.ingredient.id
          );
        else {
          state.data.formulaItems[i].extraSides1[j].quantity--;
          state.data.formulaItems[i].extraSides1[j].itemTotal -=
            action.payload.ingredient.price;
        }
      }
    },

    removeSide2FromCartFormula: (
      state: CartState,
      action: PayloadAction<FormulaItemInput>
    ) => {
      let i = -1;
      state.data.formulaItems.forEach((item, j) => {
        if (j === action.payload.id) {
          i = j;
          return;
        }
      });
      if (i < 0) return;
      if (
        cartContainsItem(
          action.payload.ingredient,
          state.data.formulaItems[i].sides2
        ) !== -1
      ) {
        const j = cartContainsItem(
          action.payload.ingredient,
          state.data.formulaItems[i].sides2
        );
        if (j < 0) return;
        if (state.data.formulaItems[i].extraSides2.length > 0) {
          const k = cartContainsItem(
            action.payload.ingredient,
            state.data.formulaItems[i].extraSides2
          );
          if (k !== -1) {
            if (state.data.formulaItems[i].extraSides2[k].quantity === 1) {
              state.data.formulaItems[i].extraSides2 = state.data.formulaItems[
                i
              ].extraSides2.filter(
                (item) => item.ingredient.id !== action.payload.ingredient.id
              );
            } else if (state.data.formulaItems[i].extraSides2[k].quantity > 1) {
              state.data.formulaItems[i].extraSides2[k].quantity--;
              state.data.formulaItems[i].extraSides2[k].itemTotal -=
                state.data.formulaItems[i].extraSides2[k].ingredient.price;
            }
            state.data.formulaItems[i].supplementsTotal -=
              action.payload.ingredient.price;
            state.data.formulaItems[i].formulaTotal -=
              action.payload.ingredient.price;
            state.data.cartTotal -=
              action.payload.ingredient.price *
              state.data.formulaItems[i].quantity *
              100;
            state.data.formulaItems[i].caloriesTotal -=
              action.payload.ingredient.calories;
            return;
          }
        }
        if (state.data.formulaItems[i].sides2[j].quantity === 1) {
          state.data.formulaItems[i].sides2 = state.data.formulaItems[
            i
          ].sides2.filter(
            (item) => item.ingredient.id !== action.payload.ingredient.id
          );
          if (state.data.formulaItems[i].extraSides2.length > 0) {
            if (state.data.formulaItems[i].extraSides2[0].quantity === 1) {
              const object = state.data.formulaItems[i].extraSides2[0];
              state.data.cartTotal -=
                object.ingredient.price *
                state.data.formulaItems[i].quantity *
                100;
              state.data.formulaItems[i].sides2.push({
                ingredient: object.ingredient,
                quantity: 1,
                itemTotal:
                  object.ingredient.price + object.ingredient.premium_price,
                is_warm: object.is_warm,
              });
              state.data.formulaItems[i].extraSides2 = state.data.formulaItems[
                i
              ].extraSides2.filter(
                (item) => item.ingredient.id !== object.ingredient.id
              );
              state.data.formulaItems[i].supplementsTotal -=
                object.ingredient.price;
              state.data.formulaItems[i].formulaTotal -=
                object.ingredient.price;
              state.data.formulaItems[i].formulaTotal +=
                object.ingredient.premium_price;
            } else {
              const object = state.data.formulaItems[i].extraSides2[0];
              state.data.cartTotal -=
                object.ingredient.price *
                state.data.formulaItems[i].quantity *
                100;
              state.data.formulaItems[i].sides2.push({
                ingredient: object.ingredient,
                itemTotal: object.ingredient.price,
                quantity: 1,
              });
              state.data.formulaItems[i].extraSides2[0].quantity--;
              state.data.formulaItems[i].supplementsTotal -=
                object.ingredient.price;
              state.data.formulaItems[i].formulaTotal -=
                object.ingredient.price;
            }
          }
        } else {
          state.data.formulaItems[i].sides2[j].quantity--;
          state.data.formulaItems[i].bases[j].itemTotal -=
            action.payload.ingredient.price -
            action.payload.ingredient.premium_price;
        }
        state.data.formulaItems[i].caloriesTotal -=
          action.payload.ingredient.calories;
        if (action.payload.ingredient.premium_price > 0) {
          state.data.cartTotal -= action.payload.ingredient.premium_price * 100;
          state.data.formulaItems[i].formulaTotal -=
            action.payload.ingredient.premium_price;
        }
        return;
      } else if (
        cartContainsItem(
          action.payload.ingredient,
          state.data.formulaItems[i].extraSides2
        ) !== -1
      ) {
        const j = cartContainsItem(
          action.payload.ingredient,
          state.data.formulaItems[i].extraSides2
        );
        if (j < 0) return;
        state.data.formulaItems[i].caloriesTotal -=
          action.payload.ingredient.calories;
        state.data.formulaItems[i].supplementsTotal -=
          action.payload.ingredient.price;
        state.data.formulaItems[i].formulaTotal -=
          action.payload.ingredient.price;
        state.data.cartTotal -=
          action.payload.ingredient.price *
          state.data.formulaItems[i].quantity *
          100;
        if (state.data.formulaItems[i].extraSides2[j].quantity === 1)
          state.data.formulaItems[i].extraSides2 = state.data.formulaItems[
            i
          ].extraSides2.filter(
            (item) => item.ingredient.id !== action.payload.ingredient.id
          );
        else {
          state.data.formulaItems[i].extraSides2[j].quantity--;
          state.data.formulaItems[i].extraSides2[j].itemTotal -=
            action.payload.ingredient.price;
        }
      }
    },

    removeFormulaFromCart: (
      state: CartState,
      action: PayloadAction<{ formula: Formula; id: number }>
    ) => {
      const id = action.payload.id;
      let i = -1;
      if (action.payload.formula.is_paid_with_eatfid_points)
        state.data.formulaItems.forEach((item, j) => {
          if (
            item.formula.id === action.payload.formula.id &&
            item.is_paid_with_eatfid_points
          )
            i = j;
        });
      else
        state.data.formulaItems.forEach((item, j) => {
          if (id === j) {
            i = j;
          }
        });
      if (i < 0) return;

      state.data.cartTotal -= state.data.formulaItems[i].formulaTotal * 100;
      if (action.payload.formula.points)
        state.data.eatfid_points_cost -= action.payload.formula.points;
      if (state.data.formulaItems[i].quantity > 1)
        state.data.formulaItems[i].quantity--;
      else {
        if (action.payload.formula.points) state.data.formulaItems.splice(i, 1);
        else
          state.data.formulaItems = state.data.formulaItems.filter(
            (formula, j) => j !== id
          );
      }
    },
    deleteSignatureSaladFromCart: (
      state: CartState,
      action: PayloadAction<SignatureSalad>
    ) => {
      const i = cartContainsSignature(action.payload, state.data.signatures);
      if (i < 0) return;
      state.data.cartTotal -=
        state.data.signatures[i].ingredient.price *
        100 *
        state.data.signatures[i].quantity;
      state.data.signatures.splice(i, 1);
    },

    deleteFormulaFromCart: (
      state: CartState,
      action: PayloadAction<number>
    ) => {
      const id = action.payload;
      let i = -1;
      state.data.formulaItems.forEach((item, j) => {
        if (id === j) i = j;
      });
      if (i < 0) return;
      state.data.cartTotal -=
        state.data.formulaItems[i].formulaTotal *
        100 *
        state.data.formulaItems[i].quantity;
      state.data.formulaItems = state.data.formulaItems.filter(
        (formula, j) => j !== action.payload
      );
    },

    deleteDessertFromCart: (
      state: CartState,
      action: PayloadAction<number>
    ) => {
      const id = action.payload;
      let i = -1;
      state.data.customDessertItems.forEach((item, j) => {
        if (id === j) i = j;
      });

      if (i < 0) return;
      state.data.cartTotal -=
        100 * state.data.customDessertItems[i].formulaTotal;
      if (state.data.customDessertItems[i].quantity > 1)
        state.data.customDessertItems[i].quantity--;
      else
        state.data.customDessertItems = state.data.customDessertItems.filter(
          (formula, j) => j !== action.payload
        );
    },

    deleteCustomFormulaFromCart: (
      state: CartState,
      action: PayloadAction<number>
    ) => {
      const id = action.payload;
      let i = -1;
      state.data.customFormulaItems.forEach((item, j) => {
        if (id === j) i = j;
      });
      if (i < 0) return;
      state.data.cartTotal -=
        state.data.customFormulaItems[i].formulaTotal *
        100 *
        state.data.customFormulaItems[i].quantity;
      state.data.customFormulaItems = state.data.customFormulaItems.filter(
        (formula, j) => j !== action.payload
      );
    },

    deleteItemFromCart: (
      state: CartState,
      action: PayloadAction<Ingredient>
    ) => {
      const i = cartContainsItem(action.payload, state.data.items);
      if (i < 0) return;
      state.data.cartTotal -=
        state.data.items[i].ingredient.price *
        100 *
        state.data.items[i].quantity;
      state.data.items = state.data.items.filter(
        (item) => item.ingredient.id !== action.payload.id
      );
    },

    removeEatfidProductsFromCart: (state: CartState) => {
      state.data.items = state.data.items.filter(
        (item) => item.is_paid_with_eatfid_points !== true
      );
      state.data.formulaItems = state.data.formulaItems.filter(
        (formula) => formula.is_paid_with_eatfid_points !== true
      );
      state.data.eatfid_points_cost = 0;
    },

    clearCart: (state: CartState) => {
      state.data.items = [];
      state.data.formulaItems = [];
      state.data.customFormulaItems = [];
      state.data.cartTotal = 0;
      state.data.discount = 0;
      state.data.promoCode = "";
      state.data.eatfid_points_cost = 0;
      state.data.suggested_count = [];
      state.data.signatures = [];
      state.data.customDessertItems = [];
    },

    removeAlcoholicBeverages: (state: CartState) => {
      let flag = false;
      if (state.data.formulaItems.length > 0) {
        state.data.formulaItems.forEach((formula) => {
          if (formula.completed && !flag) {
            flag = true;
          }
        });
        if (flag) return;
      }
      if (state.data.customFormulaItems.length > 0) {
        state.data.customFormulaItems.forEach((formula) => {
          if (formula.bases.length > 0 && !flag) {
            flag = true;
          }
        });
        if (flag) return;
      }

      if (state.data.signatures.length > 0 && !flag) {
        flag = true;
        if (flag) return;
      }
      if (state.data.items.length > 0) {
        state.data.items.forEach((item) => {
          if (
            (item.ingredient.category.slug === "base" ||
              item.ingredient.category.id === 16) &&
            !flag
          ) {
            flag = true;
          }
        });
        if (flag) return;
      }
      state.data.items.forEach((item) => {
        if (item.ingredient.category.id === 15)
          state.data.cartTotal -= item.ingredient.price * item.quantity * 100;
      });
      state.data.items = state.data.items.filter(
        (item) => item.ingredient.category.id !== 15
      );
    },

    setDiscountPrice: (
      state: CartState,
      action: PayloadAction<PromoCodePayload>
    ) => {
      state.data.discount = action.payload.discount;
      state.data.promoCode = action.payload.promoCode;
    },
  },
});

export const {
  addItemToCart,
  addSignatureSaladToCart,
  removeItemFromCart,
  addNewFormulaToCart,
  addRecipeIdeaAsFormulaToCart,
  addFormulaToCart,
  addBaseToCartFormula,
  addIngredientToCartFormula,
  addSauceToCartFormula,
  addSide1ToCartFormula,
  addSide2ToCartFormula,
  addDessertToFormula,
  addDessertToCart,
  addCoulisToDessert,
  addToppingToDessert,
  addNewDessertToCart,
  removeFormulaFromCart,
  removeSauceFromSignature,
  removeSignatureSaladFromCart,
  deleteFormulaFromCart,
  deleteItemFromCart,
  deleteCustomFormulaFromCart,
  deleteSignatureSaladFromCart,
  deleteDessertFromCart,
  removeBaseFromCartFormula,
  removeCoulisFromDessert,
  removeToppingFromDessert,
  removeIngredientFromCartFormula,
  removeSauceFromCartFormula,
  removeSide1FromCartFormula,
  removeSide2FromCartFormula,
  removeToppingsFromDessert,
  clearCart,
  removeEatfidProductsFromCart,
  setItemComplete,
  setDessertComplete,
  addRecipeIdeaAsCustomFormulaToCart,
  addCustomFormulaToCart,
  addBaseToCartCustomFormula,
  addIngredientToCartCustomFormula,
  addSauceToCartCustomFormula,
  addSide1ToCartCustomFormula,
  addSide2ToCartCustomFormula,
  removeBaseFromCartCustomFormula,
  removeIngredientFromCartCustomFormula,
  removeSauceFromCartCustomFromula,
  removeSide1FromCartCustomFormula,
  removeSide2FromCartCustomFormula,
  removeDessertFromFormula,
  removeCustomFormulaFromCart,
  removeAlcoholicBeverages,
  setDiscountPrice,
} = cartSlice.actions;

export const selectCart = (state: RootState) => state.cart.data;

export default cartSlice.reducer;
