import Image from "next/image";

import imageLoader from "../../../../../utils/loader";
import classes from "./socialItem.module.scss";

interface Props {
  src: string;
  link: string;
  alt: string;
}

const SocialItem = ({ src, alt, link }: Props) => {
  return (
    <div className={classes.item}>
      <a href={link} target="_blank" rel="noreferrer">
        <Image
          layout="fixed"
          loader={imageLoader}
          width={"40px"}
          height={"40px"}
          src={src}
          alt={alt}
        />
      </a>
    </div>
  );
};

export default SocialItem;
