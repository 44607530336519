import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  DessertCategory,
  Ingredient,
  MainCategory,
  RestaurantClosingRulesType,
  RestaurantOpeningHoursType,
} from "../../api/types/restaurant.type";
import type { RootState } from "../store";

export interface PaymentOption {
  name: string;
}
interface SelectRestaurantPayloadInput {
  id: number;
  name: string;
  address: string;
  mainCategories?: MainCategory[];
  openingHours: RestaurantOpeningHoursType[];
  closingRules: RestaurantClosingRulesType[];
  payment_options?: PaymentOption[];
  cutleryIngredient?: Ingredient;
}

export type RestaurantState = {
  data: {
    id: number;
    name: string;
    address: string;
    selected: boolean;
    date: string;
    time: string;
    mainCategories: MainCategory[];
    dessertCategories: DessertCategory[];
    openingHours: RestaurantOpeningHoursType[];
    closingRules: RestaurantClosingRulesType[];
    payment_options: PaymentOption[];
    cutleryIngredient?: Ingredient;
  };
};

const initialState: RestaurantState = {
  data: {
    id: -1,
    name: "",
    address: "",
    selected: false,
    date: "dynamic",
    time: "",
    mainCategories: [],
    dessertCategories: [],
    openingHours: [],
    closingRules: [],
    payment_options: [],
  },
};

export const restaurantSlice = createSlice({
  name: "restaurant",
  initialState,
  reducers: {
    setRestaurantInfo: (
      state: RestaurantState,
      action: PayloadAction<SelectRestaurantPayloadInput>
    ) => {
      state.data.id = action.payload.id;
      state.data.name = action.payload.name;
      state.data.address = action.payload.address;
      if (action.payload.mainCategories) {
        state.data.mainCategories = action.payload.mainCategories;
      }
      if (action.payload.openingHours) {
        state.data.openingHours = action.payload.openingHours;
      }
      if (action.payload.closingRules) {
        state.data.closingRules = action.payload.closingRules;
      }
      if (action.payload.payment_options)
        state.data.payment_options = action.payload.payment_options;
      if (action.payload.cutleryIngredient)
        state.data.cutleryIngredient = action.payload.cutleryIngredient;
      state.data.selected = true;
    },
    setRestaurantDate: (
      state: RestaurantState,
      action: PayloadAction<string>
    ) => {
      state.data.date = action.payload;
    },
    setRestaurantTime: (
      state: RestaurantState,
      action: PayloadAction<string>
    ) => {
      state.data.time = action.payload;
    },
    setRestaurantCategories: (
      state: RestaurantState,
      action: PayloadAction<{
        mainCategories: MainCategory[];
        dessertCategories: DessertCategory[];
      }>
    ) => {
      state.data.mainCategories = action.payload.mainCategories;
      state.data.dessertCategories = action.payload.dessertCategories;
    },
    setRestaurantPaymentOptions: (
      state: RestaurantState,
      action: PayloadAction<PaymentOption[]>
    ) => {
      state.data.payment_options = action.payload;
    },
    setRestaurantCutleryIngredient: (
      state: RestaurantState,
      action: PayloadAction<Ingredient>
    ) => {
      state.data.cutleryIngredient = action.payload;
    },
    resetRestaurantInfo: (state: RestaurantState) => {
      state.data = initialState.data;
    },
  },
});

export const {
  setRestaurantInfo,
  setRestaurantDate,
  setRestaurantTime,
  setRestaurantPaymentOptions,
  resetRestaurantInfo,
  setRestaurantCategories,
  setRestaurantCutleryIngredient,
} = restaurantSlice.actions;

export const selectRestaurant = (state: RootState) => state.restaurant.data;

export default restaurantSlice.reducer;
