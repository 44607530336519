import { createContext, useContext } from "react";

import { MapType } from "../api/types/map.type";

export const mapObject: MapType = {
  id: -1,
  lat: 48.8566969,
  lng: 2.3514616,
};

export type MapContextType = {
  map: MapType;
  setMap: React.Dispatch<React.SetStateAction<MapType>>;
};

export const MapContext = createContext<MapContextType>({
  map: mapObject,
  setMap: () => {},
});

export const useMap = () => useContext(MapContext);
