import { useRouter } from "next/router";
import { useState } from "react";

import { translationsContent } from "../../../localization/translations";
import { useLanguage } from "../../../store/lang.context";
import { translate } from "../../../utils/translate";
import LanguageSelect from "../../LanguageSelect";
import Columns from "./Columns";
import classes from "./footer.module.scss";

const Footer = () => {
  const { language } = useLanguage();
  const [isModalOpen, setOpenModal] = useState(false);
  const router = useRouter();

  const handleLanguage = () => {
    switch (language) {
      case "fr":
        return "Français";
      case "en":
        return "English";
      case "es":
        return "Español";
      case "nl":
        return "Nederlands";
    }
  };

  const handleRouting = () => {
    switch (language) {
      case "fr":
        router.push("/");
        break;
      case "en":
        router.push("/en/");
        break;
      case "es":
        router.push("/es/");
        break;
      case "nl":
        router.push("/nl/");
        break;
    }
  };

  const handleCountry = () => {
    if (typeof window !== "undefined" && localStorage.getItem("country"))
      return localStorage.getItem("country") as string;
  };

  return (
    <footer className={classes.footer}>
      <div className={classes.FooterNote}>
        {translate(translationsContent, "footerNote", language)}
      </div>
      <Columns />
      <div className={classes.midContainer}>
        <p>© {translate(translationsContent, "footerRights", language)}</p>
      </div>
      <div className={classes.bottomContainer}>
        <p
          onClick={() => {
            setOpenModal(true);
          }}
        >
          {handleCountry()} / {handleLanguage()}
        </p>
      </div>
      {isModalOpen && <LanguageSelect setModalIsOpen={setOpenModal} />}
    </footer>
  );
};

export default Footer;
