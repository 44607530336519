import {
  FormulaItem,
  Item,
  SignatureSaladItem,
} from "../../../redux/slices/cartSlice";
import {
  Formula,
  Ingredient,
  SignatureSalad,
} from "../../types/restaurant.type";

export const itemAndElemAreBothEatfidOrNot = (item: Ingredient, elem: Item) => {
  return (
    (item.points && elem.is_paid_with_eatfid_points) ||
    (!item.points && !elem.is_paid_with_eatfid_points)
  );
};

export const itemsAreBothEatFidOrNot = (item1: Item, item2: Ingredient) => {
  const compare1 =
    item1.is_paid_with_eatfid_points || item1.points ? true : false;
  const compare2 =
    item2.is_paid_with_eatfid_points || item2.points ? true : false;
  return compare1 === compare2;
};

export const cartContainsItem = (
  item: Ingredient,
  list: Item[] | SignatureSaladItem[]
) => {
  let i = -1;
  list.forEach((elem, index) => {
    if (
      elem.ingredient.id === item.id &&
      itemAndElemAreBothEatfidOrNot(item, elem)
    ) {
      i = index;
    }
  });
  return i;
};

export const cartContainsSignature = (
  item: SignatureSalad,
  list: SignatureSaladItem[]
) => {
  let i = -1;
  list.forEach((elem, index) => {
    if (
      elem.ingredient.id == item.ingredient.id &&
      (elem.freeSauce === item.freeSauce ||
        elem.freeSauce?.id === item.freeSauce?.id)
    ) {
      i = index;
    }
  });
  return i;
};

export const cartContainsFormula = (formula: Formula, list: FormulaItem[]) => {
  let i = -1;
  list.forEach((elem, index) => {
    if (elem.formula.id === formula.id) i = index;
  });
  return i;
};
