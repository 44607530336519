import dynamic from "next/dynamic";
import { useState } from "react";

import { translationsContent } from "../../../../localization/translations";
import { useLanguage } from "../../../../store/lang.context";
import { translate } from "../../../../utils/translate";
import LegalLink from "../../../ui/links/LegalLink";
import classes from "./legals.module.scss";

const CookiesSettings = dynamic(() => import("./CookiesSettings"), {
  ssr: false,
});

export type TranslationHeader =
  | "footerLegalNoticeHeader"
  | "footerCgvHeader"
  | "footerPrivacyPolicyHeader"
  | "footerLeClubTerms";

const legalsList = [
  { route: "legalNotice", header: "footerLegalNoticeHeader" },
  { route: "cgv", header: "footerCgvHeader" },
  { route: "privacyPolicy", header: "footerPrivacyPolicyHeader" },
  { route: "leClub", header: "footerLeClubTerms" },
];

const Legals = () => {
  const { language } = useLanguage();

  const [cookiesSettingsIsOpen, setCookiesSettingsIsOpen] = useState(false);

  const legalMentionsRouting = () => {
    let route = "";
    if (typeof window !== "undefined") {
      const lang = localStorage.getItem("language");
      switch (lang) {
        case "fr":
          route = "/mentions-legales/";
          break;
        case "en":
          route = "/en/legal-notice/";
          break;
        case "es":
          route = "/es/aviso-legal/";
          break;
        case "nl":
          route = "/nl/wettelijke-bepalingen/";
          break;
      }
    }
    return route;
  };

  const cgvRouting = () => {
    let route = "";
    if (typeof window !== "undefined") {
      const lang = localStorage.getItem("language");
      switch (lang) {
        case "fr":
          route = "/conditions-generales-vente/";
          break;
        case "en":
          route = "/en/terms-and-conditions/";
          break;
        case "es":
          route = "/es/condiciones-generales-venta/";
          break;
        case "nl":
          route = "/nl/algemene-verkoopvoorwaarden/";
          break;
      }
    }
    return route;
  };

  const regulationsRouting = () => {
    let route = "";
    if (typeof window !== "undefined") {
      const lang = localStorage.getItem("language");
      switch (lang) {
        case "fr":
          route = "/reglement-la-fidelite/";
          break;
        case "en":
          route = "/en/regulation-the-fidelity/";
          break;
        case "es":
          route = "/es/reglamento-el-fidelite/";
          break;
        case "nl":
          route = "/nl/reglement-de-fidelite/";
          break;
      }
    }
    return route;
  };

  const personalDataRouting = () => {
    return "https://eatsalad.com/donnees-personnelles/";
  };

  const getRoute = (legal: string) => {
    let route = "";
    if (typeof window !== "undefined") {
      switch (legal) {
        case "legalNotice":
          route = legalMentionsRouting();
          break;
        case "cgv":
          route = cgvRouting();
          break;
        case "privacyPolicy":
          route = personalDataRouting();
          break;
        case "leClub":
          route = regulationsRouting();
          break;
      }
    }
    return route;
  };

  return (
    <div className={classes.container}>
      <div className={classes.column}>
        <h4>{translate(translationsContent, "footerHeader3", language)}</h4>
        <ul>
          {legalsList.map((legal, index) => (
            <li key={`legal-footer-${index}`}>
              <LegalLink header={legal.header} href={getRoute(legal.route)} />
            </li>
          ))}
          <li onClick={() => setCookiesSettingsIsOpen(true)}>
            {translate(
              translationsContent,
              "footerCookieSettingsHeader",
              language
            )}
          </li>
          {/* <li>
            <a
              href="/pdfs/RÉGLEMENT_JEU_CONCOURS_10_ans_Eat_Salad.pdf"
              target="_blank"
              rel="noreferrer"
            >
              {translate(
                translationsContent,
                "footerContestRulesHeader",
                language
              )}
            </a>
          </li> */}
        </ul>
      </div>
      {cookiesSettingsIsOpen && (
        <CookiesSettings setIsOpen={setCookiesSettingsIsOpen} />
      )}
    </div>
  );
};

export default Legals;
