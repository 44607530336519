import React, { createContext, useContext } from "react";

export const popupIsOpenObject = {
  isOpen: false,
  notAvailableIngredients: [],
  onOutsideClose: () => {},
};

export type PopupObject = {
  isOpen: boolean;
  notAvailableIngredients: string[];
  onOutsideClose?: () => void;
};

export type RestaurantChangePopupContextType = {
  popupIsOpen: PopupObject;
  setPopupIsOpen: React.Dispatch<React.SetStateAction<PopupObject>>;
  onOutsideClose?: () => void;
};

export const RestaurantChangePopupContext =
  createContext<RestaurantChangePopupContextType>({
    popupIsOpen: popupIsOpenObject,
    setPopupIsOpen: () => {},
    onOutsideClose: () => {},
  });

export const useRestaurantChangePopup = () =>
  useContext(RestaurantChangePopupContext);
