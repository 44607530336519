//import { useAppDispatch } from "../../redux/hooks";
//import { clearCart } from "../../redux/slices/cartSlice";
import { translationsContent } from "../../localization/translations";
import { useLanguage } from "../../store/lang.context";
import { useRestaurantChangePopup } from "../../store/restaurantChangePopup";
import { translate } from "../../utils/translate";
import Button from "../ui/button/SimpleButton";
import DynamicModal from "../ui/modal/DynamicModal";
import classes from "./popup.module.scss";

interface Props {
  onClose: () => void;
}

const RestaurantChangePopup = ({ onClose }: Props) => {
  const { language } = useLanguage();
  const { popupIsOpen } = useRestaurantChangePopup();

  const handleOnClick = () => {
    onClose();
    if (popupIsOpen.onOutsideClose) {
      popupIsOpen.onOutsideClose();
    }
  };

  return (
    <DynamicModal onClose={onClose}>
      <div className={classes.container}>
        <h2>
          {translate(
            translationsContent,
            "restaurantChangePopupMessage1",
            language
          )}
          <br />
          <br />
          <ul className={classes.list}>
            {popupIsOpen.notAvailableIngredients.map((item, i) => (
              <li key={i}>{item}</li>
            ))}
          </ul>
          <br />
          {translate(
            translationsContent,
            "restaurantChangePopupMessage2",
            language
          )}
        </h2>
        <div className={classes.buttons}>
          <Button label="OK" onClick={handleOnClick} />
        </div>
      </div>
    </DynamicModal>
  );
};

export default RestaurantChangePopup;
