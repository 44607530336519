import { translationsContent } from "../../../../localization/translations";
import { useLanguage } from "../../../../store/lang.context";
import { translate } from "../../../../utils/translate";
import SocialItem from "./Item";
import classes from "./socials.module.scss";

const socialItems = [
  {
    href: "https://www.facebook.com/eatsalad/",
    src: "EATSALAD_social_facebook.svg",
    alt: "facebook",
  },
  {
    href: "https://www.instagram.com/eatsaladfr/",
    src: "EATSALAD_social_instagram.svg",
    alt: "instagram",
  },
  {
    href: "https://www.linkedin.com/company/eatsalad/?originalSubdomain=fr",
    src: "EATSALAD_social_linkedin.svg",
    alt: "linkedin",
  },
  {
    href: "https://www.tiktok.com/@eatsaladfrance?lang=fr",
    src: "EATSALAD_social_tiktok.svg",
    alt: "tiktok",
  },
];

const Socials = () => {
  const { language } = useLanguage();

  return (
    <div className={classes.container}>
      <h4>{translate(translationsContent, "footerHeader4", language)}</h4>
      <div className={classes.itemsContainer}>
        {socialItems.map((item) => (
          <SocialItem
            key={item.alt}
            src={item.src}
            link={item.href}
            alt={item.alt}
          />
        ))}
      </div>
    </div>
  );
};

export default Socials;
