import React, { createContext, useContext } from "react";

import { TakeawaySelectorType } from "../api/types/takeawaySelector.type";

export const takeawaySelectorObject: TakeawaySelectorType = {
  isRestaurantsModalOpen: false,
  isTakeawayModalOpen: false,
};

export type TakeawaySelectorContextType = {
  takeawaySelector: TakeawaySelectorType;
  setTakeawaySelector: React.Dispatch<
    React.SetStateAction<TakeawaySelectorType>
  >;
};

export const TakeawaySelectorContext =
  createContext<TakeawaySelectorContextType>({
    takeawaySelector: takeawaySelectorObject,
    setTakeawaySelector: () => {},
  });

export const useTakeawaySelector = () => useContext(TakeawaySelectorContext);
