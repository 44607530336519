import axios, { AxiosResponse } from "axios";

import { sendRequest } from "../request/axios";
import { RestaurantDataType } from "../types/restaurant.type";
import { Locale } from "../utils/helpers/langRedirection";

export async function getRestaurants(limit: number, lang?: Locale) {
  try {
    if (lang) axios.defaults.headers.common["Accept-Language"] = lang;
    const res = await sendRequest<never, AxiosResponse<RestaurantDataType>>(
      "get",
      `/restaurants/?limit=${limit}`
    );
    return { data: res.data, success: true };
  } catch (error: unknown) {
    return {
      success: false,
    };
  }
}
